@use 'sass:meta';
@use 'sass:math';
@use 'sass:map';
@use '../variables/animations';

$rds-elevation-transition-duration: 280ms !default;
$rds-elevation-transition-timing-function: animations.$rds-fast-out-slow-in-timing-function !default;

$rds-elevation-colors: (
  grey: (
    ambient: black,
    umbra: black,
  ),
) !default;

$rds-elevation-properties: (
  ambient: (
    box-shadow: (
      1: '0px 2px 4px 0px',
      2: '0px 8px 20px 0px',
      3: '0px 12px 40px 0px',
    ),
    opacity: (
      1: 0.11,
      2: 0.15,
      3: 0.17,
    ),
  ),
  umbra: (
    box-shadow: (
      1: '0px 0px 1px 0px',
      2: '0px 0px 1px 0px',
      3: '0px 0px 2px 0px',
    ),
    opacity: (
      1: 0.32,
      2: 0.24,
      3: 0.16,
    ),
  ),
) !default;

@function rds-elevation($z, $type: grey) {
  @if meta.type-of($z) != number or not math.is-unitless($z) {
    @error '$z must be a unitless number';
  }
  @if $z < 1 or $z > 3 {
    @error '$z must be between 1 and 3';
  }

  $umbraBoxShadow: map.get(
    map.get(map.get($rds-elevation-properties, umbra), box-shadow),
    $z
  );
  $umbraColor: map.get(map.get($rds-elevation-colors, $type), umbra);
  $umbraOpacity: map.get(
    map.get(map.get($rds-elevation-properties, umbra), opacity),
    $z
  );
  $ambientBoxShadow: map.get(
    map.get(map.get($rds-elevation-properties, ambient), box-shadow),
    $z
  );
  $ambientColor: map.get(map.get($rds-elevation-colors, $type), ambient);
  $ambientOpacity: map.get(
    map.get(map.get($rds-elevation-properties, ambient), opacity),
    $z
  );

  @return #{$umbraBoxShadow rgba($umbraColor, $umbraOpacity)},
    #{$ambientBoxShadow rgba($ambientColor, $ambientOpacity)};
}

@mixin rds-elevation($zValue, $type: grey) {
  & {
    box-shadow: rds-elevation($zValue, $type);
  }
}

@function rds-elevation-transition-property-value(
  $duration: $rds-elevation-transition-duration,
  $easing: $rds-elevation-transition-timing-function
) {
  @return box-shadow #{$duration} #{$easing};
}

@mixin rds-elevation-transition(
  $duration: $rds-elevation-transition-duration,
  $easing: $rds-elevation-transition-timing-function
) {
  transition: rds-elevation-transition-property-value($duration, $easing);
}

@mixin rds-elevation-classes() {
  @for $value from 1 through 3 {
    .rds-shadow-#{$value} {
      @include rds-elevation($value);
    }
  }
}

@use './colors';
@use '../functions/colors' as f;

$brand-gradient-animation-0: f.rds-color(bg-gradient-6) !default;
$brand-gradient-animation-33: f.rds-color(bg-gradient-3) !default;
$brand-gradient-animation-66: f.rds-color(bg-gradient-7) !default;
$brand-gradient-animation-100: f.rds-color(bg-gradient-6) !default;
$brand-gradient-animation-time: 45s !default;

$rds-backgrounds: (
  // Available on components
  'blue-gradient-top-left':
    linear-gradient(
      125.75deg,
      f.rds-color(bg-gradient-1) -40.48%,
      f.rds-color(bg-base-1) 43.74%
    ),
  'blue-gradient-bottom-right':
    linear-gradient(
      121.43deg,
      f.rds-color(bg-base-1) 61.88%,
      f.rds-color(bg-gradient-1) 175.69%
    ),
  'neutral-gradient-top-left':
    linear-gradient(
      140.87deg,
      f.rds-color(bg-gradient-2) -68.64%,
      f.rds-color(bg-base-1) 52.89%
    ),
  'neutral-gradient-bottom-right':
    linear-gradient(
      144.84deg,
      f.rds-color(bg-base-1) 54.87%,
      f.rds-color(bg-gradient-3) 227.96%
    ),
  'neutral-gradient':
    linear-gradient(
      144.84deg,
      f.rds-color(bg-gradient-4) 54.87%,
      f.rds-color(bg-gradient-3) 227.96%
    ),
  // Presets (Not available for editors to pick)
  'warm-blue-gradient-top-left':
    linear-gradient(
      121.21deg,
      f.rds-color(bg-gradient-1) -17.31%,
      f.rds-color(bg-gradient-4) 46.37%,
      f.rds-color(bg-base-1) 65.87%
    ),
  'warm-blue-gradient-bottom-right':
    linear-gradient(
      136.47deg,
      f.rds-color(bg-base-1) 35.41%,
      f.rds-color(bg-gradient-4) 58%,
      f.rds-color(bg-gradient-1) 128.14%
    ),
  'gradient-white-to-grey':
    linear-gradient(
      148.3deg,
      f.rds-color(bg-base-1) 54.06%,
      f.rds-color(bg-gradient-5) 146.91%
    ),
  // Experimental / Homepage (Maybe hardcoded)
  'brand-gradient':
    linear-gradient(
      148.8deg,
      f.rds-color(bg-base-1) -29.38%,
      f.rds-color(bg-gradient-2) 69.97%,
      transparent 141.56%
    )
) !default;

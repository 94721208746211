@use '@rds/angular-components/styles/angular-components' as rds;
@include rds.rds-angular-components();
@import '../../../node_modules/angular-calendar/css/angular-calendar.css';

.loader {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 500px;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 99;
}

body {
  overflow: hidden;
}

@media (max-width: 768px) {
  .rds-header .rds-header-title .rds-header-logo svg {
    display: none;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes fade-out {
  to {
    opacity: 0;
  }
}

@keyframes slide-from-right {
  from {
    transform: translateX(30px);
  }
}

@keyframes slide-to-left {
  to {
    transform: translateX(-30px);
  }
}

::view-transition-old(root) {
  animation: 90ms cubic-bezier(0.4, 0, 1, 1) both fade-out,
  300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-left;
}

::view-transition-new(root) {
  animation: 210ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in,
  300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-right;
}

.rds-form-field__select-trigger-value {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

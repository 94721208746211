@use 'sass:math';
@use './icon-variables';
@use '../abstracts/functions/colors';
@use '../abstracts/functions/spacing';
@use '../abstracts/functions/layout';
@use '../abstracts/mixins/elevation';

$rds-button-outline-width: 2px !default;
$rds-button-outline-style: solid !default;
$rds-button-outline-color: colors.rds-color(stroke-active-focus) !default;
$rds-button-outline: $rds-button-outline-width $rds-button-outline-style
  $rds-button-outline-color !default;
$rds-button-height-s: 28px !default;
$rds-button-height-m: 36px !default;
$rds-button-height-l: 48px !default;
$rds-button-min-width-s: 60px !default;
$rds-button-min-width-m: 80px !default;
$rds-button-min-width-l: 100px !default;
$rds-icon-button-s__overlay-size: 28px !default;
$rds-button-sizes: (
  s: (
    height: $rds-button-height-s,
    min-width: $rds-button-min-width-s,
    padding-horizontal: spacing.rds-spacing(4),
    border-width: 1px,
    icon: icon-variables.$rds-icon-size-s,
    icon-spacing: spacing.rds-spacing(3),
    icon-margin: spacing.rds-spacing(2),
    typography: ui-label-s-bold,
  ),
  m: (
    height: $rds-button-height-m,
    min-width: $rds-button-min-width-m,
    padding-horizontal: spacing.rds-spacing(5),
    border-width: 1px,
    icon: icon-variables.$rds-icon-size-m,
    icon-spacing: spacing.rds-spacing(4),
    icon-margin: spacing.rds-spacing(3),
    typography: ui-label-m-bold,
  ),
  l: (
    height: $rds-button-height-l,
    min-width: $rds-button-min-width-l,
    padding-horizontal: spacing.rds-spacing(7),
    border-width: 1px,
    icon: icon-variables.$rds-icon-size-l,
    icon-spacing: spacing.rds-spacing(6),
    icon-margin: spacing.rds-spacing(3),
    typography: ui-label-l-bold,
  ),
) !default;
$rds-button-border-radius: spacing.rds-spacing(1) !default;

$rds-gradient-buttons: (
  primary: (
    outline: $rds-button-outline,
    color: colors.rds-color(on-dark-surface),
    color-disabled: colors.rds-color(disabled),
    background: colors.rds-color(bg-informative),
    background-hover: colors.rds-color(bg-informative-hover),
    background-active: colors.rds-color(bg-informative-pressed),
    background-disabled: colors.rds-color(bg-disabled-2),
    box-shadow: elevation.rds-elevation(1),
    box-shadow-active: none,
    box-shadow-disabled: none,
    border-color-focus: colors.rds-color(bg-base-1),
  ),
  warning: (
    outline: $rds-button-outline,
    color: colors.rds-color(on-dark-surface),
    color-disabled: colors.rds-color(disabled),
    background: colors.rds-color(bg-error),
    background-hover: colors.rds-color(bg-error-hover),
    background-active: colors.rds-color(bg-error-pressed),
    background-disabled: colors.rds-color(bg-disabled-2),
    box-shadow: elevation.rds-elevation(1),
    box-shadow-active: none,
    box-shadow-disabled: none,
    border-color-focus: colors.rds-color(bg-base-1),
  ),
  fab: (
    outline: $rds-button-outline,
    color: colors.rds-color(on-dark-surface),
    color-disabled: colors.rds-color(disabled),
    background: colors.rds-color(bg-informative),
    background-hover: colors.rds-color(bg-informative-hover),
    background-active: colors.rds-color(bg-informative-pressed),
    background-disabled: colors.rds-color(bg-disabled-2),
    box-shadow: elevation.rds-elevation(1),
    box-shadow-active: none,
    box-shadow-disabled: none,
    border-color-focus: colors.rds-color(bg-base-1),
    border-radius: 50%,
  ),
) !default;

$rds-buttons: (
  secondary: (
    outline: $rds-button-outline,
    color: colors.rds-color(informative),
    color-hover: colors.rds-color(informative-hover),
    color-disabled: colors.rds-color(disabled),
    background: none,
    background-focus: transparent,
    background-diabled: colors.rds-color(bg-disabled),
    border: 1px solid colors.rds-color(informative),
    border-hover: 1px solid colors.rds-color(informative-hover),
    border-focus: 1px solid transparent,
    border-disabled: 1px solid colors.rds-color(stroke-enabled),
  ),
  secondary-warning: (
    outline: $rds-button-outline,
    color: colors.rds-color(error),
    color-hover: colors.rds-color(error-hover),
    color-disabled: colors.rds-color(disabled),
    background: none,
    background-focus: transparent,
    background-hover: transparent,
    background-diabled: colors.rds-color(bg-disabled),
    border: 1px solid colors.rds-color(error),
    border-hover: 1px solid colors.rds-color(error-hover),
    border-focus: 1px solid transparent colors.rds-color(stroke-active-focus),
    border-disabled: 1px solid colors.rds-color(stroke-enabled),
  ),
  text: (
    outline: $rds-button-outline,
    color: colors.rds-color(informative),
    color-hover: colors.rds-color(informative-hover),
    color-disabled: colors.rds-color(disabled),
    background: none,
    background-focus: transparent,
    background-diabled: none,
    border: none,
    border-hover: none,
    border-focus: none,
    border-disabled: none,
    height: layout.rds-layout(2),
    min-width: auto,
  ),
  text-warning: (
    outline: $rds-button-outline,
    color: colors.rds-color(error),
    color-hover: colors.rds-color(error-hover),
    color-disabled: colors.rds-color(disabled),
    background: none,
    background-focus: transparent,
    background-diabled: none,
    border: none,
    border-hover: none,
    border-focus: none,
    border-disabled: none,
    height: layout.rds-layout(2),
    min-width: auto,
  ),
) !default;

$rds-icon-buttons: (
  icon-button: (
    outline: $rds-button-outline,
    color: colors.rds-color(low-contrast),
    color-hover: colors.rds-color(high-contrast),
    color-active: colors.rds-color(high-contrast),
    color-focus: colors.rds-color(high-contrast),
    color-disabled: colors.rds-color(disabled),
    background: transparent,
    background-hover: colors.rds-color(bg-transparent-hover-select),
    background-focus: transparent,
    background-active: colors.rds-color(bg-transparent-pressed),
    background-disabled: transparent,
  ),
  icon-warning-button: (
    outline: $rds-button-outline,
    color: colors.rds-color(error),
    color-hover: colors.rds-color(error-hover),
    color-active: colors.rds-color(error-hover),
    color-focus: colors.rds-color(error-hover),
    color-disabled: colors.rds-color(disabled),
    background: transparent,
    background-hover: colors.rds-color(bg-transparent-hover-select),
    background-focus: transparent,
    background-active: colors.rds-color(bg-transparent-pressed),
    background-disabled: transparent,
  ),
) !default;
$rds-icon-button-sizes: (
  s: (
    size: icon-variables.$rds-icon-size-s,
    overlay-size: $rds-icon-button-s__overlay-size,
  ),
  m: (
    size: icon-variables.$rds-icon-size-l,
    overlay-size: $rds-button-height-m,
  ),
) !default;

$rds-fab-button-sizes: (
  s: (
    icon: icon-variables.$rds-icon-size-s,
    height: 36px,
  ),
  m: (
    icon: icon-variables.$rds-icon-size-l,
    height: spacing.rds-spacing(11),
  ),
  l: (
    icon: spacing.rds-spacing(8),
    height: 56px,
  ),
) !default;
$rds-text-button-sizes: (
  s: (
    icon: icon-variables.$rds-icon-size-s,
    height: 20px,
    typography: ui-label-s-bold,
  ),
  m: (
    icon: icon-variables.$rds-icon-size-m,
    height: 24px,
    typography: ui-label-m-bold,
  ),
  l: (
    icon: icon-variables.$rds-icon-size-l,
    height: 24px,
    typography: ui-label-l-bold,
  ),
) !default;

$rds-button-border-radius: spacing.rds-spacing(1) !default;
$rds-button-min-width: 72px !default;

@use '../../abstracts/functions' as f;
@use '../icon-variables' as iv;

$rds-checkbox-base__height: 24px !default;

$rds-checkbox-base__checkbox-container-height: iv.$rds-icon-size-s !default;
$rds-checkbox-base__checkbox-container-width: iv.$rds-icon-size-s !default;
$rds-checkbox-base__checkbox-container-margin-right: f.rds-spacing(3) +
  f.rds-spacing(1) !default;

$rds-checkbox-base__frame-border: f.rds-spacing(1) solid f.rds-color(grey-3) !default;
$rds-checkbox-base__frame-border-color-disabled: f.rds-color(grey-2) !default;
$rds-checkbox-base__frame-border-radius: f.rds-spacing(1) !default;

$rds-checkbox-base__background-border-radius: f.rds-spacing(1) !default;
$rds-checkbox-base__background-background: f.rds-color(informative) !default;
$rds-checkbox-base__background-background-disabled: f.rds-color(
  grey-2
) !default;

$rds-checkbox-base__checkmark: f.rds-color(bg-base-1) !default;

$rds-checkbox-base__indeterminate-mark-width: calc(100% - 6px) !default;
$rds-checkbox-base__indeterminate-mark-height: f.rds-spacing(1) !default;
$rds-checkbox-base__indeterminate-mark-background: f.rds-color(
  bg-base-1
) !default;

@use '../abstracts/functions' as df;

$rds-divider-size: df.rds-spacing(1) * 0.5 !default;
$rds-divider-color: df.rds-color(grey-2) !default;
$rds-divider-style: solid !default;
$rds-divider-line: $rds-divider-size $rds-divider-style $rds-divider-color !default;

@mixin rds-divider() {
  .rds-divider {
    display: block;
    width: 100%;
    border-bottom: $rds-divider-line;
    margin: 0;

    &.rds-divider-vertical {
      border-bottom: 0;
      border-right: $rds-divider-line;
      height: 100%;
    }
  }
}

@use 'sass:map';
@use '../variables/colors';
@use '../functions/colors' as f-color;

$color-classes: (
  bg: background,
  c: color,
) !default;

@mixin rds-color-classes() {
  :root {
    @each $color-group-name, $color-map in colors.$rds-color-primitives-palette
    {
      @each $color-name, $color-value in $color-map {
        $color-var-name: #{colors.$rds-color-prefix}#{$color-name};

        #{$color-var-name}: #{$color-value};
      }
    }

    @each $color-palette-name, $color-palette in colors.$rds-color-palettes {
      @if $color-palette-name == colors.$rds-color-palette-default {
        @each $color-group-name, $color-map in $color-palette {
          @each $color-name, $color-value in $color-map {
            $color-var-name: #{colors.$rds-color-prefix}#{$color-name};
            #{$color-var-name}: var(#{colors.$rds-color-prefix}#{$color-value});
          }
        }
      }
    }
  }

  @each $color-palette-name, $color-palette in colors.$rds-color-palettes {
    [rds-theme='#{$color-palette-name}'] {
      @each $color-group-name, $color-map in $color-palette {
        @each $color-name, $color-value in $color-map {
          $color-var-name: #{colors.$rds-color-prefix}#{$color-name};
          #{$color-var-name}: var(#{colors.$rds-color-prefix}#{$color-value});
        }
      }
    }
  }

  @each $color-group-name,
    $color-map
      in map.merge(
        colors.$rds-color-primitives-palette,
        map.get(colors.$rds-color-palettes, colors.$rds-color-palette-default)
      )
  {
    @each $color-name, $color-value in $color-map {
      @each $class-prefix, $class-property in $color-classes {
        $class-name: #{$class-prefix}-#{$color-name};

        .#{$class-name} {
          #{$class-property}: f-color.rds-color($color-name) !important;
        }
      }
    }
  }
}

@use 'sass:math';
@use 'sass:map';
@use '../abstracts/mixins/disabled-outline';
@use '../abstracts/mixins/typography';
@use './button-variables';

@mixin button($button) {
  @content;
  transition:
    background-color 0.2s linear,
    border-color 0.2s linear,
    color 0.2s linear;

  background: map.get($button, background);
  color: map.get($button, color);
  border: map.get($button, border);
  text-decoration: none;
  @include disabled-outline.disabled-outline();

  &:not(:hover).rds-button-active:not([disabled]) {
    color: map.get($button, color);

    &:not(:focus-visible):not([disabled]) {
      border: map.get($button, border);
    }
  }

  &:hover:not([disabled]) {
    color: map.get($button, color-hover);
    border: map.get($button, border-hover);
  }

  &:focus-visible:not([disabled]) {
    outline: map.get($button, outline);
    border: map.get($button, border-focus);
    background-color: map.get($button, background-focus);
  }

  &:active:not([disabled]) {
    color: map.get($button, color);

    &:not(:focus-visible):not([disabled]) {
      border: map.get($button, border);
    }
  }

  &[disabled] {
    background: map.get($button, background-diabled);
    color: map.get($button, color-disabled);
    border: map.get($button, border-disabled);
  }
}

@mixin button-gradient($gradient-button) {
  transition:
    background-color 0.1s linear,
    border-color 0.1s linear,
    box-shadow 0.1s linear;

  box-shadow: map.get($gradient-button, box-shadow);
  border: 1px solid map.get($gradient-button, background);
  background: map.get($gradient-button, background);
  color: map.get($gradient-button, color);
  @include disabled-outline.disabled-outline();

  &:not(:hover).rds-button-active:not([disabled]) {
    background: map.get($gradient-button, background-active);
    box-shadow: map.get($gradient-button, box-shadow-active);

    &:not(:focus-visible):not([disabled]) {
      border-color: map.get($gradient-button, background-active);
    }
  }

  &:hover:not([disabled]) {
    background: map.get($gradient-button, background-hover);
    border-color: map.get($gradient-button, background-hover);
  }

  &:focus-visible:not([disabled]) {
    outline: map.get($gradient-button, outline);
    border-color: map.get($gradient-button, border-color-focus);
  }

  &:active:not([disabled]) {
    background: map.get($gradient-button, background-active);
    box-shadow: map.get($gradient-button, box-shadow-active);

    &:not(:focus-visible):not([disabled]) {
      border-color: map.get($gradient-button, background-active);
    }
  }

  &[disabled] {
    color: map.get($gradient-button, color-disabled);
    background: map.get($gradient-button, background-disabled);
    border-color: map.get($gradient-button, background-disabled);
    box-shadow: map.get($gradient-button, box-shadow-disabled);
  }
}

@mixin rds-button-style($button-type) {
  @if map.has-key(button-variables.$rds-icon-buttons, $button-type) {
    $button-styles: map.get(button-variables.$rds-icon-buttons, $button-type);

    background: map.get($button-styles, background);
    color: map.get($button-styles, color);
    padding: 0;
    border: 0;
    z-index: 0;

    &:before,
    &:after {
      border-radius: button-variables.$rds-button-border-radius;
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      outline: transparent
        button-variables.$rds-button-outline-style
        button-variables.$rds-button-outline-width;
    }

    &:not(:hover).rds-button-active:not([disabled]) {
      color: map.get($button-styles, color-active);

      &:before {
        background-color: map.get($button-styles, background-active);
      }
    }

    &:hover:not([disabled]) {
      color: map.get($button-styles, color-hover);

      &:before {
        background-color: map.get($button-styles, background-hover);
      }
    }

    &:focus-visible:not([disabled]) {
      color: map.get($button-styles, color-focus);

      &:before {
        background-color: map.get($button-styles, background-focus);
      }

      &:after {
        outline: map.get($button-styles, outline);
      }
    }

    &:active:not([disabled]) {
      color: map.get($button-styles, color-active);

      &:before {
        background-color: map.get($button-styles, background-active);
      }
    }

    &[disabled] {
      background: map.get($button-styles, background-disabled);
      color: map.get($button-styles, color-disabled);
    }

    @each $size, $values in button-variables.$rds-icon-button-sizes {
      $btn-size: map.get($values, size);
      $overlay-size: map.get($values, overlay-size);

      &.rds-button-#{$size} {
        height: $btn-size;
        min-width: $btn-size;

        $outline-before-width: math.div($overlay-size - $btn-size, 2);

        &:before,
        &:after {
          $outline-before-width: math.div($overlay-size - $btn-size, 2);
          outline-width: $outline-before-width;
          outline-offset: -$outline-before-width;
          left: -$outline-before-width;
          right: -$outline-before-width;
          top: -$outline-before-width;
          bottom: -$outline-before-width;
          height: $overlay-size;
          width: $overlay-size;
        }

        &:after {
          outline-width: button-variables.$rds-button-outline-width;
          outline-offset: 0;
        }

        .rds-icon {
          width: $btn-size;
          height: $btn-size;
        }
      }
    }
  }
}

@mixin rds-button() {
  .rds-button {
    position: relative;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    border-radius: button-variables.$rds-button-border-radius;
    min-width: button-variables.$rds-button-min-width;
    cursor: pointer;
    @include disabled-outline.disabled-outline();

    &[disabled] {
      cursor: default;
      pointer-events: none;
    }

    @each $size, $btn in button-variables.$rds-button-sizes {
      &.rds-button-#{$size} {
        $btn-horizontal-padding: map.get($btn, padding-horizontal) - map.get(
            $btn,
            border-width
          );

        height: map.get($btn, height);
        min-width: map.get($btn, min-width);
        padding: 0 $btn-horizontal-padding;
        @include typography.rds-typography(map.get($btn, typography));

        &.rds-button--with-left-icon,
        &.rds-button--with-right-icon,
        &.rds-button--with-only-icon {
          .rds-icon {
            height: map.get($btn, icon);
            width: map.get($btn, icon);
          }
        }

        &.rds-button--with-left-icon {
          padding-left: map.get($btn, icon-spacing) - map.get(
              $btn,
              border-width
            );

          .rds-icon {
            margin-right: map.get($btn, icon-margin);
          }
        }

        &.rds-button--with-right-icon {
          padding-right: map.get($btn, icon-spacing) - map.get(
              $btn,
              border-width
            );

          .rds-icon {
            margin-left: map.get($btn, icon-margin);
          }
        }

        &.rds-button--with-only-icon {
          min-width: map.get($btn, height);
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    &.rds-button--primary {
      @include button-gradient(
        map.get(button-variables.$rds-gradient-buttons, primary)
      );
    }

    &.rds-button--warning {
      @include button-gradient(
        map.get(button-variables.$rds-gradient-buttons, warning)
      );
    }

    &.rds-button--secondary {
      @include button(map.get(button-variables.$rds-buttons, secondary));
    }

    &.rds-button--secondary-warning {
      @include button(
        map.get(button-variables.$rds-buttons, secondary-warning)
      );
    }

    &.rds-button--text {
      $btn: map.get(button-variables.$rds-buttons, text);
      min-width: map.get($btn, min-width);
      padding: 0;
      @include button($btn);

      @each $size, $values in button-variables.$rds-text-button-sizes {
        &.rds-button-#{$size} {
          height: map.get($values, height);
          padding: 0;
          @include typography.rds-typography(map.get($values, typography));

          .rds-icon {
            width: map.get($values, icon);
            height: map.get($values, icon);
          }
        }
      }
    }

    &.rds-button--text-warning {
      $btn: map.get(button-variables.$rds-buttons, text-warning);

      min-width: map.get($btn, min-width);
      padding: 0;
      @include button($btn);

      @each $size, $values in button-variables.$rds-text-button-sizes {
        &.rds-button-#{$size} {
          height: map.get($values, height);
          padding: 0;
          @include typography.rds-typography(map.get($values, typography));

          .rds-icon {
            width: map.get($values, icon);
            height: map.get($values, icon);
          }
        }
      }
    }

    &.rds-button--icon {
      @include rds-button-style('icon-button');
    }

    &.rds-button--icon-warning {
      @include rds-button-style('icon-warning-button');
    }

    &.rds-button--fab {
      $btn: map.get(button-variables.$rds-gradient-buttons, fab);
      border-radius: map.get($btn, border-radius);
      padding: 0;
      @include button-gradient($btn);

      .rds-icon {
        fill: currentColor;
      }

      @each $size, $values in button-variables.$rds-fab-button-sizes {
        &.rds-button-#{$size} {
          width: map.get($values, height);
          max-width: map.get($values, height);
          min-width: map.get($values, height);
          height: map.get($values, height);

          .rds-icon {
            width: map.get($values, icon);
            height: map.get($values, icon);
          }
        }
      }
    }
  }
}

@use '../abstracts/functions' as f;
@use '../abstracts/mixins' as m;

$rds-tabs__header-border-bottom-width: 1px !default;
$rds-tabs__header-border-bottom: $rds-tabs__header-border-bottom-width solid
  f.rds-color(grey-2) !default;

$rds-tabs__tab-border-bottom: 3px solid transparent !default;
$rds-tabs__tab-border-bottom-color--active: f.rds-color(
  informative-hover
) !default;
$rds-tabs__tab-border-bottom--active: 3px solid
  $rds-tabs__tab-border-bottom-color--active !default;
$rds-tabs__tab-margin: 0 f.rds-spacing(5) !default;
$rds-tabs__tab-height: f.rds-spacing(2) + f.rds-spacing(12) -
  $rds-tabs__header-border-bottom-width !default;
$rds-tabs__tab-min-width: 60px !default;
$rds-tabs__tab-margin-s: 0 f.rds-spacing(4) !default;
$rds-tabs__tab-height-s: f.rds-spacing(2) + f.rds-spacing(10) -
  $rds-tabs__header-border-bottom-width !default;
$rds-tabs__tab-header-spacings: f.rds-spacing(4) !default;

$rds-tabs__tab-color: f.rds-color(low-contrast) !default;
$rds-tabs__tab-color--active: f.rds-color(informative-hover) !default;
$rds-tabs__tab-color--disabled: f.rds-color(disabled) !default;

$rds-tabs__tab-outline-width: f.rds-spacing(1) !default;
$rds-tabs__tab-outline: $rds-tabs__tab-outline-width solid
  f.rds-color(stroke-active-focus) !default;
$rds-tabs__tab-outline-offset: f.rds-spacing(1) !default;

@mixin rds-tabs() {
  .rds-tabs {
    .rds-tabs__header {
      display: flex;
      justify-content: space-between;
      border-bottom: $rds-tabs__header-border-bottom;

      .rds-tabs-label-actions {
        display: flex;
      }
    }

    .rds-tabs__wrapper {
      display: flex;

      .rds-tabs__tab {
        position: relative;
        top: 1px;
        display: flex;
        align-items: center;
        border-bottom: $rds-tabs__tab-border-bottom;
        margin: $rds-tabs__tab-margin;
        height: $rds-tabs__tab-height;
        min-width: $rds-tabs__tab-min-width;
        color: $rds-tabs__tab-color;
        cursor: pointer;
        @include m.disabled-outline();
        @include m.rds-typography(ui-label-m-bold);

        > * {
          margin-left: $rds-tabs__tab-header-spacings;

          &:first-child {
            margin-left: 0;
          }
        }

        &:first-child {
          padding-left: 0;
          margin-left: 0;
        }

        &.rds-tabs__tab--active {
          border-bottom: $rds-tabs__tab-border-bottom--active;
          color: $rds-tabs__tab-color--active;
        }

        &.rds-tabs__tab--disabled {
          color: $rds-tabs__tab-color--disabled;
          pointer-events: none;
          cursor: default;
        }

        &:hover:not(.rds-tabs__tab--disabled):not(.rds-tabs__tab--active) {
          border-bottom: $rds-tabs__tab-border-bottom--active;
        }

        &.cdk-focused.cdk-keyboard-focused:not(.rds-tabs__tab--disabled) {
          border-radius: $rds-tabs__tab-outline-width;
          outline: $rds-tabs__tab-outline;
          outline-offset: $rds-tabs__tab-outline-offset;
        }

        &-size-s {
          margin: $rds-tabs__tab-margin-s;
          height: $rds-tabs__tab-height-s;
        }
      }
    }

    &.rds-tabs-stretch {
      .rds-tabs__wrapper {
        flex-basis: 0;
        flex-grow: 1;

        .rds-tabs__tab {
          flex-basis: 0;
          flex-grow: 1;
        }
      }
    }
  }
}

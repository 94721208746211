@use 'sass:math';
@use 'sass:map';
@use './form-field-variables' as v;
@use '../abstracts/mixins' as m;
@use '../abstracts/functions' as f;
@use '../abstracts/variables' as av;
@use './select';
@use './autocomplete/autocomplete-variables' as acv;

@include select.rds-select-base-styles();
@include select.rds-select-search-input();

@mixin rds-form-field() {
  .rds-form-field {
    .rds-form-field__label {
      white-space: unset;
      color: v.$rds-form-field__label-color;
      display: flex;
      align-items: flex-start;
    }

    &--floating-label {
      .rds-form-field__label {
        cursor: inherit;
      }
    }

    &.rds-form-field--side {
      --label-width: calc(var(--rds-control-label-width) * 1%);
      display: flex;
      flex-direction: row;

      &-right {
        .rds-form-field__label-wrapper {
          display: flex;
          justify-content: flex-end;

          .rds-form-field__label {
            text-align: right;
          }
        }
      }

      .rds-form-field__label-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: v.$rds-form-field__label-wrapper-margin-bottom--side;
        margin-right: v.$rds-form-field__label-margin-right--side;
        width: var(--label-width);
        overflow: unset;
        text-overflow: unset;
        white-space: inherit;
      }

      &.rds-form-field--hint-or-error-or-counter {
        .rds-form-field__label-wrapper {
          margin-bottom: v.$rds-form-field__label-wrapper-margin-bottom--hint--side;
        }
      }

      .rds-form-field__container {
        width: calc(
          100% - var(--label-width) -
            #{v.$rds-form-field__label-margin-right--side}
        );
      }

      &.rds-form-field__wrapper-textarea-field,
      &.rds-form-field--select-with-chips {
        .rds-form-field__label-wrapper {
          padding-top: v.$rds-form-field__wrapper-textarea-field-padding-top--side;
          align-items: flex-start;
        }
      }
    }

    &.rds-form-field--no-label {
      .rds-form-field__label-wrapper {
        width: 0;
        height: 0;
        padding: 0;
        margin: 0;
      }
    }

    &:not(.rds-form-field--focused):not(.rds-form-field--invalid):not(
        .rds-form-field--disabled
      ) {
      .rds-form-field__control-container:hover {
        border-color: v.$rds-form-field__control-container-border-color--hover;

        .rds-form-field__control-prefix-separator,
        .rds-form-field__control-suffix-separator {
          border-color: v.$rds-form-field__control-container-border-color--hover;
        }
      }

      .rds-form-field__select-arrow {
        color: v.$rds-form-field__select-arrow-color;
      }
    }

    &.rds-form-field--focused {
      .rds-form-field__container.rds-form-field--global-search {
        .rds-form-field__control-container {
          outline: none;
        }
      }

      .rds-form-field__container {
        .rds-form-field__control-container {
          outline: v.$rds-form-field__control-container-border--focused;
          outline-offset: -#{v.$rds-form-field__control-container-border-width--focused};
          border: none;

          .rds-form-field__content {
            .rds-form-field__content-prefix
              .rds-form-field__control-prefix-separator-container
              .rds-form-field__control-prefix-separator,
            .rds-form-field__content-suffix
              .rds-form-field__control-suffix-separator-container
              .rds-form-field__control-suffix-separator {
              border-color: v.$rds-form-field__control-container-border-color--focused;
            }
          }
        }
      }

      .rds-form-field--textarea-field {
        &.rds-form-field--static-label {
          .rds-form-field__control-container {
            .rds-form-field__content-prefix {
              padding-top: 0;
            }

            .rds-form-field__content-suffix {
              padding-top: 0;
            }
          }
        }
      }

      &.rds-form-field--floating-label {
        .rds-form-field__container {
          .rds-form-field__label-wrapper {
            color: v.$rds-form-field__label-color--focused;
          }

          .rds-form-field__label {
            color: v.$rds-form-field__label-color--focused;
          }
        }
      }
    }

    > .rds-form-field__label-wrapper {
      margin-bottom: v.$rds-form-field__label-top__margin-bottom;
    }

    .rds-form-field__container {
      flex-direction: column;
      display: flex;
      position: relative;
      text-align: center;

      .rds-form-field__control-container {
        display: flex;
        border-radius: v.$rds-form-field__control-container-border-radius;
        border: v.$rds-form-field__control-container-border;
        background: v.$rds-form-field__control-container-background;
        cursor: text;
      }

      .rds-form-field__content {
        display: flex;
        position: relative;
        width: 100%;

        .rds-form-field__content-prefix {
          display: flex;
          align-items: center;
          color: v.$rds-form-field__control-fix-color;

          > *:last-child.rds-form-field__control-prefix {
            margin-right: v.$rds-form-field__control-fix-no-separator-spacing;
          }

          .rds-form-field__control-prefix-separator-container {
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: row;

            .rds-form-field__control-prefix-separator {
              position: absolute;
              border-left: v.$rds-form-field__control-container-border;
            }
          }
        }

        .rds-form-field__content-infix {
          position: relative;
          width: 100%;
          display: flex;
          min-width: 0;
        }

        .rds-form-field__content-suffix {
          display: flex;
          align-items: center;
          color: v.$rds-form-field__control-fix-color;

          &.rds-form-field__content-suffix-datepicker {
            align-items: center;
            padding-top: 0;

            .rds-icon {
              margin-top: 0;
            }
          }

          > *:first-child.rds-form-field__control-suffix {
            margin-left: v.$rds-form-field__control-fix-no-separator-spacing;
          }

          .rds-form-field__control-suffix-separator-container {
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: row-reverse;

            .rds-form-field__control-suffix-separator {
              position: absolute;
              border-right: v.$rds-form-field__control-container-border;
            }
          }
        }
      }

      &.rds-form-field--textarea-field {
        .rds-form-field__control-container {
          padding-bottom: v.$rds-form-field__control-container-padding-textarea-resizer;
          padding-right: v.$rds-form-field__control-container-padding-textarea-resizer;

          .rds-form-field__content {
            display: flex;
          }

          .rds-form-field__content-suffix {
            > *:first-child {
              &.rds-form-field__control-suffix-separator-container {
                width: v.$rds-form-field__control-container-padding-textarea-resizer;

                .rds-form-field__control-suffix-separator {
                  padding-right: v.$rds-form-field__control-container-padding-textarea-resizer +
                    v.$rds-form-field__control-container-border-width;
                }
              }

              &.rds-form-field__control-suffix {
                margin-left: v.$rds-form-field__control-container-padding-textarea-resizer +
                  v.$rds-form-field__control-container-border-width;
              }
            }
          }
        }

        &.rds-form-field--static-label {
          .rds-form-field__control-container {
            .rds-form-field__content-prefix {
              padding-top: 0;
            }

            .rds-form-field__content-suffix {
              padding-top: 0;
            }
          }
        }
      }

      .rds-form-field__label-wrapper {
        position: absolute;
        z-index: 2;
        left: 0;
        box-sizing: content-box;
        width: 100%;
        height: map.get(
            map.get(
              av.$rds-typography-list,
              v.$rds-form-field__label-typography-floating-base
            ),
            line-height
          ) +
          map.get(map.get(v.$rds-form-field-sizes, l), margin-top);
        overflow: hidden;
        text-overflow: ellipsis;
        transition-duration: v.$rds-form-field-label-transition-timing-function-duration;
        transition-timing-function: v.$rds-form-field-label-transition-timing-function;

        &--float {
          height: map.get(
            map.get(
              av.$rds-typography-list,
              v.$rds-form-field__label-typography-floating-float
            ),
            line-height
          );
        }

        .rds-form-field__label {
          position: absolute;
          width: 100%;
          left: 0;
          text-align: start;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: v.$rds-form-field__label-color;
          transition-duration: v.$rds-form-field-label-transition-timing-function-duration;
          transition-timing-function: v.$rds-form-field-label-transition-timing-function;

          .rds-icon {
            transition-duration: v.$rds-form-field-label-transition-timing-function-duration;
            transition-timing-function: v.$rds-form-field-label-transition-timing-function;
          }
        }
      }

      .rds-form-field__control {
        position: relative;
        display: flex;
        margin-top: 0;
        border: none;
        width: 100%;
        background: transparent;
        outline: none;
        color: v.$rds-form-field__control-color;
        caret-color: v.$rds-form-field__control-caret-color;
        text-overflow: ellipsis;

        &::placeholder {
          color: v.$rds-form-field__control-color--placeholder;
        }
      }

      &.rds-form-field__label--float {
        .rds-form-field__control {
          display: inline-block;
          margin-top: v.$rds-form-field__control-margin-top;
        }
      }

      &.rds-form-field--static-label {
        .rds-form-field__control {
          margin-top: 0;
        }
      }

      textarea.rds-form-field__control {
        resize: none;
        bottom: unset;

        &.rds-textarea-resize-vertical {
          resize: vertical;
        }

        &.rds-textarea-resize-horizontal {
          resize: horizontal;
        }

        &.rds-textarea-resize-both {
          resize: both;
        }
      }

      .rds-form-field__subscript-wrapper {
        position: absolute;
        top: calc(100% - #{v.$rds-form-field-padding-bottom});
        left: 0;
        z-index: -1;
        align-items: flex-start;

        display: flex;
        justify-content: space-between;

        width: v.$rds-form-field__subscript-wrapper-width;
        height: v.$rds-form-field__subscript-wrapper-height;
        padding-left: v.$rds-form-field__subscript-wrapper-padding-left;
        gap: f.rds-spacing(7);
      }

      .rds-form-field__subscript {
        text-align: left;
        display: flex;
        @include m.rds-typography(ui-component-text-s);

        &--hint {
          color: v.$rds-form-field__subscript-color--hint;
        }

        &--error {
          color: v.$rds-form-field__subscript-color--error;
        }

        &--error-icon {
          flex-shrink: 0;
          margin-right: v.$rds-icon-padding-xs;
          height: v.$rds-icon-size-xs;
          width: v.$rds-icon-size-xs;
        }
      }
    }

    .rds-form-field--hint-or-error-or-counter {
      padding-bottom: v.$rds-form-field-padding-bottom;

      .rds-form-field__subscript-wrapper {
        z-index: auto;
      }
    }

    &.rds-form-field--disabled {
      .rds-form-field__control-container {
        border-color: v.$rds-form-field__control-container-border-color--disabled;
        background: v.$rds-form-field__control-container-background--disabled;

        &:hover {
          border-color: v.$rds-form-field__control-container-border-color--disabled;
          cursor: default;
        }

        .rds-form-field__content {
          .rds-form-field__content-prefix
            .rds-form-field__control-prefix-separator-container
            .rds-form-field__control-prefix-separator,
          .rds-form-field__content-suffix
            .rds-form-field__control-suffix-separator-container
            .rds-form-field__control-suffix-separator {
            border-color: v.$rds-form-field__control-container-border-color--disabled;
          }
        }
      }

      .rds-form-field__content {
        .rds-form-field__content-prefix {
          color: v.$rds-form-field__control-color--disabled;
        }

        .rds-form-field__content-suffix {
          color: v.$rds-form-field__control-color--disabled;
        }
      }

      &.rds-form-field--floating-label {
        .rds-form-field__container {
          .rds-form-field__label {
            color: v.$rds-form-field__label-color--disabled;
          }
        }

        .rds-form-field__label {
          color: v.$rds-form-field__label-color--disabled;
        }
      }

      .rds-form-field__control {
        color: v.$rds-form-field__control-color--disabled;

        &::placeholder {
          color: v.$rds-form-field__control-color--disabled;
        }
      }

      .rds-form-field__select-arrow {
        color: v.$rds-form-field__select-arrow-color--disabled;
      }
    }

    &.rds-form-field--invalid {
      &:not(.rds-form-field--disabled) {
        .rds-form-field__control-container {
          outline: v.$rds-form-field__control-container-border--invalid;
          outline-offset: -#{v.$rds-form-field__control-container-border-width--invalid};
          border: none;

          &.rds-form-field-textarea {
            &.rds-form-field--static-label {
              .rds-form-field__control-container {
                .rds-form-field__content-prefix {
                  padding-top: 0;
                }

                .rds-form-field__content-suffix {
                  padding-top: 0;
                }
              }
            }
          }
        }

        &.rds-form-field--floating-label {
          .rds-form-field__label-wrapper {
            color: v.$rds-form-field__label-color--invalid;
          }

          .rds-form-field__label {
            color: v.$rds-form-field__label-color--invalid;
          }
        }

        .rds-form-field__content {
          .rds-form-field__content-prefix
            .rds-form-field__control-prefix-separator-container
            .rds-form-field__control-prefix-separator,
          .rds-form-field__content-suffix
            .rds-form-field__control-suffix-separator-container
            .rds-form-field__control-suffix-separator {
            border-color: v.$rds-form-field__control-container-border-color--invalid;
          }
        }
      }
    }

    &.rds-form-field--floating-label {
      .rds-form-field__control {
        display: inline-block;
        margin-top: v.$rds-form-field__control-margin-top;
      }

      input[type='number']::-webkit-textfield-decoration-container {
        position: relative;
      }

      input[type='number']::-webkit-outer-spin-button,
      input[type='number']::-webkit-inner-spin-button {
        position: absolute;
        bottom: 0;
        height: f.rds-spacing(7);
        right: 0;
      }
    }

    .rds-form-field__asterisk {
      color: v.$rds-form-field__asterisk-color;
    }

    .rds-form-field__container {
      @include select.rds-select-control();
    }

    &.rds-form-field--disabled {
      &.rds-form-field--select,
      &.rds-form-field--select-with-chips {
        .rds-form-field__control-container {
          cursor: default;
        }
      }
    }
  }

  @each $size, $form-field in v.$rds-form-field-sizes {
    .rds-form-field.rds-form-field--#{$size} {
      .rds-form-field__label-wrapper {
        .rds-form-field__label {
          @include m.rds-typography(map.get($form-field, label-name));

          .rds-icon {
            margin-left: map.get($form-field, label-icon-margin-left);
            margin-top: map.get($form-field, label-icon-margin-top);
            width: map.get($form-field, label-icon-size);
            height: map.get($form-field, label-icon-size);
          }
        }
      }

      .rds-form-field__container.rds-form-field--date-picker,
      .rds-form-field__container.rds-form-field--date-range {
        .rds-form-field__control-container {
          padding: map.get($form-field, input-padding-top)
            map.get($form-field, datepicker-right-padding)
            map.get($form-field, input-padding-bottom)
            map.get($form-field, datepicker-right-padding);
        }
      }

      &.rds-form-field--invalid,
      &.rds-form-field--focused {
        .rds-form-field__container.rds-form-field--date-picker,
        .rds-form-field__container.rds-form-field--date-range {
          .rds-form-field__control-container {
            padding: v.$rds-form-field__control-container-padding-top--focused
              map.get($form-field, datepicker-right-padding--focused)
              v.$rds-form-field__control-container-padding-bottom--focused
              map.get($form-field, input-padding-left-focus);
          }
        }
      }

      &.rds-form-field--side {
        --label-width: calc(var(--rds-control-label-width) * 1%);

        .rds-form-field__label-wrapper {
          .rds-form-field__label {
            @include m.rds-typography(map.get($form-field, label-name));

            .rds-icon {
              margin-left: map.get($form-field, label-icon-margin-left);
              width: map.get($form-field, label-icon-size);
              height: map.get($form-field, label-icon-size);
            }
          }
        }
      }

      &.rds-form-field--floating-label {
        .rds-form-field__container {
          .rds-form-field__control-container {
            &.rds-form-field-textarea {
              padding: f.rds-spacing(2) -
                v.$rds-form-field__control-container-border-width
                f.rds-spacing(3) -
                v.$rds-form-field__control-container-border-width
                f.rds-spacing(3) -
                v.$rds-form-field__control-container-border-width
                map.get($form-field, input-padding-left) -
                v.$rds-form-field__control-container-border-width;
            }
          }
        }

        &.rds-form-field--invalid {
          .rds-form-field__container {
            .rds-form-field__control-container {
              &.rds-form-field-textarea {
                padding: f.rds-spacing(2)
                  f.rds-spacing(3)
                  f.rds-spacing(3)
                  f.rds-spacing(5);
              }
            }
          }
        }

        &.rds-form-field--focused {
          .rds-form-field__container {
            .rds-form-field__control-container {
              &.rds-form-field-textarea {
                padding: f.rds-spacing(2)
                  f.rds-spacing(3)
                  f.rds-spacing(3)
                  f.rds-spacing(5);
              }
            }
          }
        }
      }

      .rds-form-field__container {
        .rds-form-field__control-container {
          padding: map.get($form-field, input-padding-top) -
            v.$rds-form-field__control-container-border-width
            map.get($form-field, input-padding-right) -
            v.$rds-form-field__control-container-border-width
            map.get($form-field, input-padding-bottom) -
            v.$rds-form-field__control-container-border-width
            map.get($form-field, input-padding-left) -
            v.$rds-form-field__control-container-border-width;

          &:not(.rds-form-field-textarea) {
            min-height: map.get($form-field, container-height);
            height: map.get($form-field, container-height);
          }

          &.rds-form-field-textarea {
            min-height: map.get($form-field, textarea-height);
            padding: map.get($form-field, textarea-padding);

            .rds-form-field__content-prefix {
              .rds-icon {
                margin-top: map.get($form-field, icon-padding);
              }
            }

            .rds-form-field__content-suffix {
              padding-right: map.get($form-field, textarea-padding-suffix);

              .rds-icon {
                margin-top: map.get($form-field, icon-padding);
              }
            }
          }
        }

        &.rds-form-field--select,
        &.rds-form-field--select-with-chips {
          .rds-form-field__control-container {
            min-height: map.get($form-field, container-height);
            height: unset;
          }
        }

        .rds-form-field__content {
          .rds-form-field__content-prefix {
            display: flex;
            align-items: center;
            @include m.rds-typography(ui-label-#{$size});

            .rds-icon {
              height: map.get($form-field, icon-size);
              width: map.get($form-field, icon-size);
            }

            .rds-form-field__control-prefix {
              display: inline-flex;
              margin-right: map.get($form-field, input-padding-right);
            }

            > *:last-child.rds-form-field__control-prefix {
              margin-right: map.get($form-field, input-padding-right);
            }

            .rds-form-field__control-prefix-separator-container {
              height: 100%;
              position: relative;
              display: flex;
              flex-direction: row;
              width: map.get($form-field, input-padding-right) +
                v.$rds-form-field__control-container-border-width;

              .rds-form-field__control-prefix-separator {
                position: absolute;
                top: map.get($form-field, separator-top);
                height: map.get($form-field, separator-height);
                padding-left: map.get($form-field, input-padding-left);
              }
            }
          }

          .rds-form-field__content-infix {
            @include m.rds-typography(ui-label-#{$size});
          }

          .rds-form-field__content-suffix {
            @include m.rds-typography(ui-label-#{$size});

            rds-icon:not(.rds-fixed-size-icon) {
              height: map.get($form-field, icon-size);
              width: map.get($form-field, icon-size);
            }

            .rds-form-field__control-suffix {
              display: inline-flex;
              margin-left: map.get($form-field, input-padding-left);
            }

            &-datepicker .rds-form-field__control-suffix {
              margin-left: v.$rds-datepicker-icon-spacing;
            }

            .rds-form-field__control-suffix-separator-container {
              width: map.get($form-field, input-padding-left);

              .rds-form-field__control-suffix-separator {
                top: map.get($form-field, separator-top);
                height: map.get($form-field, separator-height);
                padding-right: map.get($form-field, input-padding-right);
                margin-right: v.$rds-form-field__control-container-border-width;
              }
            }
          }
        }

        &.rds-form-field--textarea-field {
          .rds-form-field__control-container {
            .rds-form-field__content-prefix {
              .rds-form-field__control-prefix-separator {
                height: calc(
                  100% +
                    #{map.get($form-field, input-padding-top) +
                    map.get($form-field, textarea-suffix-separator)}
                );
                top: map.get($form-field, textarea-suffix-top);
              }
            }

            .rds-form-field__content-suffix {
              padding-right: map.get($form-field, input-padding-right) -
                v.$rds-form-field__control-container-padding-textarea-resizer;

              .rds-form-field__control-suffix-separator {
                height: calc(
                  100% +
                    #{map.get($form-field, input-padding-top) +
                    map.get($form-field, textarea-suffix-separator)}
                );
                top: map.get($form-field, textarea-suffix-top);
              }
            }
          }
        }

        @if ($size == 'l') {
          .rds-form-field__label-wrapper {
            .rds-form-field__label {
              padding-top: map.get($form-field, margin-top);
              @include m.rds-typography(
                v.$rds-form-field__label-typography-floating-base
              );

              .rds-form-field-icon-wrapper {
                width: v.$rds-form-field__label-icon-floating-base-size;
                height: v.$rds-form-field__label-icon-floating-base-size;
              }

              .rds-icon {
                width: v.$rds-form-field__label-icon-floating-base-size;
                height: v.$rds-form-field__label-icon-floating-base-size;
                margin-top: v.$rds-form-field__label-icon-floating-base-margin-top;
              }

              &--float {
                padding-top: 0;
                margin-bottom: 0;
                @include m.rds-typography(
                  v.$rds-form-field__label-typography-floating-float
                );

                .rds-form-field-icon-wrapper {
                  width: v.$rds-form-field__label-icon-floating-float;
                  height: v.$rds-form-field__label-icon-floating-float;
                }

                .rds-icon {
                  width: v.$rds-form-field__label-icon-floating-float;
                  height: v.$rds-form-field__label-icon-floating-float;
                  margin-top: v.$rds-form-field__label-icon-floating-float-margin-top;
                }
              }
            }
          }
        }

        .rds-form-field__control {
          @include m.rds-typography(ui-label-#{$size});
        }

        textarea.rds-form-field__control {
          min-height: map.get($form-field, textarea-height);
        }

        .rds-form-field__subscript-wrapper {
          margin-top: map.get($form-field, helper-text-margin);
        }
      }

      &.rds-form-field--focused {
        .rds-form-field__container {
          .rds-form-field__control-container {
            padding: map.get($form-field, input-padding-top)
              map.get($form-field, input-padding-right)
              map.get($form-field, input-padding-bottom)
              map.get($form-field, input-padding-left);

            &.rds-form-field-textarea {
              padding: map.get($form-field, textarea-padding-focused)
                f.rds-spacing(3)
                f.rds-spacing(3)
                map.get($form-field, textarea-padding-left-focused);
            }
          }
        }
      }

      &.rds-form-field--invalid {
        &:not(.rds-form-field--disabled) {
          .rds-form-field__control-container {
            padding: v.$rds-form-field__control-container-padding-top--invalid
              map.get($form-field, input-padding-right-focus)
              v.$rds-form-field__control-container-padding-bottom--invalid
              map.get($form-field, input-padding-left-focus);

            &.rds-form-field-textarea {
              padding: map.get($form-field, textarea-padding-focused)
                f.rds-spacing(3)
                f.rds-spacing(3)
                map.get($form-field, textarea-padding-left-focused);

              &.rds-form-field--static-label {
                .rds-form-field__control-container {
                  padding-top: v.$rds-form-field__control-container-padding-top--invalid +
                    map.get($form-field, margin-top);
                }
              }
            }
          }
        }
      }

      &.rds-form-field--floating-label {
        .rds-form-field__control-container.rds-form-field-textarea {
          .rds-form-field__content-prefix {
            .rds-form-field__control-prefix-separator {
              height: calc(
                100% +
                  #{map.get($form-field, input-padding-top) +
                  map.get($form-field, input-padding-bottom)} +
                  2px
              );
              top: -(
                  f.rds-spacing(2) -
                    v.$rds-form-field__control-container-border-width
                );
            }
          }

          .rds-form-field__content-suffix {
            .rds-form-field__control-suffix-separator {
              height: calc(
                100% +
                  #{map.get($form-field, input-padding-top) +
                  map.get($form-field, input-padding-bottom) +
                  v.$rds-icon-floating-textarea-padding +
                  2 *
                  v.$rds-form-field__control-container-border-width}
              );
              top: -(
                  f.rds-spacing(2) -
                    v.$rds-form-field__control-container-border-width +
                    v.$rds-icon-floating-textarea-padding
                );
            }
          }
        }
      }
    }
  }

  .rds-control-counter {
    color: f.rds-color(low-contrast);
    margin-left: auto;
    flex-shrink: 0;
    @include m.rds-typography(ui-component-text-s);
  }

  @include select.rds-select-option-panel();

  [rds-theme='dark'] {
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      filter: invert(0.9);
    }
  }

  .rds-form-field.rds-form-field--autocomplete-multi {
    .rds-form-field__container .rds-form-field__control-container {
      height: unset !important;
    }

    &.rds-form-field--floating-label .rds-form-field__control {
      margin-top: 0;
    }

    @each $size, $map in acv.$rds-autocomplete-sizes {
      &.rds-form-field--#{$size}:not(.rds-form-field--floating-label) {
        .rds-form-field__container {
          .rds-form-field__control-container {
            padding-block: 0;
          }
        }

        &.rds-form-field--has-value {
          .rds-form-field__container {
            .rds-form-field__control-container {
              padding-block: map.get($map, padding-block);
            }
          }

          &.rds-form-field--focused {
            .rds-form-field__container {
              .rds-form-field__control-container {
                padding-block: map.get($map, padding-block) +
                  v.$rds-form-field__control-container-border-width;
              }
            }
          }
        }
      }
    }
  }

  .rds-form-field.rds-form-field--select-with-chips {
    @each $size, $map in select.$rds-select-sizes {
      &.rds-form-field--#{$size}:not(.rds-form-field--floating-label) {
        .rds-form-field__container {
          .rds-form-field__control-container {
            padding-block: map.get($map, padding-block);
          }
        }

        &.rds-form-field--focused {
          .rds-form-field__container {
            .rds-form-field__control-container {
              padding-block: map.get($map, padding-block) +
                v.$rds-form-field__control-container-border-width;
            }
          }
        }
      }
    }
  }
}

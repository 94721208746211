@use 'sass:map';
@use 'sass:list';
@use 'sass:string';
@use '../functions/typography' as typo-func;
@use '../mixins/media-queries';
@use '../variables/typography';
@use '../variables/breakpoints';
@use '../variables/colors';

$rds-font-path-prefix: '../../../assets/fonts/' !default;

@mixin rds-font-faces() {
  html {
    -webkit-text-size-adjust: 100%;
  }

  body {
    font-family: typography.$rds-typography-font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  :root {
    --rds-font-family: #{typography.$rds-typography-font-family};
  }

  b,
  strong {
    font-weight: 600;
  }

  @each $font-name, $font-params in typography.$rds-roche-fonts {
    @each $font-single-params in $font-params {
      $font-paths: ();

      @each $extension, $format in typography.$rds-typography-extensions {
        $fontUrl: $rds-font-path-prefix +
          map.get($font-single-params, font-family) +
          '.' +
          $extension;
        $font-paths: list.append($font-paths, url($fontUrl) format($format));
      }

      @font-face {
        font-family: $font-name;
        src: list.zip($font-paths);
        font-weight: map.get($font-single-params, font-weight);
        font-style: map.get($font-single-params, font-style);
      }

      @font-face {
        font-family: map.get($font-single-params, font-family);
        src: list.zip($font-paths);
      }

      .font-#{string.to-lower-case(#{map.get($font-single-params, font-family)})} {
        font-family: map.get($font-single-params, font-family),
          typography.$rds-typography-font-family !important;
      }
    }

    .font-#{$font-name} {
      font-family: $font-name, typography.$rds-typography-font-family !important;
    }
  }
}

@mixin rds-single-typography($typography) {
  font-size: map.get($typography, 'font-size');
  font-weight: map.get($typography, 'font-weight');
  line-height: map.get($typography, 'line-height');
  @if (map.get($typography, 'text-transform')) {
    text-transform: map.get($typography, 'text-transform');
  } @else {
    text-transform: none;
  }
  @if (map.get($typography, 'letter-spacing')) {
    letter-spacing: map.get($typography, 'letter-spacing');
  } @else {
    letter-spacing: 0;
  }
  @if (map.get($typography, 'font-family')) {
    font-family: map.get($typography, 'font-family');
  } @else {
    font-family: typography.$rds-typography-font-family;
  }

  @if (map.get($typography, '-webkit-font-smoothing')) {
    -webkit-font-smoothing: map.get($typography, '-webkit-font-smoothing');
  } @else {
    -webkit-font-smoothing: antialiased;
  }

  @if (map.get($typography, '-moz-osx-font-smoothing')) {
    -moz-osx-font-smoothing: map.get($typography, '-moz-osx-font-smoothing');
  } @else {
    -moz-osx-font-smoothing: grayscale;
  }
}

@mixin rds-typography($name) {
  $typo-names: typo-func.get-typography-ordered-by-breakpoint(
    $name,
    typography.$rds-typography-list,
    breakpoints.$rds-breakpoints
  );

  @each $typo-name in $typo-names {
    $typography: map.get(typography.$rds-typography-list, $typo-name);
    $font-breakpoints: map.get($typography, 'breakpoint');

    @if ($font-breakpoints) {
      $font-breakpoints: typo-func.order-breakpoint-list(
        breakpoints.$rds-breakpoints,
        $font-breakpoints
      );

      @each $breakpoint in $font-breakpoints {
        @include media-queries.rds-media($breakpoint) {
          @include rds-single-typography($typography);
        }
      }
    } @else {
      @include rds-single-typography($typography);
    }
  }
}

@mixin rds-typography-non-responsive($name, $breakpoint) {
  $typo-names: typo-func.get-typography-ordered-by-breakpoint(
    $name,
    typography.$rds-typography-list,
    $breakpoint
  );
  $found: false;
  @each $typo-name in $typo-names {
    $typography: map.get(typography.$rds-typography-list, $typo-name);
    $font-breakpoints: map.get($typography, 'breakpoint');

    @if (list.index($font-breakpoints, $breakpoint) != null) {
      $found: true;
      $font-breakpoints: typo-func.order-breakpoint-list(
        breakpoints.$rds-breakpoints,
        $font-breakpoints
      );

      @include rds-single-typography($typography);
    }
  }

  @if not $found {
    @error "There is no breakpoint typography for name '#{$name}' and breakpoint '#{$breakpoint}'";
  }
}

@mixin rds-typography-classes() {
  body {
    color: var(#{colors.$rds-color-prefix}high-contrast);
  }

  @each $name in typography.$rds-typography-names {
    .#{$name} {
      @include rds-typography($name);
    }

    $typo-names: typo-func.get-typography-ordered-by-breakpoint(
      $name,
      typography.$rds-typography-list,
      breakpoints.$rds-breakpoints
    );

    @each $typo-name in $typo-names {
      $typography: map.get(typography.$rds-typography-list, $typo-name);
      $font-breakpoints: map.get($typography, 'breakpoint');

      @if ($font-breakpoints) {
        $font-breakpoints: typo-func.order-breakpoint-list(
          breakpoints.$rds-breakpoints,
          $font-breakpoints
        );

        @each $breakpoint in $font-breakpoints {
          .#{$name}--#{$breakpoint} {
            @include rds-typography-non-responsive($name, $breakpoint);
          }
        }
      }
    }
  }
}

@use '../../../core/styles/abstracts/functions/spacing';
@use '../../../core/styles/abstracts/functions/colors';
@use '../../../core/styles/abstracts/mixins/typography';
@use '../../../core/styles/library/icon-variables';

$rds-alert-border-radius: spacing.rds-spacing(1) !default;
$rds-alert-border-size: spacing.rds-spacing(2) !default;
$rds-alert-padding: spacing.rds-spacing(5) !default;
$rds-alert-min-width: 300px !default;

$rds-alert-icon-margin-right: spacing.rds-spacing(4) !default;
$rds-alert-icon-size: icon-variables.$rds-icon-size-l !default;

$rds-alert-close-margin-left: spacing.rds-spacing(5) !default;

$rds-alert-content-width: calc(100% - #{spacing.rds-spacing(4)}) !default;
$rds-alert-content-color: colors.rds-color(high-contrast) !default;

$rds-alert-info-border: $rds-alert-border-size solid
  colors.rds-color(informative) !default;
$rds-alert-info-background-color: colors.rds-color(
  alert-informative-bg
) !default;
$rds-alert-info-icon-color: colors.rds-color(informative) !default;

$rds-alert-warning-border: $rds-alert-border-size solid
  colors.rds-color(warning) !default;
$rds-alert-warning-background-color: colors.rds-color(
  alert-warning-bg
) !default;
$rds-alert-warning-icon-color: colors.rds-color(warning) !default;

$rds-alert-error-border: $rds-alert-border-size solid colors.rds-color(error) !default;
$rds-alert-error-background-color: colors.rds-color(alert-error-bg) !default;
$rds-alert-error-icon-color: colors.rds-color(error) !default;

$rds-alert-success-border: $rds-alert-border-size solid
  colors.rds-color(success) !default;
$rds-alert-success-background-color: colors.rds-color(
  alert-success-bg
) !default;
$rds-alert-success-icon-color: colors.rds-color(success) !default;

@mixin rds-alert() {
  .rds-alert {
    display: flex;
    align-items: center;
    border-radius: $rds-alert-border-radius;
    padding: $rds-alert-padding;
    min-width: $rds-alert-min-width;
    @include typography.rds-typography(ui-body-m-bold);

    .rds-alert-icon {
      align-self: flex-start;
      margin-right: $rds-alert-icon-margin-right;
      width: $rds-alert-icon-size;
      min-width: $rds-alert-icon-size;
      max-width: $rds-alert-icon-size;
      height: $rds-alert-icon-size;
      min-height: $rds-alert-icon-size;
      max-height: $rds-alert-icon-size;
    }

    &-info {
      border-left: $rds-alert-info-border;
      background-color: $rds-alert-info-background-color;

      .rds-alert-icon {
        color: $rds-alert-info-icon-color;
      }
    }

    &-warning {
      border-left: $rds-alert-warning-border;
      background-color: $rds-alert-warning-background-color;

      .rds-alert-icon {
        color: $rds-alert-warning-icon-color;
      }
    }

    &-error {
      border-left: $rds-alert-error-border;
      background-color: $rds-alert-error-background-color;

      .rds-alert-icon {
        color: $rds-alert-error-icon-color;
      }
    }

    &-success {
      border-left: $rds-alert-success-border;
      background-color: $rds-alert-success-background-color;

      .rds-alert-icon {
        color: $rds-alert-success-icon-color;
      }
    }

    .rds-alert-content {
      color: $rds-alert-content-color;
      width: $rds-alert-content-width;
    }

    .rds-alert-close {
      display: flex;
      justify-self: flex-end;
      margin-left: $rds-alert-close-margin-left;
    }
  }
}

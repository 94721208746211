@use 'sass:map';
@use '../../abstracts/variables' as v;
@use '../../abstracts/functions' as f;
@use '../icon-variables' as iv;
@use '../form-field-variables' as ffv;
@use './autocomplete-variables' as av;

@mixin rds-autocomplete() {
  .rds-autocomplete {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .rds-autocomplete-input-container {
      align-items: center;
      display: flex;
      width: 100%;

      &.rds-form-field__control-show-caret:not(
          .rds-form-field__control-disabled
        ) {
        position: relative;

        &:before {
          $caret-size: map.get(
            map.get(av.$rds-autocomplete-sizes, l),
            caret-size
          );

          position: absolute;
          left: 0;
          top: calc((100% - $caret-size) / 2);
          content: '';
          height: $caret-size;
          width: 1px;
          border-left: 1px solid ffv.$rds-form-field__control-caret-color;
        }

        &.rds-form-field__control-focused {
          &:before {
            display: none;
          }
        }
      }
    }

    .rds-autocomplete-icon-container {
      display: flex;
      align-items: center;
      height: iv.$rds-icon-size-l;
    }

    &.rds-autocomplete-disabled {
      .rds-autocomplete-icon-container {
        color: ffv.$rds-form-field__control-color--disabled;
      }
    }

    &.rds-autocomplete-multi {
      .rds-autocomplete-multi-container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      }
    }

    &.rds-autocomplete-floating-label {
      &.rds-autocomplete-multi {
        &.rds-autocomplete-floating-label {
          .rds-autocomplete-multi-container {
            margin-top: ffv.$rds-form-field__control-margin-top;
          }
        }
      }
    }

    @each $size, $map in av.$rds-autocomplete-sizes {
      &.rds-autocomplete-#{$size} {
        .rds-autocomplete-input-container {
          &.rds-form-field__control-show-caret:not(
              .rds-form-field__control-disabled
            ) {
            &:before {
              $caret-size: map.get(
                map.get(av.$rds-autocomplete-sizes, $size),
                caret-size
              );

              top: calc((100% - $caret-size) / 2);
              height: $caret-size;
            }
          }
        }

        &.rds-autocomplete-multi {
          gap: map.get($map, chips-gap);

          .rds-autocomplete-multi-container {
            gap: map.get($map, chips-gap);
          }
        }

        .rds-autocomplete-icon-container {
          gap: map.get($map, gap);
          margin-left: map.get($map, gap);
        }
      }
    }
  }
}

@use 'sass:map';
@use '../../../core/styles/abstracts/functions' as f;
@use '../../../core/styles/abstracts/mixins' as m;
@use '../../../core/styles/library/icon-variables' as v;

$rds-toast-spacing: f.rds-spacing(9) !default;
$rds-toast-min-width: 335px !default;

$rds-toast-themes: (
  info: (
    background: f.rds-color(bg-base-1),
    hover-background: f.rds-color(bg-base-1),
    border-left-color: f.rds-color(informative),
    color: f.rds-color(high-contrast),
  ),
  success: (
    background: f.rds-color(bg-base-1),
    hover-background: f.rds-color(bg-base-1),
    border-left-color: f.rds-color(success),
    color: f.rds-color(high-contrast),
  ),
  warning: (
    background: f.rds-color(bg-base-1),
    hover-background: f.rds-color(bg-base-1),
    border-left-color: f.rds-color(warning),
    color: f.rds-color(high-contrast),
  ),
  error: (
    background: f.rds-color(bg-base-1),
    hover-background: f.rds-color(bg-base-1),
    border-left-color: f.rds-color(error),
    color: f.rds-color(high-contrast),
  ),
) !default;

$rds-toast-border-radius: f.rds-spacing(1) !default;
$rds-toast-text-color: f.rds-color(high-contrast) !default;
$rds-toast-leading-icon-size: v.$rds-icon-size-l !default;
$rds-toast-close-icon-size: v.$rds-icon-size-s !default;
$rds-toast-container-left-width: f.rds-spacing(2) !default;
$rds-toast-with-title-height: f.rds-layout(6) !default;

@mixin color-variant($colors) {
  background: map.get($colors, background);
  color: map.get($colors, color);
  border-radius: $rds-toast-border-radius;

  &:hover {
    background-color: map.get($colors, hover-background);
  }

  .rds-container-left {
    @include rds-container-left-color(map.get($colors, border-left-color));
  }

  & .rds-toast__leading-icon {
    fill: map.get($colors, border-left-color);
  }
}

@mixin rds-container-left-color($color) {
  position: absolute;
  left: 0;
  top: 0;
  width: $rds-toast-container-left-width;
  height: 100%;
  background: $color;
  border-top-left-radius: $rds-toast-border-radius;
  border-bottom-left-radius: $rds-toast-border-radius;
}

@mixin toast() {
  .rds-toast-center-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .rds-toast-top-center {
    top: $rds-toast-spacing;
    right: 0;
    width: 100%;

    > .rds-toast {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  .rds-toast-bottom-center {
    bottom: $rds-toast-spacing;
    right: 0;
    width: 100%;

    > .rds-toast {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  .rds-toast-top-left {
    top: $rds-toast-spacing;
    left: $rds-toast-spacing;
  }

  .rds-toast-top-right {
    top: $rds-toast-spacing;
    right: $rds-toast-spacing;
  }

  .rds-toast-bottom-right {
    right: $rds-toast-spacing;
    bottom: $rds-toast-spacing;
  }

  .rds-toast-bottom-left {
    bottom: $rds-toast-spacing;
    left: $rds-toast-spacing;
  }

  .rds-toast-container {
    pointer-events: none;
    position: fixed;
    z-index: 999999;

    * {
      box-sizing: border-box;
    }

    &.toast-top-center,
    &.toast-bottom-center {
      .rds-toast {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .rds-toast {
      min-height: f.rds-spacing(11);
      position: relative;
      overflow: hidden;
      margin: f.rds-spacing(0) f.rds-spacing(0) f.rds-spacing(3);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: f.rds-spacing(4) f.rds-spacing(5) f.rds-spacing(4)
        #{f.rds-spacing(4) + $rds-toast-container-left-width};
      width: $rds-toast-min-width;
      border-radius: $rds-toast-border-radius;
      color: f.rds-color(high-contrast);
      pointer-events: auto;
      cursor: default;
      @include m.rds-elevation(3);

      &.rds-toast-dismiss-click {
        &:hover {
          cursor: pointer;
        }
      }

      @each $color-variant, $variant-properties in $rds-toast-themes {
        &.rds-toast-#{$color-variant} {
          @include color-variant($variant-properties);
        }
      }

      &__leading-icon {
        display: flex;
        margin-right: f.rds-spacing(4);
        min-width: $rds-toast-leading-icon-size;
        min-height: $rds-toast-leading-icon-size;
        max-width: $rds-toast-leading-icon-size;
        max-height: $rds-toast-leading-icon-size;
      }

      &__close-button {
        margin-left: f.rds-spacing(5);
      }

      &:not(.rds-toast-with-title) {
        .rds-toast-text {
          justify-content: flex-start;
        }
      }

      .rds-toast-inner {
        display: flex;
      }

      .rds-toast-text {
        word-break: break-word;
        display: flex;
        flex-direction: column;

        &__header {
          @include m.rds-typography(ui-label-m-bold);
        }

        &__message {
          @include m.rds-typography(ui-label-m-bold);
        }
      }

      &.rds-toast-with-title {
        min-height: $rds-toast-with-title-height;

        .rds-toast-text__message {
          color: $rds-toast-text-color;
          @include m.rds-typography(ui-body-s);
        }
      }
    }

    @include m.rds-media-s() {
      .rds-toast-top-left,
      .rds-toast-top-center,
      .rds-toast-top-right {
        top: $rds-toast-spacing;
        left: 0;
        width: 100%;
      }

      .rds-toast-bottom-left,
      .rds-toast-bottom-center,
      .rds-toast-bottom-right {
        bottom: $rds-toast-spacing;
        left: 0;
        width: 100%;
      }

      .rds-toast-center-center {
        top: 50%;
        left: 0;
        transform: translate(0%, -50%);
      }
    }
  }
}

@use 'sass:map';
@use '../../../core/styles/abstracts/mixins' as m;
@use '../../../core/styles/abstracts/functions' as f;

$rds-breadcrumb-font-color: f.rds-color(secondary-link) !default;
$rds-breadcrumb-font-color--hover: f.rds-color(secondary-link-hover) !default;
$rds-breadcrumb-font-color--active: f.rds-color(
  secondary-link-pressed
) !default;

$rds-breadcrumb-focused-color: f.rds-color(stroke-active-focus) !default;
$rds-breadcrumb-focused-outline-width: f.rds-spacing(1) !default;
$rds-breadcrumb-focused: $rds-breadcrumb-focused-outline-width solid
  $rds-breadcrumb-focused-color !default;
$rds-breadcrumb-focused-background-color: transparent !default;

$rds-breadcrumb-separator-height: 18px !default;
$rds-breadcrumb-separator-width: 18px !default;
$rds-breadcrumb-separator-color: f.rds-color(disabled) !default;

$rds-dots-width: 18px;

$rds-breadcrumbs-size: (
  m: (
    breakpoint: m,
    separator-margin: 0 #{f.rds-spacing(3)},
    typography: ui-component-text-m,
  ),
  l: (
    breakpoint: xxl,
    separator-margin: 0 #{f.rds-spacing(4)},
    typography: ui-label-m-bold,
  ),
) !default;

@mixin rds-breadcrumbs() {
  .rds-breadcrumbs {
    flex-flow: row nowrap;
    align-items: center;
    display: flex;

    @each $size, $value in $rds-breadcrumbs-size {
      @include m.rds-media(map.get($value, breakpoint)) {
        &.rds-breadcrumbs-#{$size} {
          @include m.rds-typography(map.get($value, typography));

          .rds-breadcrumb-separator {
            margin: map.get($value, separator-margin);
          }
        }
      }
    }

    // truncation of breadcrumbs for mobile
    // hides all of the breadcrumb items except first and last
    // displays '... >' in case more than 2 items are present, replacing second breadcrumb label and reusing it's arrow icon
    @include m.rds-media-s {
      @include m.rds-typography(ui-component-text-s);

      .rds-breadcrumb-separator {
        margin: 0 #{f.rds-spacing(2)};
      }

      .rds-breadcrumb-item:not(:first-child):not(:last-child) {
        display: none;
      }

      .rds-breadcrumb-item:nth-child(2):not(:last-child) {
        display: flex;

        .rds-breadcrumb-item {
          display: none;
        }

        .rds-icon {
          position: relative;
          margin-left: #{$rds-dots-width + f.rds-spacing(2)};

          &:before {
            color: $rds-breadcrumb-font-color;
            content: '...';
            position: absolute;
            width: $rds-dots-width;
            left: #{-$rds-dots-width - f.rds-spacing(1)};
            font-size: 18px;
            top: -3px;
          }
        }

        .rds-dots {
          display: block;
          color: $rds-breadcrumb-font-color;
          width: $rds-dots-width;
          font-size: 18px;
          cursor: auto;
        }

        .rds-no-content {
          content: none;
          margin-left: 0;
        }
      }
    }

    .rds-breadcrumb-item {
      display: flex;
      align-items: center;
      flex-direction: row;
      border-radius: $rds-breadcrumb-focused-outline-width;
      border: 0;
      color: $rds-breadcrumb-font-color;
      cursor: pointer;

      .rds-dots {
        display: none;
        cursor: auto;
      }

      &:hover {
        color: $rds-breadcrumb-font-color--hover;
      }

      &:active {
        color: $rds-breadcrumb-font-color--active;
      }

      &:focus-visible {
        outline: none;
      }

      &.cdk-focused.cdk-keyboard-focused {
        background-color: $rds-breadcrumb-focused-background-color;
        outline: $rds-breadcrumb-focused;
        outline-offset: f.rds-spacing(1);
        box-shadow: 0 0 0 f.rds-spacing(1)
          $rds-breadcrumb-focused-background-color;
      }
    }

    .rds-breadcrumb-separator {
      width: $rds-breadcrumb-separator-width;
      height: $rds-breadcrumb-separator-height;
      color: $rds-breadcrumb-separator-color;
      cursor: default;
    }
  }
}

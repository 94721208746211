@use 'sass:math';
@use '../abstracts/functions/spacing';
@use '../abstracts/functions/layout';
@use '../abstracts/functions/colors';
@use '../abstracts/mixins/border-radius';

$rds-border-width: 1px !default;
$rds-input-group-text-color: colors.rds-color(low-contrast) !default;
$rds-input-group-background: colors.rds-color(bg-base-1) !default;
$rds-input-group-border-color: colors.rds-color(stroke-enabled) !default;
$rds-padding-left: spacing.rds-spacing(5) - math.div($rds-border-width, 2) !default;
$rds-padding-left--input: spacing.rds-spacing(5) - $rds-border-width !default;
$rds-padding-right: spacing.rds-spacing(5) - $rds-border-width * 2 !default;
$rds-padding-left--button: spacing.rds-spacing(7) - $rds-border-width * 2 !default;
$rds-input-group-addon-padding-y: spacing.rds-spacing(3) !default;
$rds-input-group-addon-padding-x--l: spacing.rds-spacing(5) - $rds-border-width !default;
$rds-input-group-addon-padding-x--m: spacing.rds-spacing(4) - $rds-border-width !default;
$rds-input-group-addon-padding-x--s: spacing.rds-spacing(3) - $rds-border-width !default;
$rds-select-padding-left--hover-l: spacing.rds-spacing(5) - $rds-border-width !default;
$rds-select-padding-left--hover-m: spacing.rds-spacing(4) - $rds-border-width !default;
$rds-select-padding-left--hover-s: spacing.rds-spacing(3) - $rds-border-width !default;
$rds-prev-select--focused: spacing.rds-spacing(1) * 1.5 !default;
$rds-prev-select--hover: spacing.rds-spacing(1) !default;
$rds-prev-button--hover: math.div(spacing.rds-spacing(1), 2) !default;
$rds-prev-button--focused-l: spacing.rds-spacing(5) - $rds-border-width * 2 !default;
$rds-prev-button--focused-m: spacing.rds-spacing(4) - $rds-border-width !default;
$rds-prev-button--focused-s: spacing.rds-spacing(3) - $rds-border-width * 2 !default;
$rds-input-group-height--l: layout.rds-layout(4) !default;
$rds-input-group-height--m: layout.rds-layout(3) + spacing.rds-spacing(2) !default;
$rds-input-group-height--s: layout.rds-layout(2) + spacing.rds-spacing(2) !default;

@mixin rds-input-group() {
  .rds-component-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    width: 100%;

    .rds-component-group-text {
      display: flex;
      align-items: center;
      color: $rds-input-group-text-color;
      text-align: center;
      white-space: nowrap;
      align-self: stretch;
      background-color: $rds-input-group-background;
      border: $rds-border-width solid $rds-input-group-border-color;

      &.rds-component-group-text--l {
        height: $rds-input-group-height--l;
        padding: $rds-input-group-addon-padding-y
          $rds-input-group-addon-padding-x--l;
      }

      &.rds-component-group-text--m {
        height: $rds-input-group-height--m;
        padding: $rds-input-group-addon-padding-y
          $rds-input-group-addon-padding-x--m;
      }

      &.rds-component-group-text--s {
        height: $rds-input-group-height--s;
        padding: $rds-input-group-addon-padding-y
          $rds-input-group-addon-padding-x--s;
      }

      @include border-radius.border-radius();

      &:first-child {
        border-right: none;
        @include border-radius.border-end-radius(0);
      }

      &:last-child {
        @include border-radius.border-start-radius(0);
      }

      &:not(:first-child):not(:last-child) {
        @include border-radius.border-radius(0);
      }

      &:not(:last-child) {
        border-right: none;
      }
    }

    rds-form-field {
      position: relative;
      flex: 1 1 auto;
      min-width: 0;

      &.rds-form-field--l.rds-form-field--focused.rds-form-field--prev-button
        .rds-form-field__container.rds-form-field--date-picker
        .rds-form-field__control-container {
        padding-right: $rds-prev-button--focused-l;
      }

      &.rds-form-field--m.rds-form-field--focused.rds-form-field--prev-button
        .rds-form-field__container.rds-form-field--date-picker
        .rds-form-field__control-container {
        padding-right: $rds-prev-button--focused-m;
      }

      &.rds-form-field--s.rds-form-field--focused.rds-form-field--prev-button
        .rds-form-field__container.rds-form-field--date-picker
        .rds-form-field__control-container {
        padding-right: $rds-prev-button--focused-s;
      }

      &:first-child {
        .rds-form-field__container .rds-form-field__control-container {
          @include border-radius.border-end-radius(0);
        }
      }

      &:first-child:not(.rds-form-field--focused):not(.rds-form-field--hover) {
        .rds-form-field__container .rds-form-field__control-container {
          border-right: none;
        }
      }

      &:not(:first-child):not(:last-child).rds-form-field--focused {
        .rds-form-field__container .rds-form-field__control-container {
          @include border-radius.border-radius(0);
        }
      }

      &:not(:first-child):not(:last-child):not(.rds-form-field--hover):not(
          .rds-form-field--focused
        ) {
        .rds-form-field__container .rds-form-field__control-container {
          border-right: none;
        }
      }

      &:not(:first-child):not(:last-child) {
        .rds-form-field__container .rds-form-field__control-container {
          @include border-radius.border-radius(0);
        }
      }

      &:last-child {
        .rds-form-field__container .rds-form-field__control-container {
          @include border-radius.border-start-radius(0);
        }
      }

      &.rds-form-field--hover + .rds-form-field:not(.rds-form-field--focused) {
        .rds-form-field__container .rds-form-field__control-container {
          border-left: none;
        }
      }

      &.rds-form-field--hover
        + div:not(.rds-dropdown--focused)
        > .rds-dropdown-toggle-button {
        border-left: none;
      }

      &.rds-form-field--focused
        + div:not(.rds-dropdown--focused)
        > .rds-dropdown-toggle-button {
        border-left: none;
      }

      &.rds-form-field--focused + .rds-form-field {
        .rds-form-field__container .rds-form-field__control-container {
          border-left: none;
        }
      }

      &.rds-form-field--focused + .rds-form-field--hover {
        .rds-form-field__container .rds-form-field__control-container {
          border-left: none;
        }
      }

      &.rds-form-field--text-field.rds-form-field--l.rds-form-field--focused
        + .rds-form-field--select {
        .rds-form-field__container .rds-form-field__control-container {
          padding-left: $rds-select-padding-left--hover-l;
        }
      }

      &.rds-form-field--text-field.rds-form-field--m.rds-form-field--focused
        + .rds-form-field--select {
        .rds-form-field__container .rds-form-field__control-container {
          padding-left: $rds-select-padding-left--hover-m;
        }
      }

      &.rds-form-field--text-field.rds-form-field--s.rds-form-field--focused
        + .rds-form-field--select {
        .rds-form-field__container .rds-form-field__control-container {
          padding-left: $rds-select-padding-left--hover-s;
        }
      }

      &.rds-form-field--text-field.rds-form-field--hover
        + .rds-component-group-text {
        border-left: none;
      }

      &.rds-form-field--text-field.rds-form-field--focused
        + .rds-component-group-text {
        border-left: none;
      }

      &.rds-form-field--text-field:not(
          .rds-form-field--date-picker
        ).rds-form-field--prev-button {
        .rds-form-field__container .rds-form-field__control-container {
          padding-right: spacing.rds-spacing(5);
        }
      }

      &.rds-form-field--text-field:not(
          .rds-form-field--date-picker
        ).rds-form-field--prev-button.rds-form-field--hover:not(
          .rds-form-field--focused
        ) {
        .rds-form-field__container .rds-form-field__control-container {
          padding-right: spacing.rds-spacing(5);
        }
      }

      &.rds-form-field--text-field.rds-form-field--prev-button.rds-form-field--hover:not(
          .rds-form-field--focused
        ) {
        .rds-form-field__container .rds-form-field__control-container {
          border-right: none;
        }
      }

      &.rds-form-field--text-field.rds-form-field--prev-focused.rds-form-field--hover:not(
          .rds-form-field--focused
        ):not(:last-child) {
        .rds-form-field__container .rds-form-field__control-container {
          border-right: none;
        }
      }

      &.rds-form-field--select.rds-form-field--prev-focused.rds-form-field--hover:not(
          .rds-form-field--focused
        ):not(:last-child) {
        .rds-form-field__container .rds-form-field__control-container {
          border-right: none;
        }
      }
    }

    div:not(:hover):not(:focus):not(:last-child)
      > .rds-dropdown-toggle-button:not(:hover):not(:focus) {
      border-right: none;
    }

    .rds-dropdown--prev-button
      > .rds-dropdown-toggle-button:focus:not(
        [disabled]
      ).cdk-focused.cdk-keyboard-focused.rds-dropdown-toggle-button--l {
      padding-right: spacing.rds-spacing(4) - $rds-border-width;
    }

    .rds-dropdown--prev-button
      > .rds-dropdown-toggle-button:focus:not(
        [disabled]
      ).cdk-focused.cdk-keyboard-focused.rds-dropdown-toggle-button--m {
      padding-right: spacing.rds-spacing(4) - $rds-border-width;
    }

    .rds-dropdown--prev-button
      > .rds-dropdown-toggle-button:focus:not(
        [disabled]
      ).cdk-focused.cdk-keyboard-focused.rds-dropdown-toggle-button--s {
      padding-right: spacing.rds-spacing(2) - $rds-border-width;
    }

    div.rds-dropdown--hover + .rds-component-group-text {
      border-left: none;
    }

    div.rds-dropdown--focused + .rds-component-group-text {
      border-left: none;
    }

    div.rds-dropdown--hover + .rds-form-field--text-field {
      .rds-form-field__container .rds-form-field__control-container {
        border-left: none;
      }
    }

    div.rds-dropdown--hover + .rds-form-field--select {
      .rds-form-field__container .rds-form-field__control-container {
        border-left: none;
      }
    }

    div.rds-dropdown--focused + .rds-form-field--select {
      .rds-form-field__container .rds-form-field__control-container {
        border-left: none;
      }
    }

    div.rds-dropdown--hover + div > .rds-dropdown-toggle-button {
      border-left: none;
    }

    div.rds-dropdown--focused
      + div.rds-dropdown--hover
      > .rds-dropdown-toggle-button {
      border-left: none;
    }

    div.rds-dropdown--hover.rds-dropdown--prev-button
      > .rds-dropdown-toggle-button {
      border-right: none;
    }

    div.rds-dropdown--focused.rds-dropdown--prev-button
      > .rds-dropdown-toggle-button {
      border-right: none;
    }

    div.rds-dropdown--hover.rds-dropdown--prev-focused
      > .rds-dropdown-toggle-button {
      border-right: none;
    }

    div.rds-dropdown--focused + .rds-form-field--text-field {
      .rds-form-field__container .rds-form-field__control-container {
        border-left: none;
      }
    }

    div:first-child > .rds-dropdown-toggle-button,
    button:first-child {
      @include border-radius.border-end-radius(0);
    }

    div:last-child > .rds-dropdown-toggle-button,
    button:last-child {
      @include border-radius.border-start-radius(0);
    }

    div:not(:first-child):not(:last-child) > .rds-dropdown-toggle-button,
    button:not(:first-child):not(:last-child) {
      @include border-radius.border-radius(0);
    }
  }
}

@use '../abstracts/functions' as f;
@use '../abstracts/mixins' as m;

$rds-link-border-color: transparent !default;
$rds-link-outline-width: f.rds-spacing(1) !default;
$rds-link-border: $rds-link-outline-width solid $rds-link-border-color !default;
$rds-link-icon-spacing: f.rds-spacing(2) !default;

$rds-link-color-active: f.rds-color(informative) !default;
$rds-link-color-disabled: f.rds-color(disabled) !default;
$rds-link__focus-overlay-color: transparent !default;

$rds-link-color-primary: f.rds-color(informative) !default;
$rds-link-color-primary--hover: f.rds-color(informative-hover) !default;
$rds-link-color-secondary: f.rds-color(secondary-link) !default;
$rds-link-color-secondary--hover: f.rds-color(secondary-link-hover) !default;
$rds-link-color-visited: f.rds-color(accent) !default;
$rds-link-border-color-default--focus: f.rds-color(
  stroke-active-focus
) !default;

@mixin rds-link() {
  .rds-link {
    padding: 0;
    border-width: 0;
    border-radius: 0;
    outline: $rds-link-border;
    background: none;
    cursor: pointer;
    text-decoration: none;

    &[disabled] {
      cursor: default;
      pointer-events: none;
    }

    &.rds-link--primary {
      color: $rds-link-color-primary;

      &:hover:not([disabled]) {
        color: $rds-link-color-primary--hover;
      }
    }

    &.rds-link--secondary {
      color: $rds-link-color-secondary;

      &:hover:not([disabled]) {
        color: $rds-link-color-secondary--hover;
      }
    }

    &.rds-link-inline {
      color: $rds-link-color-primary;
      font-weight: 400;
      position: relative;
      text-decoration: underline;
      text-decoration-thickness: 0.0625rem;
      text-underline-offset: calc(0.33em - 3px);

      &:visited:not([disabled]) {
        color: $rds-link-color-visited;
      }

      &:focus:not([disabled]) {
        color: $rds-link-color-primary;
        text-decoration: none;
        border-radius: $rds-link-outline-width;
        outline-color: $rds-link-border-color-default--focus;
      }

      &:hover:not([disabled]) {
        cursor: pointer;
        color: $rds-link-color-primary--hover;
      }

      &[disabled] {
        color: inherit;
        text-decoration: none;
        font-weight: inherit;
        outline: none;
      }
    }

    &.rds-link-standalone {
      display: flex;
      align-items: center;
      position: relative;

      &.rds-link-l {
        @include m.rds-typography(ui-label-l-bold);
      }

      &.rds-link-m {
        @include m.rds-typography(ui-label-m-bold);
      }

      &.rds-link-s {
        @include m.rds-typography(ui-label-s-bold);
      }
    }

    &[disabled] {
      color: $rds-link-color-disabled;
      cursor: default;
    }

    &.rds-link--active:not([disabled]) {
      color: $rds-link-color-active;
    }

    &:focus-visible:not([disabled]):not(.rds-link-inline) {
      border-radius: $rds-link-outline-width;
      outline-color: $rds-link-border-color-default--focus;
      outline-offset: f.rds-spacing(1);
      background-color: $rds-link__focus-overlay-color;
      box-shadow: 0 0 0 f.rds-spacing(1) $rds-link__focus-overlay-color;
    }

    &.rds-link-before-icon .rds-icon {
      margin-right: $rds-link-icon-spacing;
    }

    &.rds-link-after-icon .rds-icon {
      margin-left: $rds-link-icon-spacing;
    }
  }
}

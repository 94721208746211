@use 'sass:map';
@use 'sass:math';
@use './media-queries';
@use '../variables/base' as b;
@use '../variables/breakpoints';
@use '../variables/grid';
@use '../functions/utils';

@mixin rds-make-container($gutter) {
  .container {
    width: 100%;
    max-width: breakpoints.$rds-breakpoint-xxl;
    padding-right: $gutter;
    padding-left: $gutter;
    margin-right: auto;
    margin-left: auto;
  }
}

@mixin rds-grid() {
  @each $name in map.keys(breakpoints.$rds-breakpoints) {
    $container-size-vars: map.get(grid.$rds-container-variables, $name);
    $column-gutter: map.get($container-size-vars, column-gutter);
    $container-gutter: map.get($container-size-vars, container-gutter);

    @include media-queries.rds-media($name) {
      .row {
        @include rds-row($column-gutter);

        > * {
          @include rds-col-ready();
        }
      }
    }
  }

  @each $name in map.keys(breakpoints.$rds-breakpoints) {
    $container-size-vars: map.get(grid.$rds-container-variables, $name);
    $container-gutter: map.get($container-size-vars, container-gutter);

    @if $name == s {
      @include rds-make-container($container-gutter);

      @include rds-grid-columns(grid.$rds-grid-columns, $name, false);

      @include media-queries.rds-media($name) {
        @include rds-grid-columns(grid.$rds-grid-columns, $name, false, true);
      }
    }

    @include media-queries.rds-media($name) {
      @include rds-make-container($container-gutter);
      @include rds-grid-columns(grid.$rds-grid-columns, $name);
    }
  }
}

@mixin rds-row($gutter) {
  --#{b.$rds-prefix}gutter-x: #{$gutter};
  --#{b.$rds-prefix}gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--#{b.$rds-prefix}gutter-y));
  margin-right: calc(-0.5 * var(--#{b.$rds-prefix}gutter-x));
  margin-left: calc(-0.5 * var(--#{b.$rds-prefix}gutter-x));
}

@mixin rds-col-ready() {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--#{b.$rds-prefix}gutter-x) * 0.5);
  padding-left: calc(var(--#{b.$rds-prefix}gutter-x) * 0.5);
  margin-top: var(--#{b.$rds-prefix}gutter-y);
}

@mixin rds-col($size: false, $single-column, $columns: grid.$rds-grid-columns) {
  @if $size {
    $width: if(
      $single-column,
      '100%',
      math.percentage(utils.divide($size, $columns))
    );

    flex: 0 0 auto;
    width: #{$width};
  } @else {
    flex: 1 1 0;
    max-width: 100%;
  }
}

@mixin rds-col-auto($single-column) {
  $width: if($single-column, '100%', auto);
  flex: 0 0 #{$width};
  width: #{$width};
}

@mixin rds-col-offset($size, $single-column, $columns: grid.$rds-grid-columns) {
  $num: if($single-column, 0, utils.divide($size, $columns));
  margin-left: if($num == 0, 0, math.percentage($num));
}

@mixin row-cols($count, $single-column) {
  > * {
    $width: if($single-column, '100%', utils.divide(100%, $count));
    flex: 0 0 auto;
    width: #{$width};
  }
}

@mixin rds-grid-columns(
  $columns,
  $breakpoint-name,
  $add-infix: true,
  $single-column: false
) {
  $infix: if($add-infix, utils.infix($breakpoint-name), '');

  .col#{$infix} {
    $width: if($single-column, 100%, 0%);
    flex: 1 0 #{$width};
  }

  .row-cols#{$infix}-auto > * {
    @include rds-col-auto($single-column);
  }

  @if grid.$rds-grid-row-columns > 0 {
    @for $i from 1 through grid.$rds-grid-row-columns {
      .row-cols#{$infix}-#{$i} {
        @include row-cols($i, $single-column);
      }
    }
  }

  .col-auto {
    @include rds-col-auto($single-column);
  }

  @if $columns > 0 {
    @for $i from 1 through $columns {
      .col#{$infix}-#{$i} {
        @include rds-col($i, $single-column, $columns);
      }
    }

    @for $i from 0 through ($columns - 1) {
      @if not($i == 0) {
        .offset#{$infix}-#{$i} {
          @include rds-col-offset($i, $single-column, $columns);
        }
      }
    }
  }

  // Gutters
  @each $key, $value in grid.$rds-gutters {
    .g#{$infix}-#{$key},
    .gx#{$infix}-#{$key} {
      --#{b.$rds-prefix}gutter-x: #{$value};
    }

    .g#{$infix}-#{$key},
    .gy#{$infix}-#{$key} {
      --#{b.$rds-prefix}gutter-y: #{$value};
    }
  }
}

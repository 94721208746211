@use 'sass:map';
@use '../abstracts/functions' as f;
@use '../abstracts/mixins' as m;
@use './icon-variables' as iv;

$rds-chip-border-width: 0.5 * f.rds-spacing(1) !default;
$rds-chip-border-style: solid !default;
$rds-chip-border-focused-width: f.rds-spacing(1) !default;
$rds-chip-border-radius: 1.5 * f.rds-spacing(6) !default;
$rds-chip-close-icon-size: iv.$rds-icon-size-xs !default;

$rds-chip--focused-border-color: f.rds-color(stroke-active-focus) !default;

$rds-chip--l-height: f.rds-spacing(11) !default;
$rds-chip--l-min-width: 100px !default;
$rds-chip--l-padding: 0 #{f.rds-spacing(7) - $rds-chip-border-width} !default;
$rds-chip--l-icon-margin: 0 f.rds-spacing(3) 0 -#{f.rds-spacing(3)} !default;
$rds-chip--l-icon-size: iv.$rds-icon-size-l !default;
$rds-chip--l-avatar-margin: 0 f.rds-spacing(3) 0 -#{f.rds-spacing(6)} !default;
$rds-chip--l-avatar-size: f.rds-spacing(10) !default;
$rds-chip--l-close-icon-margin-left: f.rds-spacing(3) !default;
$rds-chip--l-close-icon-margin-right: -#{f.rds-spacing(3)} !default;

$rds-chip--m-height: 1.5 * f.rds-spacing(7) !default;
$rds-chip--m-min-width: 72px !default;
$rds-chip--m-padding: 0 #{f.rds-spacing(5) - $rds-chip-border-width} !default;
$rds-chip--m-icon-margin: 0 f.rds-spacing(3) 0 -#{f.rds-spacing(2)} !default;
$rds-chip--m-icon-size: iv.$rds-icon-size-m !default;
$rds-chip--m-avatar-margin: 0 f.rds-spacing(3) 0 -#{f.rds-spacing(4)} !default;
$rds-chip--m-avatar-size: f.rds-spacing(8) !default;
$rds-chip--m-close-icon-margin-left: f.rds-spacing(3) !default;
$rds-chip--m-close-icon-margin-right: -#{f.rds-spacing(2)} !default;

$rds-chip--s-height: f.rds-spacing(8) !default;
$rds-chip--s-min-width: 60px !default;
$rds-chip--s-padding: 0 #{f.rds-spacing(4) - $rds-chip-border-width} !default;
$rds-chip--s-icon-margin: 0 f.rds-spacing(2) 0 -#{f.rds-spacing(2)} !default;
$rds-chip--s-icon-size: iv.$rds-icon-size-s !default;
$rds-chip--s-avatar-margin: 0 f.rds-spacing(2) 0 -#{f.rds-spacing(3) +
  f.rds-spacing(1)} !default;
$rds-chip--s-avatar-size: f.rds-spacing(7) !default;
$rds-chip--s-close-icon-margin-left: f.rds-spacing(2) !default;
$rds-chip--s-close-icon-margin-right: -#{f.rds-spacing(2)} !default;

$rds-chip--disabled-border-color: f.rds-color(stroke-enabled) !default;
$rds-chip--disabled-background-color: f.rds-color(bg-disabled) !default;
$rds-chip--disabled-color: f.rds-color(disabled) !default;

$rds-chip--selected-disabled-border-color: f.rds-color(bg-disabled-2) !default;
$rds-chip--selected-disabled-background-color: f.rds-color(
  bg-disabled-2
) !default;
$rds-chip--selected-disabled-color: f.rds-color(disabled) !default;

$rds-chip-list-chip-gap-s: f.rds-spacing(3) !default;
$rds-chip-list-chip-gap-m: f.rds-spacing(4) !default;
$rds-chip-list-chip-gap-l: f.rds-spacing(4) !default;

$rds-chip-types: (
  blue: (
    border-color: f.rds-color(chips-1-stroke-enabled),
    color: f.rds-color(informative),
    background: f.rds-color(chips-bg-enabled),
    hover-border-color: f.rds-color(chips-1-stroke-hover),
    hover-color: f.rds-color(informative),
    hover-background: f.rds-color(chips-bg-enabled),
    selected-border-color: f.rds-color(chips-1-bg-pressed),
    selected-color: f.rds-color(chips-1-text-pressed),
    selected-background: f.rds-color(chips-1-bg-pressed),
  ),
  neutral: (
    border-color: f.rds-color(chips-2-stroke-enabled),
    color: f.rds-color(neutral),
    background: f.rds-color(chips-bg-enabled),
    hover-border-color: f.rds-color(chips-2-stroke-hover),
    hover-color: f.rds-color(neutral),
    hover-background: f.rds-color(chips-bg-enabled),
    selected-border-color: f.rds-color(chips-2-bg-pressed),
    selected-color: f.rds-color(chips-2-text-pressed),
    selected-background: f.rds-color(chips-2-bg-pressed),
  ),
) !default;

$rds-chip-sizes: (
  s: (
    height: $rds-chip--s-height,
    min-width: $rds-chip--s-min-width,
    padding: $rds-chip--s-padding,
    icon-margin: $rds-chip--s-icon-margin,
    icon-size: $rds-chip--s-icon-size,
    avatar-margin: $rds-chip--s-avatar-margin,
    avatar-size: $rds-chip--s-avatar-size,
    close-icon-margin-inline: $rds-chip--s-close-icon-margin-left
      $rds-chip--s-close-icon-margin-right,
    gap: $rds-chip-list-chip-gap-s,
  ),
  m: (
    height: $rds-chip--m-height,
    min-width: $rds-chip--m-min-width,
    padding: $rds-chip--m-padding,
    icon-margin: $rds-chip--m-icon-margin,
    icon-size: $rds-chip--m-icon-size,
    avatar-margin: $rds-chip--m-avatar-margin,
    avatar-size: $rds-chip--m-avatar-size,
    close-icon-margin-inline: $rds-chip--m-close-icon-margin-left
      $rds-chip--m-close-icon-margin-right,
    gap: $rds-chip-list-chip-gap-m,
  ),
  l: (
    height: $rds-chip--l-height,
    min-width: $rds-chip--l-min-width,
    padding: $rds-chip--l-padding,
    icon-margin: $rds-chip--l-icon-margin,
    icon-size: $rds-chip--l-icon-size,
    avatar-margin: $rds-chip--l-avatar-margin,
    avatar-size: $rds-chip--l-avatar-size,
    close-icon-margin-inline: $rds-chip--l-close-icon-margin-left
      $rds-chip--l-close-icon-margin-right,
    gap: $rds-chip-list-chip-gap-l,
  ),
) !default;

@mixin rds-chip-size-relative($size) {
  $values: map.get($rds-chip-sizes, $size);
  $avatar-size: map.get($values, avatar-size);
  min-height: map.get($values, height);
  min-width: map.get($values, min-width);
  padding: map.get($values, padding);

  @include m.rds-typography('ui-label-#{$size}-bold');

  .rds-chip__leading-icon {
    margin: map.get($values, icon-margin);
    width: map.get($values, icon-size);
    height: map.get($values, icon-size);
  }

  .rds-chip__avatar {
    margin: map.get($values, avatar-margin);
    width: $avatar-size;
    height: $avatar-size;

    .rds-avatar__container {
      width: $avatar-size;
      height: $avatar-size;
    }
  }

  .rds-avatar .rds-avatar__container .rds-avatar-image {
    width: $avatar-size;
    height: $avatar-size;
  }

  .rds-chip-close-button {
    margin-inline: map.get($values, close-icon-margin-inline);

    &::after,
    &::before {
      border-radius: 100vmax;
      width: f.rds-spacing(6);
      height: f.rds-spacing(6);
      inset: 0;
      top: -1px;
      left: -1px;
    }

    &::before {
      width: f.rds-spacing(6);
      height: f.rds-spacing(6);
    }
  }
}

@mixin rds-chips() {
  .rds-chips__chip {
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: $rds-chip-border-width;
    border-style: $rds-chip-border-style;
    border-color: map.get(map.get($rds-chip-types, blue), border-color);
    border-radius: $rds-chip-border-radius;
    width: fit-content;
    background-color: map.get(map.get($rds-chip-types, blue), background);
    color: map.get(map.get($rds-chip-types, blue), color);
    user-select: none;

    &:hover {
      cursor: default;
    }

    &:active:not([disabled]) {
      box-shadow: none;
    }

    &:focus-within {
      outline: none;
    }

    // don't apply the outline if the chip is disabled or its close button is focused
    &.cdk-focused.cdk-keyboard-focused:not(
        [aria-disabled='true'],
        [disabled]
      ):not(:has(.rds-chip-close-button:focus-visible)) {
      outline: $rds-chip--focused-border-color $rds-chip-border-style
        $rds-chip-border-focused-width;
      outline-offset: -1px;
    }

    .rds-chip__avatar {
      border-radius: 100vmax;
    }

    .rds-chip__leading-icon {
      color: map.get(map.get($rds-chip-types, blue), color);
    }

    .rds-chip-close-button:not([disabled]) {
      color: map.get(map.get($rds-chip-types, blue), color);
    }

    &:hover:not([aria-disabled='true'], [disabled]) {
      cursor: pointer;
      border-color: map.get(map.get($rds-chip-types, blue), hover-border-color);
      color: map.get(map.get($rds-chip-types, blue), hover-color);
      background-color: map.get(
        map.get($rds-chip-types, blue),
        hover-background
      );
      @include m.rds-elevation(1);

      .rds-chip__leading-icon {
        color: map.get(map.get($rds-chip-types, blue), hover-color);
      }

      .rds-chip-close-button:not([disabled]) {
        color: map.get(map.get($rds-chip-types, blue), hover-color);
      }
    }

    .rds-chip-close-button > .rds-icon.rds-chip-close-icon {
      width: $rds-chip-close-icon-size;
      height: $rds-chip-close-icon-size;
    }

    .rds-avatar:hover .rds-avatar-overlay {
      display: none;
    }

    &--selected:not([aria-disabled='true'], [disabled]),
    &--active:not([aria-disabled='true'], [disabled]),
    &:active:not([aria-disabled='true'], [disabled]) {
      border-color: map.get(
        map.get($rds-chip-types, blue),
        selected-border-color
      );
      color: map.get(map.get($rds-chip-types, blue), selected-color);
      background: map.get(map.get($rds-chip-types, blue), selected-background);
    }

    .rds-chip-action {
      display: inline-flex;
      align-items: center;
      outline: none;

      &.cdk-focused.cdk-keyboard-focused:not([disabled]) {
        color: map.get(map.get($rds-chip-types, blue), hover-color);
        outline: $rds-chip--focused-border-color $rds-chip-border-style
          $rds-chip-border-focused-width;
        outline-offset: 0;
        border-radius: 50%;
      }
    }

    @each $chip-type, $chip-color-map in $rds-chip-types {
      &.rds-chips--type-#{$chip-type + ''} {
        border-color: map.get($chip-color-map, border-color);
        color: map.get($chip-color-map, color);

        .rds-chip__leading-icon {
          color: map.get($chip-color-map, color);
        }

        .rds-chip-close-button:not([disabled]) {
          color: map.get($chip-color-map, color);
        }

        &:hover:not([aria-disabled='true'], [disabled]) {
          border-color: map.get($chip-color-map, hover-border-color);
          color: map.get($chip-color-map, hover-color);
          background: map.get($chip-color-map, hover-background);

          .rds-chip__leading-icon {
            color: map.get($chip-color-map, hover-color);
          }

          .rds-chip-close-button:not([disabled]) {
            color: map.get($chip-color-map, hover-color);
          }
        }

        &.rds-chips__chip--selected:not([aria-disabled='true'], [disabled]),
        &:active:not([aria-disabled='true'], [disabled]) {
          border-color: map.get($chip-color-map, selected-border-color);
          color: map.get($chip-color-map, selected-color);
          background: map.get($chip-color-map, selected-background);
        }
      }
    }

    @each $chip-size, $chip-size-map in $rds-chip-sizes {
      .rds-chips--size-#{$chip-size} {
        @include rds-chip-size-relative($chip-size);
      }
    }

    &[disabled]:not(.rds-chips__chip--selected),
    &[aria-disabled='true']:not(.rds-chips__chip--selected) {
      border-color: $rds-chip--disabled-border-color;
      background-color: $rds-chip--disabled-background-color;
      color: $rds-chip--disabled-color;

      .rds-chip__avatar {
        opacity: 0.3;
      }

      .rds-chip__leading-icon {
        color: $rds-chip--disabled-color;
      }
    }

    &--selected[disabled],
    &--selected[aria-disabled='true'] {
      border-color: $rds-chip--selected-disabled-border-color;
      background-color: $rds-chip--selected-disabled-background-color;
      color: $rds-chip--selected-disabled-color;

      .rds-chip__avatar {
        opacity: 0.3;
      }

      .rds-chip__leading-icon {
        color: $rds-chip--selected-disabled-color;
      }
    }
  }

  .rds-chips {
    display: flex;
    flex-wrap: wrap;

    &:focus-within {
      outline: none;
    }

    @each $chip-size, $chip-size-map in $rds-chip-sizes {
      &.rds-chips--size-#{$chip-size} {
        gap: map.get($chip-size-map, gap);
      }
    }
  }

  .rds-chips-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@use '@rds/angular-components/styles/angular-components' as rds;
@include rds.rds-angular-components();
@import '../../../node_modules/angular-calendar/css/angular-calendar.css';

html,
body {
  position: fixed;
  width: 100%;
  height: 100%;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 500px;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 99;
}

body {
  overflow: hidden;
}

@media (max-width: 768px) {
  .rds-header .rds-header-title .rds-header-logo svg {
    display: none;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes fade-out {
  to {
    opacity: 0;
  }
}

@keyframes slide-from-right {
  from {
    transform: translateX(30px);
  }
}

@keyframes slide-to-left {
  to {
    transform: translateX(-30px);
  }
}

::view-transition-old(root) {
  animation: 90ms cubic-bezier(0.4, 0, 1, 1) both fade-out,
  300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-left;
}

::view-transition-new(root) {
  animation: 210ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in,
  300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-right;
}

.rds-form-field__select-trigger-value {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.booking-details-form {
  .rds-form-field .rds-form-field__container {
    padding-bottom: 24px;
  }
}

.dialog-panel {
  .rds-dialog {
    background: transparent;
  }
}

.rds-carousel {
  padding-left: 0 !important;
  padding-right: 0 !important;
  .rds-carousel__item {
    max-height: 85vh;
  }
}

.rds-carousel__carousel-pagination {
  color: black !important;
  margin-top: 0 !important;
  position: absolute;
  left: calc(50% - 61px);
  top: calc(90% - 20px);
  @media (max-width: 768px) {
    left: calc(50% - 54px);
    top: 85%;
  }
  .rds-button.rds-button--icon {
    color: white !important;
  }
}

.rds-carousel__carousel-pagination .rds-carousel__carousel-pagination--text  {
  color: white !important;
}

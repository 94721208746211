@use 'sass:map';
@use '../abstracts/functions' as f;
@use '../abstracts/mixins' as m;

$rds-badge-vertical-spacing: f.rds-spacing(2) !default;
$rds-badge-horizontal-single-char-spacing: calc(
  #{f.rds-spacing(3) + f.rds-spacing(1)}
) !default;
$rds-badge-horizontal-multiple-char-spacing: f.rds-spacing(4) !default;
$rds-badge-single-char-padding: $rds-badge-vertical-spacing
  $rds-badge-horizontal-single-char-spacing !default;
$rds-badge-multiple-char-padding: $rds-badge-vertical-spacing
  $rds-badge-horizontal-multiple-char-spacing !default;
$rds-badge-height: f.rds-spacing(8) !default;

$rds-badge-s-vertical-spacing: f.rds-spacing(0) !default;
$rds-badge-s-horizontal-spacing: calc(
  #{f.rds-spacing(2) + f.rds-spacing(1)}
) !default;
$rds-badge-s-padding: $rds-badge-s-vertical-spacing
  $rds-badge-s-horizontal-spacing !default;
$rds-badge-s-height: f.rds-spacing(6) !default;

$rds-badge-dot-size: f.rds-spacing(3) !default;

$rds-badge-l-vertical-spacing: calc(
  #{f.rds-spacing(2) + f.rds-spacing(1)}
) !default;
$rds-badge-l-horizontal-single-char-spacing: calc(
  #{f.rds-spacing(4) + f.rds-spacing(1)}
) !default;
$rds-badge-l-horizontal-multiple-char-spacing: f.rds-spacing(5) !default;
$rds-badge-l-single-char-padding: $rds-badge-l-vertical-spacing
  $rds-badge-l-horizontal-single-char-spacing !default;
$rds-badge-l-multiple-char-padding: $rds-badge-l-vertical-spacing
  $rds-badge-l-horizontal-multiple-char-spacing !default;
$rds-badge-l-height: calc(#{f.rds-spacing(9) + f.rds-spacing(2)}) !default;

$rds-badge-default-background-color: f.rds-color(badge-grey-bg);
$rds-badge-default-color: f.rds-color(on-light-surface);
$rds-badge-text-dot: f.rds-color(high-contrast);

$rds-badge-info-default-background-color: f.rds-color(bg-informative) !default;
$rds-badge-info-default-color: f.rds-color(on-dark-surface) !default;
$rds-badge-info-dot-background-color: f.rds-color(informative) !default;

$rds-badge-success-default-background-color: f.rds-color(bg-success) !default;
$rds-badge-success-default-color: f.rds-color(on-dark-surface) !default;
$rds-badge-success-dot-background-color: f.rds-color(success) !default;

$rds-badge-warning-default-background-color: f.rds-color(bg-warning) !default;
$rds-badge-warning-default-color: f.rds-color(on-light-surface) !default;
$rds-badge-warning-dot-background-color: f.rds-color(warning) !default;

$rds-badge-error-default-background-color: f.rds-color(bg-error) !default;
$rds-badge-error-default-color: f.rds-color(on-dark-surface) !default;
$rds-badge-error-dot-background-color: f.rds-color(error) !default;

$rds-badge-neutral-default-background-color: f.rds-color(
  badge-grey-bg
) !default;
$rds-badge-neutral-default-color: f.rds-color(on-light-surface) !default;
$rds-badge-neutral-dot-background-color: f.rds-color(grey-3) !default;

$rds-badge-blue-minimal-background-color: f.rds-color(badge-blue-bg) !default;
$rds-badge-blue-minimal-color: f.rds-color(on-light-surface) !default;
$rds-badge-green-minimal-background-color: f.rds-color(badge-green-bg) !default;
$rds-badge-green-minimal-color: f.rds-color(on-light-surface) !default;
$rds-badge-yellow-minimal-background-color: f.rds-color(
  badge-yellow-bg
) !default;
$rds-badge-yellow-minimal-color: f.rds-color(on-light-surface) !default;
$rds-badge-red-minimal-background-color: f.rds-color(badge-red-bg) !default;
$rds-badge-red-minimal-color: f.rds-color(on-light-surface) !default;
$rds-badge-neutral-minimal-background-color: f.rds-color(
  badge-neutral-bg
) !default;
$rds-badge-neutral-minimal-color: f.rds-color(on-light-surface) !default;
$rds-badge-orange-minimal-background-color: f.rds-color(
  badge-orange-bg
) !default;
$rds-badge-orange-minimal-color: f.rds-color(on-light-surface) !default;
$rds-badge-black-minimal-background-color: f.rds-color(
  badge-dark-grey-bg
) !default;
$rds-badge-black-minimal-color: f.rds-color(on-dark-surface) !default;
$rds-badge-pink-minimal-background-color: f.rds-color(
  badge-purple-1-bg
) !default;
$rds-badge-pink-minimal-color: f.rds-color(on-light-surface) !default;
$rds-badge-purple-minimal-background-color: f.rds-color(
  badge-purple-2-bg
) !default;
$rds-badge-purple-minimal-color: f.rds-color(on-dark-surface) !default;

$rds-badge-other-dot-background: f.rds-color(accent) !default;

$rds-badge-color-variants: (
  'info',
  'success',
  'warning',
  'error',
  'neutral',
  'blue',
  'green',
  'red',
  'yellow',
  'orange',
  'black',
  'purple',
  'pink',
  'other'
) !default;

$rds-badge-themes: (
  'info-default': (
    'background-color': $rds-badge-info-default-background-color,
    'color': $rds-badge-info-default-color,
  ),
  'blue-minimal': (
    'background-color': $rds-badge-blue-minimal-background-color,
    'color': $rds-badge-blue-minimal-color,
  ),
  'info-dot': (
    'background-color': $rds-badge-info-dot-background-color,
  ),
  'success-default': (
    'background-color': $rds-badge-success-default-background-color,
    'color': $rds-badge-success-default-color,
  ),
  'green-minimal': (
    'background-color': $rds-badge-green-minimal-background-color,
    'color': $rds-badge-green-minimal-color,
  ),
  'success-dot': (
    'background-color': $rds-badge-success-dot-background-color,
  ),
  'warning-default': (
    'background-color': $rds-badge-warning-default-background-color,
    'color': $rds-badge-warning-default-color,
  ),
  'yellow-minimal': (
    'background-color': $rds-badge-yellow-minimal-background-color,
    'color': $rds-badge-yellow-minimal-color,
  ),
  'warning-dot': (
    'background-color': $rds-badge-warning-dot-background-color,
  ),
  'error-default': (
    'background-color': $rds-badge-error-default-background-color,
    'color': $rds-badge-error-default-color,
  ),
  'red-minimal': (
    'background-color': $rds-badge-red-minimal-background-color,
    'color': $rds-badge-red-minimal-color,
  ),
  'error-dot': (
    'background-color': $rds-badge-error-dot-background-color,
  ),
  'neutral-default': (
    'background-color': $rds-badge-neutral-default-background-color,
    'color': $rds-badge-neutral-default-color,
  ),
  'neutral-minimal': (
    'background-color': $rds-badge-neutral-minimal-background-color,
    'color': $rds-badge-neutral-minimal-color,
  ),
  'neutral-dot': (
    'background-color': $rds-badge-neutral-dot-background-color,
  ),
  'orange-minimal': (
    'background-color': $rds-badge-orange-minimal-background-color,
    'color': $rds-badge-orange-minimal-color,
  ),
  'black-minimal': (
    'background-color': $rds-badge-black-minimal-background-color,
    'color': $rds-badge-black-minimal-color,
  ),
  'other-dot': (
    'background-color': $rds-badge-other-dot-background,
  ),
  'pink-minimal': (
    'background-color': $rds-badge-pink-minimal-background-color,
    'color': $rds-badge-pink-minimal-color,
  ),
  'purple-minimal': (
    'background-color': $rds-badge-purple-minimal-background-color,
    'color': $rds-badge-purple-minimal-color,
  ),
) !default;

$rds-badge-sizes: (
  s: 'ui-component-text-s',
  m: 'ui-label-s-bold',
  l: 'ui-label-m-bold',
) !default;

@mixin dot() {
  font-size: 0;
  padding: 0;
  width: $rds-badge-dot-size;
  height: $rds-badge-dot-size;
}

@mixin rds-badge() {
  .rds-badge--container {
    position: relative;
    display: inline-flex;
    align-items: center;

    .rds-badge {
      --single-char-padding: #{$rds-badge-single-char-padding};
      --multiple-char-padding: #{$rds-badge-multiple-char-padding};
      line-height: unset;
      display: inline-flex;
      align-items: center;
      border-radius: 100vmax;
      white-space: nowrap;
      padding: var(--padding, var(--multiple-char-padding));
      height: $rds-badge-height;
      background-color: $rds-badge-default-background-color;
      color: $rds-badge-default-color;
      @include m.rds-typography(map.get($rds-badge-sizes, 'm'));

      @each $color-variant in $rds-badge-color-variants {
        $colors-for-default: map.get(
          $rds-badge-themes,
          '#{$color-variant}-default'
        );
        $colors-for-minimal: map.get(
          $rds-badge-themes,
          '#{$color-variant}-minimal'
        );
        $colors-for-dot: map.get($rds-badge-themes, '#{$color-variant}-dot');

        @if $colors-for-default {
          &.rds-badge--#{$color-variant}-default {
            background-color: map.get($colors-for-default, background-color);
            color: map.get($colors-for-default, color);
          }
        }

        @if $colors-for-minimal {
          &.rds-badge--#{$color-variant}-minimal {
            background-color: map.get($colors-for-minimal, background-color);
            color: map.get($colors-for-minimal, color);
          }
        }

        @if $colors-for-dot {
          &.rds-badge--#{$color-variant}-dot {
            background-color: map.get($colors-for-dot, background-color);
            @include dot();
          }
        }
      }

      &--content {
        display: inline-flex;
        align-items: center;
        height: $rds-badge-height;
        color: $rds-badge-text-dot;
        padding-left: f.rds-spacing(3);
        @include m.rds-typography(ui-label-s-bold);
      }

      &--single-char {
        display: flex;
        justify-content: center;
        --padding: var(--single-char-padding);
      }

      &--s,
      &--attached {
        height: $rds-badge-s-height;
        padding: $rds-badge-s-padding;
      }

      &--s {
        @include m.rds-typography(map.get($rds-badge-sizes, 's'));
      }

      &--l {
        line-height: unset;
        --single-char-padding: #{$rds-badge-l-single-char-padding};
        --multiple-char-padding: #{$rds-badge-l-multiple-char-padding};
        height: $rds-badge-l-height;
        @include m.rds-typography(map.get($rds-badge-sizes, 'l'));
      }
    }
  }
}

@use '../variables/backgrounds';

@mixin rds-background-classes() {
  @keyframes brand-gradient-animation {
    0% {
      background-color: backgrounds.$brand-gradient-animation-0;
    }
    33% {
      background-color: backgrounds.$brand-gradient-animation-33;
    }
    66% {
      background-color: backgrounds.$brand-gradient-animation-66;
    }
    100% {
      background-color: backgrounds.$brand-gradient-animation-100;
    }
  }

  @each $key, $value in backgrounds.$rds-backgrounds {
    .bg-#{$key} {
      background-image: $value !important;

      @if $key == 'brand-gradient' {
        animation: brand-gradient-animation
          backgrounds.$brand-gradient-animation-time
          linear
          infinite;
      }
    }
  }
}

@use './checkbox-variables' as cv;

$rds-pseudo-checkbox__checkmark-path-length: 22.910259;

$rds-pseudo-checkbox-height: cv.$rds-checkbox-base__height !default;

$rds-pseudo-checkbox__checkbox-container-height: cv.$rds-checkbox-base__checkbox-container-height !default;
$rds-pseudo-checkbox__checkbox-container-width: cv.$rds-checkbox-base__checkbox-container-width !default;
$rds-pseudo-checkbox__checkbox-container-margin-right: cv.$rds-checkbox-base__checkbox-container-margin-right !default;

$rds-pseudo-checkbox__frame-border: cv.$rds-checkbox-base__frame-border !default;
$rds-pseudo-checkbox__frame-border-color-disabled: cv.$rds-checkbox-base__frame-border-color-disabled !default;
$rds-pseudo-checkbox__frame-border-radius: cv.$rds-checkbox-base__frame-border-radius !default;

$rds-pseudo-checkbox__background-border-radius: cv.$rds-checkbox-base__background-border-radius !default;
$rds-pseudo-checkbox__background-background: cv.$rds-checkbox-base__background-background !default;
$rd-pseudos-checkbox__background-background-disabled: cv.$rds-checkbox-base__background-background-disabled !default;

$rds-pseudo-checkbox__checkmark: cv.$rds-checkbox-base__checkmark !default;

$rds-pseudo-checkbox__indeterminate-mark-width: cv.$rds-checkbox-base__indeterminate-mark-width !default;
$rds-pseudo-checkbox__indeterminate-mark-height: cv.$rds-checkbox-base__indeterminate-mark-height !default;
$rds-pseudo-checkbox__indeterminate-mark-background: cv.$rds-checkbox-base__indeterminate-mark-background !default;

@mixin rds-pseudo-checkbox() {
  .rds-pseudo-checkbox {
    align-items: center;
    display: inline-flex;
    height: $rds-pseudo-checkbox-height;
    margin: 4px 0;
    transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

    .rds-pseudo-checkbox__container {
      cursor: inherit;
      display: inline-flex;
      user-select: none;
      white-space: nowrap;
    }

    .rds-pseudo-checkbox__checkbox-container {
      position: relative;
      display: inline-block;
      margin: auto $rds-pseudo-checkbox__checkbox-container-margin-right auto
        auto;
      height: $rds-pseudo-checkbox__checkbox-container-height;
      width: $rds-pseudo-checkbox__checkbox-container-width;
    }

    &.rds-pseudo-checkbox--no-spacing {
      height: unset;
      margin: 0;

      .rds-pseudo-checkbox__checkbox-container {
        margin-right: auto;
      }
    }

    .rds-pseudo-checkbox__frame {
      background: transparent;
      border: $rds-pseudo-checkbox__frame-border;
      border-radius: $rds-pseudo-checkbox__frame-border-radius;
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    .rds-pseudo-checkbox__background {
      align-items: center;
      background: $rds-pseudo-checkbox__background-background;
      border-radius: $rds-pseudo-checkbox__background-border-radius;
      bottom: 0;
      display: inline-flex;
      justify-content: center;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: opacity 90ms cubic-bezier(0, 0, 0.2, 0.1);
    }

    .rds-pseudo-checkbox__checkmark {
      bottom: 0;
      fill: $rds-pseudo-checkbox__checkmark;
      stroke: $rds-pseudo-checkbox__checkmark;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }

    .rds-pseudo-checkbox__checkmark-path {
      stroke-dasharray: $rds-pseudo-checkbox__checkmark-path-length;
      stroke-dashoffset: 0;
      stroke-width: 2.1333333333px;
    }

    .rds-pseudo-checkbox__indeterminate-mark {
      opacity: 0;
      transform: scaleX(0) rotate(0deg) translateY(0px);
      width: $rds-pseudo-checkbox__indeterminate-mark-width;
      height: $rds-pseudo-checkbox__indeterminate-mark-height;
      background: $rds-pseudo-checkbox__indeterminate-mark-background;
    }

    &.rds-pseudo-checkbox--checked {
      .rds-pseudo-checkbox__checkmark {
        opacity: 1;
      }

      .rds-pseudo-checkbox__checkmark-path {
        stroke-dashoffset: 0;
      }

      .rds-pseudo-checkbox__background {
        opacity: 1;
      }

      .rds-pseudo-checkbox__indeterminate-mark {
        transform: scaleX(1) rotate(-45deg);
      }
    }

    &.rds-pseudo-checkbox--indeterminate:not(.rds-pseudo-checkbox--checked) {
      .rds-pseudo-checkbox__checkmark {
        opacity: 0;
        transform: rotate(45deg);
      }

      .rds-pseudo-checkbox__checkmark-path {
        stroke-dashoffset: 0;
      }

      .rds-pseudo-checkbox__indeterminate-mark {
        opacity: 1;
        transform: scaleX(1) rotate(0deg) translateY(0px);
      }

      .rds-pseudo-checkbox__background {
        opacity: 1;
      }
    }

    &.rds-pseudo-checkbox--disabled {
      .rds-pseudo-checkbox__frame {
        border-color: $rds-pseudo-checkbox__frame-border-color-disabled;
      }

      .rds-pseudo-checkbox__background {
        background: $rd-pseudos-checkbox__background-background-disabled;
      }
    }
  }
}

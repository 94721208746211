@mixin rds-accessibility-base {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

@mixin rds-sr-only {
  .sr-only {
    @include rds-accessibility-base;
  }
}

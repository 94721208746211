@use './icon-variables';

$rds-icon-height: icon-variables.$rds-icon-size-l !default;
$rds-icon-width: icon-variables.$rds-icon-size-l !default;

@mixin rds-icon() {
  .rds-icon {
    display: inline-block;
    height: $rds-icon-height;
    width: $rds-icon-width;
    fill: currentColor;
    vertical-align: baseline;
  }
}

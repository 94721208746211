$rds-breakpoint-s: 0px;
$rds-breakpoint-m: 768px;
$rds-breakpoint-l: 1024px;
$rds-breakpoint-xl: 1440px;
$rds-breakpoint-xxl: 1672px;

$rds-breakpoints: (
  s: $rds-breakpoint-s,
  m: $rds-breakpoint-m,
  l: $rds-breakpoint-l,
  xl: $rds-breakpoint-xl,
  xxl: $rds-breakpoint-xxl,
) !default;

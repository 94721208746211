@use '../abstracts/functions' as f;
@use '../abstracts/mixins' as m;

$rds-accordion-content-spacing: f.rds-spacing(6) !default;
$rds-accordion-line-height: 1px !default;
$rds-accordion-line-style: solid !default;
$rds-accordion-line-color: f.rds-color(grey-2) !default;
$rds-accordion-line-hover-height: 2px !default;
$rds-accordion-line-hover-color: f.rds-color(informative-hover) !default;
$rds-accordion-focused-color: f.rds-color(stroke-active-focus) !default;
$rds-accordion-focused: solid $rds-accordion-focused-color !default;
$rds-accordion-color: f.rds-color(low-contrast) !default;
$rds-accordion-hover-color: f.rds-color(informative-hover) !default;
$rds-accordion-disabled-color: f.rds-color(disabled) !default;
$rds-accordion-icon-color: f.rds-color(low-contrast) !default;
$rds-accordion-icon-hover-color: $rds-accordion-hover-color !default;
$rds-accordion-icon-spacing: f.rds-spacing(4) !default;
$rds-accordion-line-height: 1px !default;
$rds-accordion-line-hover-height: 2px !default;
$rds-accordion-title-spacing: f.rds-spacing(5) !default;

@mixin rds-accordion() {
  .rds-accordion {
    width: 100%;

    .rds-expansion-panel {
      box-sizing: content-box;
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;
      padding-bottom: 0;
      border-radius: 0;
      border-bottom: $rds-accordion-line-height $rds-accordion-line-style
        $rds-accordion-line-color;

      &:focus {
        outline: 0;
      }

      &:hover:not(.rds-disabled) {
        border-bottom: $rds-accordion-line-hover-height
          $rds-accordion-line-style $rds-accordion-line-hover-color;

        .rds-expansion-panel-header {
          padding: $rds-accordion-title-spacing 0
            ($rds-accordion-title-spacing - $rds-accordion-line-hover-height) 0;
        }
      }

      &.rds-expanded:not(.rds-disabled) {
        padding-bottom: $rds-accordion-content-spacing -
          $rds-accordion-line-height;

        &:hover {
          padding-bottom: $rds-accordion-content-spacing -
            $rds-accordion-line-hover-height;
          border-bottom: $rds-accordion-line-hover-height
            $rds-accordion-line-style $rds-accordion-line-hover-color;
        }

        .rds-expansion-panel-header {
          padding: $rds-accordion-title-spacing 0 $rds-accordion-title-spacing 0;

          &:hover {
            padding: $rds-accordion-title-spacing 0 $rds-accordion-title-spacing
              0;
          }
        }

        svg.rds-expansion-indicator {
          transform: rotate(180deg);
        }
      }

      &.cdk-focused.cdk-keyboard-focused:not(.rds-disabled) {
        outline: 2px $rds-accordion-focused;
      }

      &.rds-disabled {
        border-bottom-color: $rds-accordion-line-color;

        .rds-expansion-panel-content {
          color: $rds-accordion-disabled-color;
        }

        &.rds-expanded {
          padding-bottom: $rds-accordion-content-spacing -
            $rds-accordion-line-height;

          .rds-expansion-panel-header {
            padding: $rds-accordion-title-spacing 0 $rds-accordion-title-spacing
              0;
          }
        }

        &:hover {
          border-bottom: $rds-accordion-line-height $rds-accordion-line-style
            $rds-accordion-line-color;
        }
      }

      .rds-expansion-panel-header {
        color: $rds-accordion-color;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: $rds-accordion-title-spacing 0
          ($rds-accordion-title-spacing - $rds-accordion-line-height) 0;
        border: none;
        cursor: pointer;

        @include m.disabled-outline();
        @include m.rds-typography(ui-heading-4);

        .rds-expansion-indicator {
          color: $rds-accordion-icon-color;
        }

        svg.rds-expansion-indicator {
          transition: all 0.3s ease-in-out;
          transform: rotate(0deg);
          transform-origin: center;
        }

        &:hover {
          color: $rds-accordion-hover-color;

          .rds-expansion-indicator {
            color: $rds-accordion-icon-hover-color;
          }
        }

        &.rds-expansion-toggle-indicator-before {
          flex-direction: row-reverse;

          .rds-expansion-indicator {
            margin: 0 $rds-accordion-icon-spacing 0 0;

            [dir='rtl'] & {
              margin: 0 0 0 $rds-accordion-icon-spacing;
            }
          }
        }

        &[aria-disabled='true'] {
          color: $rds-accordion-disabled-color;
          cursor: default;

          .rds-expansion-indicator {
            color: $rds-accordion-disabled-color;
          }
        }

        .rds-content {
          display: flex;
          flex: 1;
          flex-direction: row;
          overflow: hidden;
        }

        .rds-expansion-panel-header-title {
          display: flex;
          flex-grow: 1;
          margin-right: 16px;
          align-items: center;

          [dir='rtl'] & {
            margin-right: 0;
            margin-left: 16px;
          }

          & > * {
            font-size: inherit;
            font-family: inherit;
            font-weight: inherit;
            line-height: inherit;
          }
        }
      }

      .rds-expansion-panel-content {
        display: flex;
        flex-direction: column;
        overflow: visible;
      }
    }

    &.rds-accordion-size-s {
      .rds-expansion-panel {
        .rds-expansion-panel-header {
          @include m.rds-typography(ui-label-m-bold);
        }
      }
    }
  }
}

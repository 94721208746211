@use '../../core/styles/core' as core;
@use './components/list';
@use './components/dialog';
@use './components/toast';

@mixin rds-angular-components() {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  @include core.rds-core();
  @include list.list();
  @include dialog.dialog();
  @include toast.toast();
}

@use '../../../core/styles/abstracts/functions' as f;
@use '../../../core/styles/abstracts/mixins' as m;
@use '@angular/cdk/overlay' as o;

$rds-dialog-margin: f.rds-layout(5) !default;
$rds-dialog-margin-mobile: f.rds-layout(1) !default;

@mixin dialog() {
  @include o.overlay();

  .rds-dialog-overlay {
    max-width: 80vw;
    margin: $rds-dialog-margin;

    @include m.rds-media-s() {
      margin: $rds-dialog-margin-mobile;
    }
  }

  @include m.rds-media-s() {
    .rds-dialog-overlay {
      max-width: calc(100% - #{f.rds-spacing(5) * 2});
    }
  }
}

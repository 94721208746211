@use 'sass:map';
@use '../functions/utils';
@use '../variables/breakpoints';

@mixin rds-media($breakpoint) {
  $min-width: map.get(breakpoints.$rds-breakpoints, $breakpoint);

  @if $min-width == 0px or $min-width == 0 {
    $max-width: utils.get-next-map-value(
      breakpoints.$rds-breakpoints,
      $breakpoint
    );

    @media (max-width: $max-width) {
      @content;
    }
  } @else {
    @media (min-width: $min-width) {
      @content;
    }
  }
}

@mixin rds-media-s {
  @include rds-media(s) {
    @content;
  }
}

@mixin rds-media-m {
  @include rds-media(m) {
    @content;
  }
}

@mixin rds-media-l {
  @include rds-media(l) {
    @content;
  }
}

@mixin rds-media-xl {
  @include rds-media(xl) {
    @content;
  }
}

@mixin rds-media-xxl {
  @include rds-media(xxl) {
    @content;
  }
}

@use 'sass:map';
@use '../abstracts/mixins' as m;
@use '../abstracts/functions' as f;
@use './form-field-variables' as v;
@use './icon-variables' as iv;

$rds-form-field__select-arrow-size: 5px !default;
$rds-form-field__select-arrow-margin: 7px !default;
$rds-form-field__select-arrow-top: calc(
  50% - $rds-form-field__select-arrow-size * 2
) !default;
$rds-form-field__select-option-height: f.rds-spacing(10) !default;
$rds-form-field__select-x-wrapper-floating-margin: f.rds-spacing(5) !default;

$rds-form-field__select-panel-border-radius: f.rds-spacing(1) !default;
$rds-form-field__select-panel-height: 256px !default;
$rds-form-field__select-panel-padding-top: f.rds-spacing(4) !default;
$rds-form-field__select-panel-padding-bottom: f.rds-spacing(4) !default;
$rds-form-field__select-panel-background: f.rds-color(bg-base-1) !default;

$rds-form-field__select-search-color: f.rds-color(high-contrast) !default;
$rds-form-field__select-search-color-active: f.rds-color(
  stroke-active-focus
) !default;
$rds-form-field__select-search-color-placeholder: f.rds-color(grey-3) !default;
$rds-form-field__select-option-separator: f.rds-color(grey-2) !default;

$rds-form-field__select-hidden-trigger-values-placeholder-margin-right: f.rds-spacing(
  4
) !default;
$rds-form-field__select-hidden-trigger-values-placeholder-margin-left: f.rds-spacing(
  2
) !default;
$rds-form-field__select-hidden-trigger-values-placeholder-color: f.rds-color(
  low-contrast
) !default;

$rds-form-field__select-hidden-trigger-values-border-radius: 10px !default;
$rds-form-field__select-hidden-trigger-values-height: f.rds-spacing(6) !default;
$rds-form-field__select-hidden-trigger-values-padding: f.rds-spacing(1)
  f.rds-spacing(4) !default;
$rds-form-field__control-container-min-width: 110px !default;

$rds-select-sizes: (
  l: (
    padding-block: f.rds-spacing(3) + f.rds-spacing(1),
  ),
  m: (
    padding-block: f.rds-spacing(2),
  ),
  s: (
    padding-block: f.rds-spacing(2),
  ),
) !default;

$rds-form-field__select-search-color-placeholder: f.rds-color(
  low-contrast
) !default;
$rds-search-icon-color: f.rds-color(low-contrast) !default;
$rds-select-search-input-margin: f.rds-spacing(3) f.rds-spacing(5) !default;

@mixin rds-select-control() {
  $font-line-height: f.rds-spacing(7);

  &.rds-form-field--select {
    .rds-form-field__control-container {
      cursor: pointer;
    }
  }

  .rds-form-field__control {
    align-items: center;
  }

  .rds-form-field__select-value {
    justify-content: space-between;
    align-items: center;
    display: flex;
    min-width: 0;
    width: calc(100% - #{$font-line-height});
  }

  .rds-form-field__select-value-placeholder {
    display: flex;
    flex-wrap: nowrap;
    max-height: $font-line-height;
    overflow: hidden;
  }

  .rds-form-field__select-arrow-wrapper {
    position: absolute;
    right: 0;
    display: flex;
  }

  .rds-form-field__select-arrow {
    margin: 0 $rds-form-field__select-arrow-margin;
    border-top: $rds-form-field__select-arrow-size solid;
    border-left: $rds-form-field__select-arrow-size solid transparent;
    border-right: $rds-form-field__select-arrow-size solid transparent;
    width: 0;
    height: 0;

    &--rotate {
      transform: rotate(180deg);
    }
  }
}

@mixin rds-select-option-panel() {
  .rds-select__panel-wrapper {
    flex-basis: 100%;
  }

  .rds-select__panel {
    overflow: auto;
    border-radius: $rds-form-field__select-panel-border-radius;
    min-width: 100%;
    max-height: $rds-form-field__select-panel-height;
    padding-top: $rds-form-field__select-panel-padding-top;
    padding-bottom: $rds-form-field__select-panel-padding-bottom;
    background: $rds-form-field__select-panel-background;
    @include m.rds-elevation(2);
  }

  .rds-select__option {
    &.hide {
      display: none;
    }
  }

  .rds-select__option--separator {
    padding: 0;
    margin: 0;

    div {
      width: 100%;
      height: 1px;
      border-bottom: 1px solid $rds-form-field__select-option-separator;
      padding-bottom: 0;
      margin-bottom: f.rds-spacing(2);
    }
  }
}

@mixin rds-select-base-styles() {
  .rds-form-field__select-x-wrapper {
    display: flex;
    margin-right: f.rds-spacing(2);
    margin-left: f.rds-spacing(3);
    cursor: pointer;
  }

  rds-multi-select {
    .rds-chips {
      &.rds-chips--size-s {
        gap: f.rds-spacing(2);
      }
    }
  }

  .rds-form-field__select-trigger-value {
    display: inline-block;
    white-space: nowrap;

    &.rds-form-field__select-trigger-value-placeholder {
      color: v.$rds-form-field__control-color--placeholder;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .rds-form-field__select-hidden-trigger-values-placeholder {
    display: inline-flex;
    margin-right: $rds-form-field__select-hidden-trigger-values-placeholder-margin-right;
    margin-left: $rds-form-field__select-hidden-trigger-values-placeholder-margin-left;
    white-space: nowrap;
    color: $rds-form-field__select-hidden-trigger-values-placeholder-color;
  }

  .rds-form-field--floating-label {
    .rds-form-field__control {
      align-items: unset;
    }

    .rds-form-field__select-arrow-wrapper {
      position: absolute;
      top: $rds-form-field__select-arrow-top;
      right: 0;
      display: flex;
    }

    .rds-form-field__select-x-wrapper {
      button {
        margin-bottom: $rds-form-field__select-x-wrapper-floating-margin;
      }
    }
  }
}

@mixin rds-select-search-input() {
  .rds-select__option--search-input {
    display: flex;
    flex-direction: row;
    border-radius: v.$rds-form-field__control-container-border-radius;
    border: v.$rds-form-field__control-container-border;
    align-items: center;
    margin: $rds-select-search-input-margin;

    @each $size, $form-field in v.$rds-form-field-sizes {
      &--#{$size} {
        height: map.get($form-field, container-height);
        padding: map.get($form-field, input-padding-top)
          map.get($form-field, input-padding-right)
          map.get($form-field, input-padding-bottom)
          map.get($form-field, input-padding-left);

        rds-icon {
          height: map.get($form-field, icon-size);
          width: map.get($form-field, icon-size);
          color: $rds-search-icon-color;
        }

        input {
          width: calc(
            100% - #{map.get($form-field, icon-size) - iv.$rds-icon-size-s}
          );
          border: none;
          box-shadow: none;
          background: transparent;
          color: v.$rds-form-field__control-color;
          caret-color: v.$rds-form-field__control-caret-color;
          @include m.disabled-outline();
          @include m.rds-typography(ui-label-#{$size});

          ::placeholder {
            color: $rds-form-field__select-search-color-placeholder;
          }
        }
      }

      .rds-select__option--search-input-prefix {
        margin-right: 8px;
      }

      .rds-select__option--search-input-suffix {
        padding-right: map.get($form-field, textarea-padding-suffix);

        .rds-icon {
          margin-top: map.get($form-field, icon-padding);
        }

        &.hidden {
          visibility: hidden;
        }
      }
    }

    &:hover {
      border-color: v.$rds-form-field__control-container-border-color--hover;
    }
  }
}

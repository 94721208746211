@use 'sass:map';
@use 'sass:meta';
@use 'sass:list';
@use '../variables/utilities';
@use '../variables/breakpoints';
@use '../mixins/media-queries';

@mixin rds-utilities() {
  @each $key, $utility in utilities.$rds-utilities {
    $values: map.get($utility, values);

    @if meta.type-of($values) ==
      'string' or
      meta.type-of(list.nth($values, 1)) !=
      'list'
    {
      $values: list.zip($values, $values);
    }

    @each $key, $value in $values {
      $properties: map.get($utility, property);

      @if meta.type-of($properties) == 'string' {
        $properties: list.append((), $properties);
      }

      $property-class: if(
        map.has-key($utility, class),
        map.get($utility, class),
        list.nth($properties, 1)
      );
      $property-class: if($property-class == null, '', $property-class);

      @each $property in $properties {
        @if $property-class == '' {
          .#{$key} {
            #{$property}: #{$value} !important;
          }
        } @else {
          .#{$property-class}-#{$key} {
            #{$property}: #{$value} !important;
          }
        }
      }
    }

    @each $key, $value in $values {
      $properties: map.get($utility, property);

      @if meta.type-of($properties) == 'string' {
        $properties: list.append((), $properties);
      }

      $property-class: if(
        map.has-key($utility, class),
        map.get($utility, class),
        list.nth($properties, 1)
      );
      $property-class: if($property-class == null, '', $property-class);

      $responsive: map.get($utility, responsive);

      @if $responsive {
        @each $breakpoint-name,
          $breakpoint-value in breakpoints.$rds-breakpoints
        {
          @include media-queries.rds-media($breakpoint-name) {
            @each $property in $properties {
              .#{$property-class}-#{$breakpoint-name}-#{$key} {
                #{$property}: #{$value} !important;
              }
            }
          }
        }
      }
    }
  }
}

$rds-typography-extensions: (
  ttf: 'truetype',
  woff: 'woff',
  woff2: 'woff2',
) !default;
$rds-typography: (
  RocheSans-Bold,
  RocheSans-BoldItalic,
  RocheSans-Italic,
  RocheSans-Light,
  RocheSans-LightItalic,
  RocheSans-Medium,
  RocheSans-MediumItalic,
  RocheSans-Regular,
  RocheSansCondensed-Italic,
  RocheSansCondensed-Light,
  RocheSansCondensed-LightItalic,
  RocheSansCondensed-Regular,
  RocheSerif-Bold,
  RocheSerif-BoldItalic,
  RocheSerif-Italic,
  RocheSerif-Light,
  RocheSerif-LightItalic,
  RocheSerif-Regular
) !default;
$rds-roche-fonts: (
  roche-sans: (
    (
      font-family: RocheSans-Light,
      font-style: normal,
      font-weight: 200,
    ),
    (
      font-family: RocheSans-Regular,
      font-style: normal,
      font-weight: 400,
    ),
    (
      font-family: RocheSans-Medium,
      font-style: normal,
      font-weight: 600,
    ),
    (
      font-family: RocheSans-Bold,
      font-style: normal,
      font-weight: 800,
    ),
    (
      font-family: RocheSans-LightItalic,
      font-style: italic,
      font-weight: 200,
    ),
    (
      font-family: RocheSans-Italic,
      font-style: italic,
      font-weight: 400,
    ),
    (
      font-family: RocheSans-MediumItalic,
      font-style: italic,
      font-weight: 600,
    ),
    (
      font-family: RocheSans-BoldItalic,
      font-style: italic,
      font-weight: 800,
    ),
  ),
  roche-sans-condensed: (
    (
      font-family: RocheSansCondensed-Light,
      font-style: normal,
      font-weight: 200,
    ),
    (
      font-family: RocheSansCondensed-Regular,
      font-style: normal,
      font-weight: 400,
    ),
    (
      font-family: RocheSansCondensed-LightItalic,
      font-style: italic,
      font-weight: 200,
    ),
    (
      font-family: RocheSansCondensed-Italic,
      font-style: italic,
      font-weight: 400,
    ),
  ),
  roche-serif: (
    (
      font-family: RocheSerif-Light,
      font-style: normal,
      font-weight: 200,
    ),
    (
      font-family: RocheSerif-Regular,
      font-style: normal,
      font-weight: 400,
    ),
    (
      font-family: RocheSerif-Bold,
      font-style: normal,
      font-weight: 800,
    ),
    (
      font-family: RocheSerif-LightItalic,
      font-style: italic,
      font-weight: 200,
    ),
    (
      font-family: RocheSerif-Italic,
      font-style: italic,
      font-weight: 400,
    ),
    (
      font-family: RocheSerif-BoldItalic,
      font-style: italic,
      font-weight: 800,
    ),
  ),
) !default;

$rds-typography-default-font: roche-sans !default;
$rds-typography-fallback-fonts: Roboto, 'Helvetica Neue', sans-serif !default;
$rds-typography-font-family-2:
  #{$rds-typography-default-font},
  #{$rds-typography-fallback-fonts} !default;
$rds-typography-font-family: #{$rds-typography-font-family-2} !default;

$rds-typography-names: // ARTICLE
  article-display-l,
  article-display-m,
  article-heading-1,
  article-heading-1-bold,
  article-heading-2,
  article-heading-2-bold,
  article-heading-3,
  article-heading-3-bold,
  article-heading-4,
  article-heading-4-bold,
  article-heading-5,
  article-heading-5-bold,
  article-heading-6,
  article-heading-6-bold,
  article-quote-m,
  article-quote-s,
  article-kpi,
  article-lead,
  article-body-m,
  article-body-m-bold,
  article-body-s,
  article-body-s-bold,
  article-caption,
  article-footnote,
  // UI
  ui-heading-1,
  ui-heading-1-bold,
  ui-heading-2,
  ui-heading-2-bold,
  ui-heading-3,
  ui-heading-3-bold,
  ui-heading-4,
  ui-heading-4-bold,
  ui-quote,
  ui-body-l,
  ui-body-l-bold,
  ui-body-m,
  ui-body-m-bold,
  ui-body-s,
  ui-body-s-bold,
  ui-label-l-bold,
  ui-label-l,
  ui-label-m-bold,
  ui-label-m,
  ui-label-s-bold,
  ui-label-s,
  ui-overline-s,
  ui-overline-m,
  ui-component-text-m,
  ui-component-text-s !default;

$rds-typography-list: (
  article-display-l--xl-xxl: (
    name: article-display-l,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 128px,
    line-height: 144px,
    font-weight: 200,
    font-style: normal,
  ),
  article-display-l--m-l: (
    name: article-display-l,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 88px,
    line-height: 100px,
    font-weight: 200,
    font-style: normal,
  ),
  article-display-l--s: (
    name: article-display-l,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 60px,
    line-height: 72px,
    font-weight: 200,
    font-style: normal,
  ),
  article-display-m--xl-xxl: (
    name: article-display-m,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 88px,
    line-height: 100px,
    font-weight: 200,
    font-style: normal,
  ),
  article-display-m--m-l: (
    name: article-display-m,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 60px,
    line-height: 72px,
    font-weight: 200,
    font-style: normal,
  ),
  article-display-m--s: (
    name: article-display-m,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 48px,
    line-height: 56px,
    font-weight: 200,
    font-style: normal,
  ),
  article-heading-1--xl-xxl: (
    name: article-heading-1,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 60px,
    line-height: 64px,
    font-weight: 200,
    font-style: normal,
  ),
  article-heading-1--m-l: (
    name: article-heading-1,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 48px,
    line-height: 56px,
    font-weight: 200,
    font-style: normal,
  ),
  article-heading-1--s: (
    name: article-heading-1,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 40px,
    line-height: 48px,
    font-weight: 200,
    font-style: normal,
  ),
  article-heading-1-bold--xl-xxl: (
    name: article-heading-1-bold,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 60px,
    line-height: 64px,
    font-weight: 400,
    font-style: normal,
  ),
  article-heading-1-bold--m-l: (
    name: article-heading-1-bold,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 48px,
    line-height: 56px,
    font-weight: 400,
    font-style: normal,
  ),
  article-heading-1-bold--s: (
    name: article-heading-1-bold,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 40px,
    line-height: 48px,
    font-weight: 400,
    font-style: normal,
  ),
  article-heading-2--xl-xxl: (
    name: article-heading-2,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 48px,
    line-height: 56px,
    font-weight: 200,
    font-style: normal,
  ),
  article-heading-2--m-l: (
    name: article-heading-2,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 40px,
    line-height: 48px,
    font-weight: 200,
    font-style: normal,
  ),
  article-heading-2--s: (
    name: article-heading-2,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 30px,
    line-height: 40px,
    font-weight: 200,
    font-style: normal,
  ),
  article-heading-2-bold--xl-xxl: (
    name: article-heading-2-bold,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 48px,
    line-height: 56px,
    font-weight: 400,
    font-style: normal,
  ),
  article-heading-2-bold--m-l: (
    name: article-heading-2-bold,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 40px,
    line-height: 48px,
    font-weight: 400,
    font-style: normal,
  ),
  article-heading-2-bold--s: (
    name: article-heading-2-bold,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 30px,
    line-height: 40px,
    font-weight: 400,
    font-style: normal,
  ),
  article-heading-3--xl-xxl: (
    name: article-heading-3,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 40px,
    line-height: 48px,
    font-weight: 200,
    font-style: normal,
  ),
  article-heading-3--m-l: (
    name: article-heading-3,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 30px,
    line-height: 40px,
    font-weight: 200,
    font-style: normal,
  ),
  article-heading-3--s: (
    name: article-heading-3,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 28px,
    line-height: 36px,
    font-weight: 200,
    font-style: normal,
  ),
  article-heading-3-bold--xl-xxl: (
    name: article-heading-3-bold,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 40px,
    line-height: 48px,
    font-weight: 400,
    font-style: normal,
  ),
  article-heading-3-bold--m-l: (
    name: article-heading-3-bold,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 30px,
    line-height: 40px,
    font-weight: 400,
    font-style: normal,
  ),
  article-heading-3-bold--s: (
    name: article-heading-3-bold,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 28px,
    line-height: 36px,
    font-weight: 400,
    font-style: normal,
  ),
  article-heading-4--xl-xxl: (
    name: article-heading-4,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 30px,
    line-height: 40px,
    font-weight: 400,
    font-style: normal,
  ),
  article-heading-4--m-l: (
    name: article-heading-4,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 24px,
    line-height: 32px,
    font-weight: 400,
    font-style: normal,
  ),
  article-heading-4--s: (
    name: article-heading-4,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 24px,
    line-height: 32px,
    font-weight: 400,
    font-style: normal,
  ),
  article-heading-4-bold--xl-xxl: (
    name: article-heading-4-bold,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 30px,
    line-height: 40px,
    font-weight: 600,
    font-style: normal,
  ),
  article-heading-4-bold--m-l: (
    name: article-heading-4-bold,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 24px,
    line-height: 32px,
    font-weight: 600,
    font-style: normal,
  ),
  article-heading-4-bold--s: (
    name: article-heading-4-bold,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 24px,
    line-height: 32px,
    font-weight: 600,
    font-style: normal,
  ),
  article-heading-5--xl-xxl: (
    name: article-heading-5,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 24px,
    line-height: 32px,
    font-weight: 400,
    font-style: normal,
  ),
  article-heading-5--m-l: (
    name: article-heading-5,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 20px,
    line-height: 28px,
    font-weight: 400,
    font-style: normal,
  ),
  article-heading-5--s: (
    name: article-heading-5,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 20px,
    line-height: 28px,
    font-weight: 400,
    font-style: normal,
  ),
  article-heading-5-bold--xl-xxl: (
    name: article-heading-5-bold,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 24px,
    line-height: 32px,
    font-weight: 600,
    font-style: normal,
  ),
  article-heading-5-bold--m-l: (
    name: article-heading-5-bold,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 20px,
    line-height: 28px,
    font-weight: 600,
    font-style: normal,
  ),
  article-heading-5-bold--s: (
    name: article-heading-5-bold,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 20px,
    line-height: 28px,
    font-weight: 600,
    font-style: normal,
  ),
  article-heading-6--xl-xxl: (
    name: article-heading-6,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 20px,
    line-height: 28px,
    font-weight: 400,
    font-style: normal,
  ),
  article-heading-6--m-l: (
    name: article-heading-6,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 18px,
    line-height: 24px,
    font-weight: 400,
    font-style: normal,
  ),
  article-heading-6--s: (
    name: article-heading-6,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 18px,
    line-height: 24px,
    font-weight: 400,
    font-style: normal,
  ),
  article-heading-6-bold--xl-xxl: (
    name: article-heading-6-bold,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 20px,
    line-height: 28px,
    font-weight: 600,
    font-style: normal,
  ),
  article-heading-6-bold--m-l: (
    name: article-heading-6-bold,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 18px,
    line-height: 24px,
    font-weight: 600,
    font-style: normal,
  ),
  article-heading-6-bold--s: (
    name: article-heading-6-bold,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 18px,
    line-height: 24px,
    font-weight: 600,
    font-style: normal,
  ),
  article-quote-m--xl-xxl: (
    name: article-quote-m,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-serif,
    font-size: 48px,
    line-height: 56px,
    font-weight: 200,
    font-style: normal,
  ),
  article-quote-m--m-l: (
    name: article-quote-m,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-serif,
    font-size: 40px,
    line-height: 48px,
    font-weight: 200,
    font-style: normal,
  ),
  article-quote-m--s: (
    name: article-quote-m,
    breakpoint: (
      s,
    ),
    font-family: roche-serif,
    font-size: 32px,
    line-height: 40px,
    font-weight: 200,
    font-style: normal,
  ),
  article-quote-s--xl-xxl: (
    name: article-quote-s,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-serif,
    font-size: 30px,
    line-height: 45px,
    font-weight: 200,
    font-style: normal,
  ),
  article-quote-s--m-l: (
    name: article-quote-s,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-serif,
    font-size: 26px,
    line-height: 40px,
    font-weight: 200,
    font-style: normal,
  ),
  article-quote-s--s: (
    name: article-quote-s,
    breakpoint: (
      s,
    ),
    font-family: roche-serif,
    font-size: 24px,
    line-height: 36px,
    font-weight: 200,
    font-style: normal,
  ),
  article-kpi: (
    name: article-kpi,
    font-family: roche-sans,
    font-size: 40px,
    line-height: 48px,
    font-weight: 200,
    font-style: normal,
  ),
  article-lead--xl-xxl: (
    name: article-lead,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 26px,
    line-height: 38px,
    font-weight: 200,
    font-style: normal,
  ),
  article-lead--m-l: (
    name: article-lead,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 24px,
    line-height: 36px,
    font-weight: 200,
    font-style: normal,
  ),
  article-lead--s: (
    name: article-lead,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 22px,
    line-height: 32px,
    font-weight: 200,
    font-style: normal,
  ),
  article-body-m--xl-xxl: (
    name: article-body-m,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 20px,
    line-height: 32px,
    font-weight: 200,
    font-style: normal,
  ),
  article-body-m--m-l: (
    name: article-body-m,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 18px,
    line-height: 28px,
    font-weight: 200,
    font-style: normal,
  ),
  article-body-m--s: (
    name: article-body-m,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 18px,
    line-height: 28px,
    font-weight: 200,
    font-style: normal,
  ),
  article-body-m-bold--xl-xxl: (
    name: article-body-m-bold,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 20px,
    line-height: 32px,
    font-weight: 400,
    font-style: normal,
  ),
  article-body-m-bold--m-l: (
    name: article-body-m-bold,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 18px,
    line-height: 28px,
    font-weight: 400,
    font-style: normal,
  ),
  article-body-m-bold--s: (
    name: article-body-m-bold,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 18px,
    line-height: 28px,
    font-weight: 400,
    font-style: normal,
  ),
  article-body-s--xl-xxl: (
    name: article-body-s,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 18px,
    line-height: 28px,
    font-weight: 300,
    font-style: normal,
  ),
  article-body-s--m-l: (
    name: article-body-s,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 16px,
    line-height: 28px,
    letter-spacing: 0.2px,
    font-weight: 300,
    font-style: normal,
  ),
  article-body-s--s: (
    name: article-body-s,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 16px,
    line-height: 28px,
    letter-spacing: 0.2px,
    font-weight: 300,
    font-style: normal,
  ),
  article-body-s-bold--xl-xxl: (
    name: article-body-s-bold,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 18px,
    line-height: 28px,
    font-weight: 400,
    font-style: normal,
  ),
  article-body-s-bold--m-l: (
    name: article-body-s-bold,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 16px,
    line-height: 28px,
    letter-spacing: 0.2px,
    font-weight: 400,
    font-style: normal,
  ),
  article-body-s-bold--s: (
    name: article-body-s-bold,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 16px,
    line-height: 28px,
    letter-spacing: 0.2px,
    font-weight: 400,
    font-style: normal,
  ),
  article-caption--xl-xxl: (
    name: article-caption,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 16px,
    line-height: 24px,
    letter-spacing: 0.2px,
    font-weight: 400,
    font-style: normal,
  ),
  article-caption--m-l: (
    name: article-caption,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 16px,
    line-height: 24px,
    letter-spacing: 0.2px,
    font-weight: 400,
    font-style: normal,
  ),
  article-caption--s: (
    name: article-caption,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 14px,
    line-height: 20px,
    letter-spacing: 0.3px,
    font-weight: 400,
    font-style: normal,
  ),
  article-footnote: (
    name: article-footnote,
    font-family: roche-sans,
    font-size: 14px,
    line-height: 20px,
    letter-spacing: 0.3px,
    font-weight: 400,
    font-style: normal,
  ),
  // UI
  ui-heading-1--xl-xxl:
    (
      name: ui-heading-1,
      breakpoint: (
        xl,
        xxl,
      ),
      font-family: roche-sans,
      font-size: 40px,
      line-height: 48px,
      font-weight: 200,
      font-style: normal,
    ),
  ui-heading-1--m-l: (
    name: ui-heading-1,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 30px,
    line-height: 40px,
    font-weight: 200,
    font-style: normal,
  ),
  ui-heading-1--s: (
    name: ui-heading-1,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 28px,
    line-height: 36px,
    font-weight: 200,
    font-style: normal,
  ),
  ui-heading-1-bold--xl-xxl: (
    name: ui-heading-1-bold,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 40px,
    line-height: 48px,
    font-weight: 400,
    font-style: normal,
  ),
  ui-heading-1-bold--m-l: (
    name: ui-heading-1-bold,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 30px,
    line-height: 40px,
    font-weight: 400,
    font-style: normal,
  ),
  ui-heading-1-bold--s: (
    name: ui-heading-1-bold,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 28px,
    line-height: 36px,
    font-weight: 400,
    font-style: normal,
  ),
  ui-heading-2--xl-xxl: (
    name: ui-heading-2,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 30px,
    line-height: 40px,
    font-weight: 200,
    font-style: normal,
  ),
  ui-heading-2--m-l: (
    name: ui-heading-2,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 24px,
    line-height: 32px,
    font-weight: 200,
    font-style: normal,
  ),
  ui-heading-2--s: (
    name: ui-heading-2,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 24px,
    line-height: 32px,
    font-weight: 200,
    font-style: normal,
  ),
  ui-heading-2-bold--xl-xxl: (
    name: ui-heading-2-bold,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 30px,
    line-height: 40px,
    font-weight: 400,
    font-style: normal,
  ),
  ui-heading-2-bold--m-l: (
    name: ui-heading-2-bold,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 24px,
    line-height: 32px,
    font-weight: 400,
    font-style: normal,
  ),
  ui-heading-2-bold--s: (
    name: ui-heading-2-bold,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 24px,
    line-height: 32px,
    font-weight: 400,
    font-style: normal,
  ),
  ui-heading-3--xl-xxl: (
    name: ui-heading-3,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 24px,
    line-height: 32px,
    font-weight: 400,
    font-style: normal,
  ),
  ui-heading-3--m-l: (
    name: ui-heading-3,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 20px,
    line-height: 28px,
    font-weight: 400,
    font-style: normal,
  ),
  ui-heading-3--s: (
    name: ui-heading-3,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 20px,
    line-height: 28px,
    font-weight: 400,
    font-style: normal,
  ),
  ui-heading-3-bold--xl-xxl: (
    name: ui-heading-3-bold,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 24px,
    line-height: 32px,
    font-weight: 600,
    font-style: normal,
  ),
  ui-heading-3-bold--m-l: (
    name: ui-heading-3-bold,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 20px,
    line-height: 28px,
    font-weight: 600,
    font-style: normal,
  ),
  ui-heading-3-bold--s: (
    name: ui-heading-3-bold,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 20px,
    line-height: 28px,
    font-weight: 600,
    font-style: normal,
  ),
  ui-heading-4--xl-xxl: (
    name: ui-heading-4,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 20px,
    line-height: 28px,
    font-weight: 400,
    font-style: normal,
  ),
  ui-heading-4--m-l: (
    name: ui-heading-4,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 18px,
    line-height: 24px,
    font-weight: 400,
    font-style: normal,
  ),
  ui-heading-4--s: (
    name: ui-heading-4,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 18px,
    line-height: 24px,
    font-weight: 400,
    font-style: normal,
  ),
  ui-heading-4-bold--xl-xxl: (
    name: ui-heading-4-bold,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 20px,
    line-height: 28px,
    font-weight: 600,
    font-style: normal,
  ),
  ui-heading-4-bold--m-l: (
    name: ui-heading-4-bold,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 18px,
    line-height: 24px,
    font-weight: 600,
    font-style: normal,
  ),
  ui-heading-4-bold--s: (
    name: ui-heading-4-bold,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 18px,
    line-height: 24px,
    font-weight: 600,
    font-style: normal,
  ),
  ui-quote--xl-xxl: (
    name: ui-quote,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-serif,
    font-size: 20px,
    line-height: 28px,
    font-weight: 400,
    font-style: normal,
  ),
  ui-quote--m-l: (
    name: ui-quote,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-serif,
    font-size: 18px,
    line-height: 28px,
    font-weight: 400,
    font-style: normal,
  ),
  ui-quote--s: (
    name: ui-quote,
    breakpoint: (
      s,
    ),
    font-family: roche-serif,
    font-size: 18px,
    line-height: 28px,
    font-weight: 400,
    font-style: normal,
  ),

  ui-body-l--xl-xxl: (
    name: ui-body-l,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 18px,
    line-height: 28px,
    font-weight: 200,
    font-style: normal,
  ),
  ui-body-l--m-l: (
    name: ui-body-l,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 18px,
    line-height: 28px,
    font-weight: 200,
    font-style: normal,
  ),
  ui-body-l--s: (
    name: ui-body-l,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 16px,
    line-height: 24px,
    font-weight: 200,
    font-style: normal,
    letter-spacing: 0.2px,
  ),
  ui-body-l-bold--xl-xxl: (
    name: ui-body-l-bold,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 18px,
    line-height: 28px,
    font-weight: 400,
    font-style: normal,
  ),
  ui-body-l-bold--m-l: (
    name: ui-body-l-bold,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 18px,
    line-height: 28px,
    font-weight: 400,
    font-style: normal,
  ),
  ui-body-l-bold--s: (
    name: ui-body-l-bold,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 16px,
    line-height: 24px,
    font-weight: 400,
    font-style: normal,
    letter-spacing: 0.2px,
  ),
  ui-body-m--xl-xxl: (
    name: ui-body-m,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 16px,
    line-height: 24px,
    letter-spacing: 0.2px,
    font-weight: 200,
    font-style: normal,
  ),
  ui-body-m--m-l: (
    name: ui-body-m,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 16px,
    line-height: 24px,
    letter-spacing: 0.2px,
    font-weight: 200,
    font-style: normal,
  ),
  ui-body-m--s: (
    name: ui-body-m,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 14px,
    line-height: 20px,
    letter-spacing: 0.2px,
    font-weight: 200,
    font-style: normal,
  ),
  ui-body-m-bold--xl-xxl: (
    name: ui-body-m-bold,
    breakpoint: (
      xl,
      xxl,
    ),
    font-family: roche-sans,
    font-size: 16px,
    line-height: 24px,
    letter-spacing: 0.2px,
    font-weight: 400,
    font-style: normal,
  ),
  ui-body-m-bold--m-l: (
    name: ui-body-m-bold,
    breakpoint: (
      m,
      l,
    ),
    font-family: roche-sans,
    font-size: 16px,
    line-height: 24px,
    letter-spacing: 0.2px,
    font-weight: 400,
    font-style: normal,
  ),
  ui-body-m-bold--s: (
    name: ui-body-m-bold,
    breakpoint: (
      s,
    ),
    font-family: roche-sans,
    font-size: 14px,
    line-height: 20px,
    letter-spacing: 0.2px,
    font-weight: 400,
    font-style: normal,
  ),
  ui-body-s: (
    name: ui-body-s,
    font-family: roche-sans,
    font-size: 14px,
    line-height: 24px,
    letter-spacing: 0.2px,
    font-weight: 200,
    font-style: normal,
  ),
  ui-body-s-bold: (
    name: ui-body-s-bold,
    font-family: roche-sans,
    font-size: 14px,
    line-height: 24px,
    letter-spacing: 0.2px,
    font-weight: 400,
    font-style: normal,
  ),

  ui-label-l-bold: (
    name: ui-label-l-bold,
    font-family: roche-sans,
    font-size: 18px,
    line-height: 24px,
    letter-spacing: 0.2px,
    font-weight: 400,
    font-style: normal,
  ),
  ui-label-l: (
    name: ui-label-l,
    font-family: roche-sans,
    font-size: 18px,
    line-height: 24px,
    letter-spacing: 0.2px,
    font-weight: 200,
    font-style: normal,
  ),
  ui-label-m-bold: (
    name: ui-label-m-bold,
    font-family: roche-sans,
    font-size: 16px,
    line-height: 24px,
    letter-spacing: 0.2px,
    font-weight: 400,
    font-style: normal,
  ),
  ui-label-m: (
    name: ui-label-m,
    font-family: roche-sans,
    font-size: 16px,
    line-height: 24px,
    letter-spacing: 0.2px,
    font-weight: 200,
    font-style: normal,
  ),
  ui-label-s: (
    name: ui-label-s,
    font-family: roche-sans,
    font-size: 14px,
    line-height: 20px,
    letter-spacing: 0.3px,
    font-weight: 200,
    font-style: normal,
  ),
  ui-label-s-bold: (
    name: ui-label-s-bold,
    font-family: roche-sans,
    font-size: 14px,
    line-height: 20px,
    letter-spacing: 0.3px,
    font-weight: 400,
    font-style: normal,
  ),
  ui-overline-m: (
    name: ui-overline-m,
    font-family: roche-sans,
    font-size: 14px,
    line-height: 20px,
    letter-spacing: 1px,
    font-weight: 400,
    font-style: normal,
    text-transform: uppercase,
  ),
  ui-overline-s: (
    name: ui-overline-s,
    font-family: roche-sans,
    font-size: 12px,
    line-height: 16px,
    letter-spacing: 1px,
    font-weight: 400,
    font-style: normal,
    text-transform: uppercase,
  ),
  ui-component-text-m: (
    name: ui-component-text-m,
    font-family: roche-sans,
    font-size: 14px,
    line-height: 20px,
    letter-spacing: 0.3px,
    font-weight: 400,
    font-style: normal,
  ),
  ui-component-text-s: (
    name: ui-component-text-s,
    font-family: roche-sans,
    font-size: 12px,
    line-height: 16px,
    letter-spacing: 0.5px,
    font-weight: 400,
    font-style: normal,
  ),
) !default;

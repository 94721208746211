@use '../abstracts/functions' as f;

$rds-scrollbar-size: f.rds-spacing(4) !default;
$rds-scrollbar-thumb-size: 0.5 * $rds-scrollbar-size !default;
$rds-scrollbar-thumb-color: f.rds-color(grey-1) !default;
$rds-scrollbar-thumb-hover-color: f.rds-color(grey-2) !default;
$rds-scrollbar-thumb-min-height: f.rds-spacing(12) !default;

@mixin scrollbar() {
  *::-webkit-scrollbar {
    width: $rds-scrollbar-size;
    height: $rds-scrollbar-size;
  }

  *::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 $rds-scrollbar-size $rds-scrollbar-thumb-color;
    border-width: #{($rds-scrollbar-size - $rds-scrollbar-thumb-size) * 0.5};
    border-style: solid;
    border-color: transparent;
    border-radius: 1000px;
    background-clip: padding-box;

    &:vertical {
      min-height: $rds-scrollbar-thumb-min-height;
    }

    &:horizontal {
      min-width: $rds-scrollbar-thumb-min-height;
    }

    &:hover {
      box-shadow: inset 0 0 0 $rds-scrollbar-size
        $rds-scrollbar-thumb-hover-color;
    }
  }

  ::-webkit-scrollbar-button,
  ::-webkit-scrollbar-corner {
    background: transparent;
    height: 0;
    width: 0;
  }
}

@use '../../abstracts/mixins' as m;
@use '../../abstracts/functions' as f;
@use './checkbox-variables' as cv;

$rds-linear-out-slow-taming-function: cubic-bezier(0, 0, 0.2, 0.1) !default;
$rds-fast-out-slow-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !default;
$rds-fast-out-linear-timing-function: cubic-bezier(0.4, 0, 1, 1) !default;
$rds-checkbox-transition-duration: 90ms;

$rds-checkbox-mark-path-length: 22.910259;
$rds-checkbox-indeterminate-checked-easing-function: cubic-bezier(
  0.14,
  0,
  0,
  1
);

$rds-swift-ease-out-duration: 400ms !default;
$rds-swift-ease-out-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1) !default;

$rds-checkbox-height: cv.$rds-checkbox-base__height !default;
$rds-checkbox-margin: f.rds-spacing(2) !default;

$rds-checkbox__checkbox-container-height: cv.$rds-checkbox-base__checkbox-container-height !default;
$rds-checkbox__checkbox-container-width: cv.$rds-checkbox-base__checkbox-container-width !default;
$rds-checkbox__checkbox-container-margin-right: cv.$rds-checkbox-base__checkbox-container-margin-right !default;

$rds-checkbox__overlay-border: f.rds-spacing(1) solid transparent !default;
$rds-checkbox__overlay-border-radius: f.rds-spacing(1) !default;
$rds-checkbox__overlay-border-color-focused: f.rds-color(
  stroke-active-focus
) !default;
$rds-checkbox__overlay-width: 36px !default;
$rds-checkbox__overlay-height: 36px !default;
$rds-checkbox__overlay-background: transparent !default;
$rds-checkbox__overlay-background-focused: transparent !default;
$rds-checkbox__overlay-background-hover: f.rds-color(
  bg-transparent-hover-select
) !default;
$rds-checkbox__overlay-background-active: f.rds-color(
  bg-transparent-pressed
) !default;

$rds-checkbox__frame-border: cv.$rds-checkbox-base__frame-border !default;
$rds-checkbox__frame-border-color-disabled: cv.$rds-checkbox-base__frame-border-color-disabled !default;
$rds-checkbox__frame-border-radius: cv.$rds-checkbox-base__frame-border-radius !default;

$rds-checkbox__background-border-radius: cv.$rds-checkbox-base__background-border-radius !default;
$rds-checkbox__background-background: cv.$rds-checkbox-base__background-background !default;
$rds-checkbox__background-background-disabled: cv.$rds-checkbox-base__background-background-disabled !default;

$rds-checkbox__checkmark: cv.$rds-checkbox-base__checkmark !default;

$rds-checkbox__indeterminate-mark-width: cv.$rds-checkbox-base__indeterminate-mark-width !default;
$rds-checkbox__indeterminate-mark-height: cv.$rds-checkbox-base__indeterminate-mark-height !default;
$rds-checkbox__indeterminate-mark-background: cv.$rds-checkbox-base__indeterminate-mark-background !default;

$rds-checkbox__label-color: f.rds-color(high-contrast) !default;
$rds-checkbox__label-color-disabled: f.rds-color(disabled) !default;

$rds-checkbox__asterisk-color: f.rds-color(error) !default;

$rds-checkbox__subscript-wrapper-margin-top: f.rds-spacing(3) !default;
$rds-checkbox__subscript-wrapper-width: 100% !default;
$rds-checkbox__subscript-wrapper-height: f.rds-spacing(5) !default;
$rds-checkbox__subscript-wrapper-padding-left: 0 !default;

$rds-checkbox__subscript-color--hint: f.rds-color(low-contrast) !default;
$rds-checkbox__subscript-color--hint-disabled: f.rds-color(disabled) !default;
$rds-checkbox__subscript-color--error: f.rds-color(error) !default;

@mixin rds-checkbox() {
  .rds-checkbox {
    display: flex;
    flex-direction: column;

    @keyframes rds-checkbox__fade-in-background {
      0% {
        opacity: 0;
      }

      50% {
        opacity: 1;
      }
    }

    @keyframes rds-checkbox__fade-out-background {
      0%,
      50% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }

    @keyframes rds-checkbox__from-checked-to-indeterminate-checkmark {
      from {
        animation-timing-function: $rds-linear-out-slow-taming-function;
        opacity: 1;
        transform: rotate(0deg);
      }

      to {
        opacity: 0;
        transform: rotate(45deg);
      }
    }

    @keyframes rds-checkbox__from-indeterminate-to-checked-checkmark {
      from {
        animation-timing-function: $rds-checkbox-indeterminate-checked-easing-function;
        opacity: 0;
        transform: rotate(45deg);
      }

      to {
        opacity: 1;
        transform: rotate(360deg);
      }
    }

    @keyframes rds-checkbox__from-unchecked-to-checked-checkmark-path {
      0%,
      50% {
        stroke-dashoffset: $rds-checkbox-mark-path-length;
      }

      50% {
        animation-timing-function: $rds-linear-out-slow-taming-function;
      }

      100% {
        stroke-dashoffset: 0;
      }
    }

    @keyframes rds-checkbox__from-checked-to-unchecked-checkmark-path {
      from {
        animation-timing-function: $rds-fast-out-linear-timing-function;
        stroke-dashoffset: 0;
      }

      to {
        stroke-dashoffset: $rds-checkbox-mark-path-length * -1;
      }
    }

    @keyframes rds-checkbox__from-unchecked-to-indeterminate-indeterminate-mark {
      0%,
      68.2% {
        transform: scaleX(0);
      }

      68.2% {
        animation-timing-function: cubic-bezier(0, 0, 0, 1);
      }

      100% {
        transform: scaleX(1);
      }
    }

    @keyframes rds-checkbox__from-checked-to-indeterminate-indeterminate-mark {
      from {
        animation-timing-function: $rds-linear-out-slow-taming-function;
        opacity: 0;
        transform: rotate(-45deg);
      }

      to {
        opacity: 1;
        transform: rotate(0deg);
      }
    }

    @keyframes rds-checkbox__from-indeterminate-to-checked-indeterminate-mark {
      from {
        animation-timing-function: $rds-checkbox-indeterminate-checked-easing-function;
        opacity: 1;
        transform: rotate(0deg);
      }

      to {
        opacity: 0;
        transform: rotate(315deg);
      }
    }

    @keyframes rds-checkbox__from-indeterminate-to-unchecked-indeterminate-mark {
      0% {
        animation-timing-function: linear;
        opacity: 1;
        transform: scaleX(1);
      }

      32.8%,
      100% {
        opacity: 0;
        transform: scaleX(0);
      }
    }

    &.rds-checkbox-with-label {
      .rds-checkbox__checkbox-container {
        margin: auto $rds-checkbox__checkbox-container-margin-right auto auto;
      }
    }
  }

  .rds-checkbox__wrapper {
    align-items: center;
    display: inline-flex;
    transition: background $rds-swift-ease-out-duration
      $rds-swift-ease-out-timing-function;
    margin: $rds-checkbox-margin 0;
    height: $rds-checkbox-height;
    cursor: pointer;

    .rds-checkbox__container {
      align-items: center;
      display: inline-flex;
      user-select: none;
      cursor: inherit;
      white-space: nowrap;
    }

    .rds-checkbox__checkbox-container {
      position: relative;
      display: inline-block;
      margin: auto;
      height: $rds-checkbox__checkbox-container-height;
      width: $rds-checkbox__checkbox-container-width;
    }

    .rds-checkbox__native-checkbox {
      bottom: 0;
      left: 50%;
    }

    .rds-checkbox__overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translate(
        calc(
          #{$rds-checkbox__overlay-height -
            $rds-checkbox__checkbox-container-height} / -2
        ),
        calc(
          #{$rds-checkbox__overlay-width -
            $rds-checkbox__checkbox-container-width} / -2
        )
      );

      transition:
        border-color $rds-checkbox-transition-duration
          $rds-linear-out-slow-taming-function,
        background $rds-checkbox-transition-duration
          $rds-linear-out-slow-taming-function;

      border: $rds-checkbox__overlay-border;
      border-radius: $rds-checkbox__overlay-border-radius;
      width: $rds-checkbox__overlay-width;
      height: $rds-checkbox__overlay-height;
      background: $rds-checkbox__overlay-background;
    }

    .rds-checkbox__frame {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transition: border-color $rds-checkbox-transition-duration
        $rds-linear-out-slow-taming-function;

      border: $rds-checkbox__frame-border;
      border-radius: $rds-checkbox__frame-border-radius;

      background: transparent;
    }

    .rds-checkbox__background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      justify-content: center;
      align-items: center;
      display: inline-flex;
      opacity: 0;

      transition: opacity $rds-checkbox-transition-duration
        $rds-linear-out-slow-taming-function;

      border-radius: $rds-checkbox__background-border-radius;
      background: $rds-checkbox__background-background;
    }

    .rds-checkbox__checkmark {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      fill: $rds-checkbox__checkmark;
      stroke: $rds-checkbox__checkmark;
    }

    .rds-checkbox__checkmark-path {
      stroke-dashoffset: 0;
      stroke-dasharray: 22.910259;
      stroke-width: 2.1333333333px;
    }

    .rds-checkbox__indeterminate-mark {
      opacity: 0;
      transform: scaleX(0) rotate(0deg) translateY(0px);
      width: $rds-checkbox__indeterminate-mark-width;
      height: $rds-checkbox__indeterminate-mark-height;
      background: $rds-checkbox__indeterminate-mark-background;
    }

    .rds-checkbox__label {
      color: $rds-checkbox__label-color;
    }

    .rds-checkbox__label--s {
      @include m.rds-typography(ui-label-s);
    }

    .rds-checkbox__label--m {
      @include m.rds-typography(ui-label-m);
    }

    .rds-checkbox__label--l {
      @include m.rds-typography(ui-label-l);
    }
  }

  .rds-checkbox.rds-checkbox--checked {
    .rds-checkbox__checkmark {
      opacity: 1;
    }

    .rds-checkbox__checkmark-path {
      stroke-dashoffset: 0;
    }

    .rds-checkbox__background {
      opacity: 1;
    }

    .rds-checkbox__indeterminate-mark {
      transform: scaleX(1) rotate(-45deg);
    }
  }

  .rds-checkbox.rds-checkbox--indeterminate {
    .rds-checkbox__checkmark {
      opacity: 0;
      transform: rotate(45deg);
    }

    .rds-checkbox__checkmark-path {
      stroke-dashoffset: 0;
    }

    .rds-checkbox__indeterminate-mark {
      opacity: 1;
      transform: scaleX(1) rotate(0deg) translateY(0px);
    }

    .rds-checkbox__background {
      opacity: 1;
    }
  }

  .rds-checkbox.cdk-focused.cdk-keyboard-focused {
    .rds-checkbox__overlay {
      background: $rds-checkbox__overlay-background-focused;
      border-color: $rds-checkbox__overlay-border-color-focused;
    }
  }

  .rds-checkbox:hover:not(.rds-checkbox--disabled) {
    .rds-checkbox__overlay {
      background: $rds-checkbox__overlay-background-hover;
    }
  }

  .rds-checkbox:active:not(.rds-checkbox--disabled) {
    .rds-checkbox__overlay {
      background: $rds-checkbox__overlay-background-active;
    }
  }

  .rds-checkbox.rds-checkbox--disabled {
    cursor: default;

    .rds-checkbox__wrapper {
      cursor: default;
    }

    &.rds-checkbox--checked,
    &.rds-checkbox--indeterminate {
      .rds-checkbox__background {
        background: $rds-checkbox__background-background-disabled;
      }
    }

    .rds-checkbox__frame {
      border-color: $rds-checkbox__frame-border-color-disabled;
    }

    .rds-checkbox__label {
      color: $rds-checkbox__label-color-disabled;
    }
  }

  .rds-checkbox--invalid {
    .rds-checkbox__background {
      background: $rds-checkbox__asterisk-color;
    }

    .rds-checkbox__frame {
      border-color: $rds-checkbox__asterisk-color;
    }
  }

  .rds-checkbox-animation {
    $indeterminate-change-duration: 500ms;

    &--unchecked-checked {
      .rds-checkbox__background {
        animation: $rds-checkbox-transition-duration * 2 linear 0ms
          rds-checkbox__fade-in-background;
      }

      .rds-checkbox__checkmark-path {
        animation: $rds-checkbox-transition-duration * 2 linear 0ms
          rds-checkbox__from-unchecked-to-checked-checkmark-path;
      }
    }

    &--unchecked-indeterminate {
      .rds-checkbox__background {
        animation: $rds-checkbox-transition-duration * 2 linear 0ms
          rds-checkbox__fade-in-background;
      }

      .rds-checkbox__indeterminate-mark {
        animation: $rds-checkbox-transition-duration linear 0ms
          rds-checkbox__from-unchecked-to-indeterminate-indeterminate-mark;
      }
    }

    &--checked-unchecked {
      .rds-checkbox__background {
        animation: $rds-checkbox-transition-duration * 2 linear 0ms
          rds-checkbox__fade-out-background;
      }

      .rds-checkbox__checkmark-path {
        animation: $rds-checkbox-transition-duration linear 0ms
          rds-checkbox__from-checked-to-unchecked-checkmark-path;
      }
    }

    &--checked-indeterminate {
      .rds-checkbox__checkmark {
        animation: $rds-checkbox-transition-duration linear 0ms
          rds-checkbox__from-checked-to-indeterminate-checkmark;
      }

      .rds-checkbox__indeterminate-mark {
        animation: $rds-checkbox-transition-duration linear 0ms
          rds-checkbox__from-checked-to-indeterminate-indeterminate-mark;
      }
    }

    &--indeterminate-checked {
      .rds-checkbox__checkmark {
        animation: $indeterminate-change-duration linear 0ms
          rds-checkbox__from-indeterminate-to-checked-checkmark;
      }

      .rds-checkbox__indeterminate-mark {
        animation: $indeterminate-change-duration linear 0ms
          rds-checkbox__from-indeterminate-to-checked-indeterminate-mark;
      }
    }

    &--indeterminate-unchecked {
      .rds-checkbox__background {
        animation: $rds-checkbox-transition-duration * 2 linear 0ms
          rds-checkbox__fade-out-background;
      }

      .rds-checkbox__indeterminate-mark {
        animation: $indeterminate-change-duration * 0.6 linear 0ms
          rds-checkbox__from-indeterminate-to-unchecked-indeterminate-mark;
      }
    }
  }

  .rds-checkbox-asterisk {
    color: $rds-checkbox__asterisk-color;
  }

  .rds-checkbox__subscript-wrapper {
    & > div:not(:empty) {
      margin-top: $rds-checkbox-margin;
    }
  }

  .rds-checkbox--disabled {
    .rds-checkbox__subscript {
      color: $rds-checkbox__subscript-color--hint-disabled;
    }
  }

  .rds-checkbox__subscript {
    text-align: left;
    @include m.rds-typography(ui-component-text-s);

    &--hint {
      color: $rds-checkbox__subscript-color--hint;
    }

    &--error {
      color: $rds-checkbox__subscript-color--error;
    }
  }
}

@use './icon-variables' as iv;
@use '../abstracts/functions' as f;

$rds-list-item-background--hover: f.rds-color(bg-solid-hover-select) !default;
$rds-list-item-background--active: f.rds-color(bg-solid-pressed) !default;
$rds-list-item-background--checked: f.rds-color(bg-solid-hover-select) !default;
$rds-list-item-color: f.rds-color(high-contrast) !default;
$rds-list-item-color--disabled: f.rds-color(disabled) !default;
$rds-list-item-checkmark-color--disabled: f.rds-color(disabled) !default;
$rds-list-item-checkmark-color--active: f.rds-color(informative) !default;
$rds-list-item-border-size: f.rds-spacing(1) !default;
$rds-list-item-border-color--focus: f.rds-color(stroke-active-focus) !default;
$rds-list-item-border--focus: $rds-list-item-border-size solid
  $rds-list-item-border-color--focus !default;
$rds-list-item-border-radius: 0 !default;
$rds-list-item-border-radius--focus: f.rds-spacing(1) !default;
$rds-list-item__checkbox-size: f.rds-spacing(7) !default;

$rds-list-item-padding-vertical-s: f.rds-spacing(2) !default;
$rds-list-item-padding-horizontal-s: f.rds-spacing(4) !default;
$rds-list-item-gap-s: f.rds-spacing(3) !default;

$rds-list-item-padding-vertical-m: 1.5 * f.rds-spacing(2) !default;
$rds-list-item-padding-horizontal-m: f.rds-spacing(5) !default;
$rds-list-item-gap-m: f.rds-spacing(4) !default;

$rds-list-item-padding-vertical-l: f.rds-spacing(3) !default;
$rds-list-item-padding-horizontal-l: f.rds-spacing(5) !default;
$rds-list-item-gap-l: f.rds-spacing(4) !default;

$rds-header-list-item-height: f.rds-spacing(9) !default;
$rds-header-list-item-color: f.rds-color(low-contrast) !default;

$rds-list-item-separator-color: f.rds-color(grey-2) !default;
$rds-list-item-separator-padding: f.rds-spacing(2) !default;

$rds-list-item-sizes: (
  s: (
    typography: 'ui-label-s',
    padding-horizontal: $rds-list-item-padding-horizontal-s,
    padding-vertical: $rds-list-item-padding-vertical-s,
    gap: $rds-list-item-gap-s,
    header-typography: 'ui-overline-s',
    header-height: f.rds-spacing(9),
    icon-size: iv.$rds-icon-size-m,
  ),
  m: (
    typography: 'ui-label-m',
    padding-horizontal: $rds-list-item-padding-horizontal-m,
    padding-vertical: $rds-list-item-padding-vertical-m,
    gap: $rds-list-item-gap-m,
    header-typography: 'ui-overline-m',
    header-height: 36px,
    icon-size: iv.$rds-icon-size-m,
  ),
  l: (
    typography: 'ui-label-l',
    padding-horizontal: $rds-list-item-padding-horizontal-l,
    padding-vertical: $rds-list-item-padding-vertical-l,
    gap: $rds-list-item-gap-l,
    header-typography: 'ui-overline-m',
    header-height: 36px,
    icon-size: iv.$rds-icon-size-l,
  ),
) !default;

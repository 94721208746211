@use 'sass:map';
@use '../abstracts/functions' as f;
@use '../abstracts/mixins' as m;
@use '../library/icon-variables' as iv;

$rds-avatar-background-color: f.rds-color(avatar-bg) !default;
$rds-avatar-color: f.rds-color(brand) !default;
$rds-avatar-grouped-border-color: f.rds-color(avatar-stroke) !default;
$rds-avatar-hover-overlay-color: f.rds-color(
  bg-transparent-hover-select
) !default;
$rds-avatar-focused-color: f.rds-color(stroke-active-focus) !default;
$rds-avatar-focused-outline-width: f.rds-spacing(1) !default;
$rds-avatar-focused: $rds-avatar-focused-outline-width solid
  $rds-avatar-focused-color !default;

$rds-avatar-spacing: (
  xs: (
    groupedMargin: -#{f.rds-spacing(3)},
    border: 1px solid $rds-avatar-grouped-border-color,
    size: f.rds-spacing(7),
    icon: iv.$rds-icon-size-xs,
    typography: ui-component-text-s,
    margin: f.rds-spacing(3),
    nameTypography: ui-label-s-bold,
    descriptionTypography: ui-label-s,
  ),
  s: (
    groupedMargin: -#{f.rds-spacing(3)},
    border: 1px solid $rds-avatar-grouped-border-color,
    size: f.rds-spacing(8),
    icon: iv.$rds-icon-size-s,
    typography: ui-component-text-s,
    margin: f.rds-spacing(3),
    nameTypography: ui-label-s-bold,
    descriptionTypography: ui-label-s,
  ),
  m: (
    groupedMargin: -#{f.rds-spacing(5)},
    border: f.rds-spacing(1) solid $rds-avatar-grouped-border-color,
    size: f.rds-spacing(10),
    icon: iv.$rds-icon-size-l,
    typography: ui-label-s-bold,
    margin: f.rds-spacing(4),
    nameTypography: ui-label-s-bold,
    descriptionTypography: ui-label-s,
  ),
  l: (
    groupedMargin: -#{f.rds-spacing(7)},
    border: f.rds-spacing(1) solid $rds-avatar-grouped-border-color,
    size: f.rds-spacing(12),
    icon: iv.$rds-icon-size-xl,
    typography: ui-label-l-bold,
    margin: f.rds-spacing(5),
    nameTypography: ui-label-s-bold,
    descriptionTypography: ui-label-s,
  ),
  xl: (
    groupedMargin: -#{f.rds-spacing(9)},
    border: f.rds-spacing(1) solid $rds-avatar-grouped-border-color,
    size: 2 * f.rds-spacing(10),
    icon: iv.$rds-icon-size-xxl,
    typography: ui-heading-3,
    margin: f.rds-spacing(5),
    nameTypography: ui-label-l-bold,
    descriptionTypography: ui-label-m,
  ),
) !default;

@mixin rds-avatar() {
  .rds-avatar {
    display: flex;
    align-items: center;

    @each $size, $value in $rds-avatar-spacing {
      $var: map.get($rds-avatar-spacing, $size);

      &.rds-avatar-#{$size} {
        .rds-avatar__container {
          border: map.get(map.get($rds-avatar-spacing, $size), border);
          width: map.get($var, size);
          height: map.get($var, size);
          flex-shrink: 0;

          @if ($size == 'xl') {
            @include m.rds-typography-non-responsive(
              map.get($var, typography),
              'xl'
            );
          } @else {
            @include m.rds-typography(map.get($var, typography));
          }

          .rds-icon {
            width: map.get($var, icon);
            height: map.get($var, icon);
          }
        }

        .rds-avatar__name-wrapper {
          margin-left: map.get($var, margin);

          .rds-avatar-label {
            @include m.rds-typography(map.get($var, nameTypography));
          }

          .rds-avatar-description {
            @include m.rds-typography(map.get($var, descriptionTypography));
          }
        }
      }

      .rds-avatar__container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: $rds-avatar-background-color;
        color: $rds-avatar-color;
      }

      .rds-avatar__name-wrapper {
        display: flex;
        flex-direction: column;
      }
    }

    .rds-avatar-image {
      object-fit: cover;
      border-radius: 50%;
      width: 100%;
      height: 100%;
    }

    &.cdk-focused.cdk-keyboard-focused {
      outline: none;

      .rds-avatar__container {
        outline: $rds-avatar-focused;
      }
    }

    &:hover {
      .rds-avatar-overlay {
        display: block;
      }
    }

    &.rds-avatar-disable-interactions {
      pointer-events: none;
    }
  }

  .rds-avatar-group {
    display: flex;
    flex-direction: row;

    .rds-avatar {
      display: unset;
    }

    @each $size, $value in $rds-avatar-spacing {
      .rds-avatar-#{$size} {
        &:not(:first-child) {
          margin-left: map.get(
            map.get($rds-avatar-spacing, $size),
            groupedMargin
          );
        }
      }
    }

    .rds-avatar__container.cdk-focused.cdk-keyboard-focused {
      outline: $rds-avatar-focused;
    }
  }

  .rds-avatar-overlay {
    position: absolute;
    background: $rds-avatar-hover-overlay-color;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    display: none;
  }
}

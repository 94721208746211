@use 'sass:map';
@use '../../../core/styles/abstracts/functions' as f;
@use '../../../core/styles/abstracts/mixins' as m;

$rds-list-bullet-color: f.rds-color(informative) !default;
$rds-list-color: f.rds-color(high-contrast) !default;

$rds-list-footnote-color: f.rds-color(low-contrast) !default;
$rds-list-footnote-marker-gap: f.rds-spacing(3) !default;

$rds-list-item-vertical-gap: f.rds-spacing(3) !default;
$rds-list-item-with-icons-vertical-gap: f.rds-layout(1) !default;

$rds-list-ul-indentation-size: f.rds-spacing(7) !default;
$rds-list-ul-bullet-size: f.rds-spacing(3) !default;
$rds-list-ul-bullet-gap: f.rds-spacing(5) !default;
$rds-list-ul-default-bullet-top: calc(0.75em - $rds-list-ul-bullet-size / 2);
$rds-list-ul-nested-additional-gap: f.rds-spacing(1) !default;

$rds-list-ol-marker-min-column-width: f.rds-spacing(9) !default;
$rds-list-ol-marker-gap: f.rds-spacing(2) !default;
$rds-list-ol-marker-separator: '.' !default;
$rds-list-ol-second-level-style: lower-alpha !default;
$rds-list-ol-third-level-style: lower-roman !default;
$rds-list-ol-footnotes-marker-min-column-width: f.rds-spacing(7) !default;

$rds-list-variants: (
  article-body-m: (
    with-icons: (
      s: (
        icon-size: f.rds-spacing(7),
        icon-gap: f.rds-spacing(7),
      ),
      m: (
        icon-size: f.rds-spacing(7),
        icon-gap: f.rds-spacing(7),
      ),
      xl: (
        icon-size: f.rds-spacing(7),
        icon-gap: f.rds-spacing(7),
      ),
    ),
  ),
  article-body-s: (
    with-icons: (
      s: (
        icon-size: f.rds-spacing(7),
        icon-gap: f.rds-spacing(5),
      ),
      m: (
        icon-size: f.rds-spacing(7),
        icon-gap: f.rds-spacing(5),
      ),
      xl: (
        icon-size: f.rds-spacing(7),
        icon-gap: f.rds-spacing(7),
      ),
    ),
  ),
  ui-body-l: (
    with-icons: (
      s: (
        icon-size: f.rds-spacing(7),
        icon-gap: f.rds-spacing(5),
      ),
      m: (
        icon-size: f.rds-spacing(7),
        icon-gap: f.rds-spacing(5),
      ),
      xl: (
        icon-size: f.rds-spacing(7),
        icon-gap: f.rds-spacing(7),
      ),
    ),
  ),
  ui-body-m: (
    with-icons: (
      s: (
        icon-size: f.rds-spacing(7),
        icon-gap: f.rds-spacing(5),
      ),
      m: (
        icon-size: f.rds-spacing(7),
        icon-gap: f.rds-spacing(5),
      ),
      xl: (
        icon-size: f.rds-spacing(7),
        icon-gap: f.rds-spacing(5),
      ),
    ),
  ),
  ui-body-s: (
    with-icons: (
      s: (
        icon-size: f.rds-spacing(5) + f.rds-spacing(1),
        icon-gap: f.rds-spacing(5),
      ),
      m: (
        icon-size: f.rds-spacing(5) + f.rds-spacing(1),
        icon-gap: f.rds-spacing(5),
      ),
      xl: (
        icon-size: f.rds-spacing(5) + f.rds-spacing(1),
        icon-gap: f.rds-spacing(5),
      ),
    ),
    ul-bullet-top: calc(0.65em - 4px),
    ol-marker-min-column-width: f.rds-spacing(7),
  ),
) !default;

@mixin list() {
  ul {
    --rds-list-ul-bullet-top: #{$rds-list-ul-default-bullet-top};

    list-style-type: none;
    padding-left: $rds-list-ul-bullet-size + $rds-list-ul-bullet-gap;

    li {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        display: block;
        left: -#{$rds-list-ul-bullet-size + $rds-list-ul-bullet-gap};
        top: var(--rds-list-ul-bullet-top);
        width: $rds-list-ul-bullet-size;
        height: $rds-list-ul-bullet-size;
        background: $rds-list-bullet-color;
        border-radius: 50%;
      }
    }

    ul {
      padding-left: $rds-list-ul-nested-additional-gap;

      li::before {
        box-sizing: border-box;
        border: 1px solid $rds-list-bullet-color;
        background: none;
      }

      ul {
        li::before {
          background: $rds-list-bullet-color;
          border-radius: 0;
        }
      }
    }
  }

  ol {
    --rds-list-ol-marker-min-column-width: #{$rds-list-ol-marker-min-column-width};

    list-style-type: none;
    counter-reset: rds-ol-first-lvl-counter;

    li {
      display: grid;
      grid-template-columns:
        minmax(
          calc(
            var(--rds-list-ol-marker-min-column-width) - $rds-list-ol-marker-gap
          ),
          auto
        )
        1fr;

      &::before {
        counter-increment: rds-ol-first-lvl-counter;
        content: counter(rds-ol-first-lvl-counter) $rds-list-ol-marker-separator;
        margin-right: $rds-list-ol-marker-gap;
      }
    }

    ol {
      counter-reset: rds-ol-second-lvl-counter;
      grid-column: 2;

      li::before {
        counter-increment: rds-ol-second-lvl-counter;
        content: counter(
            rds-ol-second-lvl-counter,
            $rds-list-ol-second-level-style
          )
          $rds-list-ol-marker-separator;
      }

      ol {
        counter-reset: rds-ol-third-lvl-counter;

        li::before {
          counter-increment: rds-ol-third-lvl-counter;
          content: counter(
              rds-ol-third-lvl-counter,
              $rds-list-ol-third-level-style
            )
            $rds-list-ol-marker-separator;
        }
      }
    }
  }

  ul,
  ol {
    color: $rds-list-color;
    list-style-position: inside;

    @include m.rds-typography(article-body-m);

    ul,
    ol {
      // due to including article-body-m as the default typography
      // we need to explicitly inherit typography in nested lists
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      text-transform: inherit;
      letter-spacing: inherit;
      font-family: inherit;
    }

    li + li ul,
    li + li ol,
    li + li {
      margin-top: $rds-list-item-vertical-gap;
    }

    ul {
      margin-left: $rds-list-ul-indentation-size;
    }

    &.no-bullet {
      list-style: none;

      li::before,
      ul li::before {
        display: none;
      }
    }

    &.with-icons {
      list-style: none;
      padding-left: 0;

      li {
        display: flex;
        align-items: center;
        margin-top: $rds-list-item-with-icons-vertical-gap;

        rds-icon {
          // article-body-m's layout as the default
          $article-body-m-with-icons: map.get(
            map.get($rds-list-variants, 'article-body-m'),
            'with-icons'
          );

          @each $breakpoint, $layout in $article-body-m-with-icons {
            @include m.rds-media($breakpoint) {
              height: map.get($layout, 'icon-size');
              width: map.get($layout, 'icon-size');
              margin-right: map.get($layout, 'icon-gap');
            }
          }
        }

        &::before {
          display: none;
        }

        &:first-child {
          margin-top: 0;
        }
      }

      ul li::before {
        display: none;
      }
    }

    &.footnotes {
      --rds-list-ol-marker-min-column-width: #{$rds-list-ol-footnotes-marker-min-column-width};
      color: $rds-list-footnote-color;

      @include m.rds-typography(article-footnote);

      &:not(.inside) {
        margin-left: -#{$rds-list-footnote-marker-gap};

        li {
          padding-left: $rds-list-footnote-marker-gap;
        }
      }
    }
  }

  ul {
    &.inside {
      padding-left: $rds-list-ul-bullet-size + $rds-list-ul-bullet-gap;
    }

    &.outside {
      padding-left: 0;
    }
  }

  @each $typography, $values in $rds-list-variants {
    ul,
    ol {
      &.#{$typography} {
        @include m.rds-typography($typography);
      }
    }

    ul.#{$typography} {
      $bullet-top: map.get($values, 'ul-bullet-top');
      $with-icons-variants: map.get($values, 'with-icons');

      @if $bullet-top {
        --rds-list-ul-bullet-top: #{$bullet-top};
        ul {
          --rds-list-ul-bullet-top: #{$bullet-top};
        }
      }

      @each $breakpoint, $layout in $with-icons-variants {
        &.with-icons li rds-icon {
          @include m.rds-media($breakpoint) {
            height: map.get($layout, 'icon-size');
            width: map.get($layout, 'icon-size');
            margin-right: map.get($layout, 'icon-gap');
          }
        }
      }
    }

    ol.#{$typography} {
      $marker-min-column-width: map.get($values, 'ol-marker-min-column-width');

      @if $marker-min-column-width {
        --rds-list-ol-marker-min-column-width: #{$marker-min-column-width};

        ol {
          --rds-list-ol-marker-min-column-width: #{$marker-min-column-width};
        }
      }
    }
  }
}

@use '../abstracts/mixins' as m;
@use '../abstracts/functions' as f;

$rds-radio-label-color: f.rds-color('high-contrast') !default;
$rds-radio-label-color-disabled: f.rds-color('disabled') !default;

$rds-radio-checkmark-outer-circle-color: f.rds-color('grey-3') !default;
$rds-radio-checkmark-outer-circle-color-disabled: f.rds-color(
  'grey-2'
) !default;
$rds-radio-checkmark-outer-circle-color-checked: f.rds-color(
  'informative'
) !default;
$rds-radio-checkmark-outer-circle-border-color: f.rds-color(
  '600-grey-2'
) !default;
$rds-radio-checkmark-outer-circle-border-checked-disabled: f.rds-color(
  'grey-2'
) !default;

$rds-radio-checkmark-inner-circle-color-checked: f.rds-color(
  'informative'
) !default;
$rds-radio-checkmark-inner-circle-color-checked-disabled: f.rds-color(
  'grey-2'
) !default;

$rds-radio-checkmark-color-hover: f.rds-color(
  'bg-transparent-hover-select'
) !default;
$rds-radio-checkmark-color-pressed: f.rds-color(
  'bg-transparent-pressed'
) !default;
$rds-radio-focus-border-color: f.rds-color('stroke-active-focus') !default;

$rds-radio-error-color: f.rds-color('error') !default;
$rds-radio__subscript-color--hint: f.rds-color('low-contrast') !default;
$rds-radio__subscript-color--hint-disabled: f.rds-color('disabled') !default;
$rds-radio__subscript-color--error: f.rds-color('error') !default;

$rds-radio-size: 20px !default;
$rds-radio-hover-size: 36px !default;
$rds-radio-margin: f.rds-spacing(3) !default;
$rds-radio-label-margin: f.rds-spacing(3) + f.rds-spacing(1) !default;

@mixin rds-radio-button() {
  .rds-radio-checkmark-inner-circle {
    border-radius: 50%;
    height: $rds-radio-size;
    left: 0;
    position: absolute;
    top: 0;
    transition:
      transform ease 280ms,
      background-color ease 280ms;
    width: $rds-radio-size;
    background: $rds-radio-checkmark-inner-circle-color-checked;

    // Note: This starts from 0.001 instead of 0, because transitioning from 0 to 0.5 causes
    // IE to flash the entire circle for a couple of frames, throwing off the entire animation.
    transform: scale(0.001);

    .rds-radio-checked & {
      transform: scale(0.5);
    }
  }

  .cdk-keyboard-focused.rds-radio-button {
    .rds-checkmark-hover {
      display: flex;
      border: f.rds-spacing(1) solid $rds-radio-focus-border-color;
    }
  }

  .rds-radio-button {
    display: inline-block;
    -webkit-tap-highlight-color: transparent;
    outline: 0;

    &.rds-radio--invalid {
      .rds-radio-checkmark-outer-circle {
        border-color: $rds-radio-error-color;
      }

      .rds-radio-checkmark-inner-circle {
        background: $rds-radio-error-color;
      }

      &.rds-radio-disabled {
        .rds-radio-checkmark-outer-circle {
          border-color: $rds-radio-checkmark-outer-circle-color-disabled;
        }
      }

      &.rds-radio-disabled {
        .rds-radio-checkmark-outer-circle {
          border-color: $rds-radio-checkmark-outer-circle-border-checked-disabled;
        }

        .rds-radio-checkmark-inner-circle {
          background: $rds-radio-checkmark-inner-circle-color-checked-disabled;
        }
      }
    }

    &:not(.rds-radio--invalid) {
      .rds-radio-checkmark-outer-circle {
        border-color: $rds-radio-checkmark-outer-circle-border-color;
      }

      &:not(.rds-radio-checked) {
        .rds-radio-checkmark-outer-circle {
          border-color: $rds-radio-checkmark-outer-circle-color;
        }

        &.rds-radio-disabled {
          .rds-radio-checkmark-outer-circle {
            border-color: $rds-radio-checkmark-outer-circle-color-disabled;
          }
        }
      }

      &.rds-radio-checked {
        .rds-radio-checkmark-outer-circle {
          border-color: $rds-radio-checkmark-outer-circle-color-checked;
        }

        .rds-radio-checkmark-inner-circle {
          background: $rds-radio-checkmark-inner-circle-color-checked;
        }

        &.rds-radio-disabled {
          .rds-radio-checkmark-outer-circle {
            border-color: $rds-radio-checkmark-outer-circle-border-checked-disabled;
          }

          .rds-radio-checkmark-inner-circle {
            background: $rds-radio-checkmark-inner-circle-color-checked-disabled;
          }
        }
      }
    }

    .rds-radio-container {
      box-sizing: border-box;
      display: inline-block;
      position: relative;
      width: $rds-radio-size;
      height: $rds-radio-size;
      flex-shrink: 0;

      .rds-radio-checkmark-outer-circle {
        height: $rds-radio-size;
        left: 0;
        position: absolute;
        top: 0;
        transition: border-color ease 280ms;
        width: $rds-radio-size;
        border: {
          width: f.rds-spacing(1);
          style: solid;
          radius: 50%;
        }
      }

      .rds-radio-input {
        // Move the input in the middle and towards the bottom so
        // the native validation messages are aligned correctly.
        bottom: 0;
        left: 50%;
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        outline: 0;
        -webkit-appearance: none;
      }
    }

    &.rds-radio-disabled {
      .rds-radio-label-content {
        color: $rds-radio-label-color-disabled;
      }

      .rds-radio-label {
        cursor: default;
      }
    }

    .rds-checkmark-hover {
      display: none;
      position: absolute;
      top: -8px;
      left: -8px;
      height: $rds-radio-hover-size;
      width: $rds-radio-hover-size;
      border-radius: 50%;
      background-color: $rds-radio-checkmark-color-hover;
    }

    .rds-radio-label-content {
      padding-left: $rds-radio-label-margin;
      color: $rds-radio-label-color;
    }

    &:not(.rds-radio-checked):not(.rds-radio--invalid) {
      .rds-radio-checkmark-outer-circle {
        border-color: $rds-radio-checkmark-outer-circle-color;
      }

      &.rds-radio-disabled {
        .rds-radio-checkmark-outer-circle {
          border-color: $rds-radio-checkmark-outer-circle-color-disabled;
        }
      }
    }

    .rds-radio-checkmark-outer-circle,
    .rds-radio-checkmark-inner-circle {
      z-index: 1;
    }

    &:hover:not(.rds-radio-disabled) {
      .rds-checkmark-hover {
        display: flex;
      }
    }

    &:active:not(.rds-radio-disabled) {
      .rds-checkmark-hover {
        display: flex;
        background-color: $rds-radio-checkmark-color-pressed;
      }
    }
  }

  // Inner label container, wrapping entire element.
  // Enables focus by click.
  .rds-radio-label {
    // Disable text selection on the label itself, because having text selected
    // will prevent focus from reaching the label. Below we'll re-enable it only
    // for the label's content so that people can still select the text.
    //   @include user-select(none);
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    vertical-align: middle;

    // Have the label span the rest of the radio button for maximum clickable area.
    width: 100%;
  }

  // Text label next to radio.
  .rds-radio-label-content {
    display: inline-block;
    order: 0;
    line-height: inherit;
    padding-left: $rds-radio-label-margin;
    padding-right: 0;
  }

  .rds-radio-label-content--s {
    @include m.rds-typography(ui-label-s);
  }

  .rds-radio-label-content--m {
    @include m.rds-typography(ui-label-m);
  }

  .rds-radio-label-content--l {
    @include m.rds-typography(ui-label-l);
  }

  // Alignment.
  .rds-radio-label-content.rds-radio-label-before {
    order: -1;
    padding-left: 0;
    padding-right: $rds-radio-label-margin;
  }

  .rds-radio__subscript-wrapper {
    & > div:not(:empty) {
      margin-top: $rds-radio-margin;
    }
  }

  .rds-radio-button.rds-radio-disabled {
    .rds-radio__subscript--hint {
      color: $rds-radio__subscript-color--hint-disabled;
    }
  }

  .rds-radio__subscript {
    text-align: left;
    @include m.rds-typography(ui-component-text-s);

    &--hint {
      color: $rds-radio__subscript-color--hint;
    }

    &--error {
      color: $rds-radio__subscript-color--error;
    }
  }
}

@use 'sass:math';
@use '../abstracts/mixins' as m;
@use '../abstracts/functions' as f;
@use '../library/icon-variables' as iv;

$rds-form-field-label-transition-timing-function-duration: 100ms !default;
$rds-form-field-label-transition-timing-function: cubic-bezier(
  0.55,
  0,
  0.55,
  0.2
) !default;

$rds-form-field__control-container-height--l: f.rds-layout(4) !default;
$rds-form-field__control-container-height--m: f.rds-layout(3) + f.rds-spacing(2) !default;
$rds-form-field__control-container-height--s: f.rds-layout(2) + f.rds-spacing(2) !default;

$rds-icon-size-l: f.rds-spacing(7) !default;
$rds-icon-size-m: f.rds-spacing(5) + f.rds-spacing(1) !default;
$rds-icon-size-s: f.rds-spacing(5) + f.rds-spacing(1) !default;
$rds-icon-size-xs: f.rds-spacing(5) !default;

$rds-icon-padding-l: 0 !default;
$rds-icon-padding-m: f.rds-spacing(1);
$rds-icon-padding-s: -#{math.div(f.rds-spacing(1), 2)};
$rds-icon-padding-xs: f.rds-spacing(2) !default;

$rds-form-field__control-container-border-width: math.div(
  f.rds-spacing(1),
  2
) !default;
$rds-form-field__control-container-border-width--invalid: f.rds-spacing(
  1
) !default;
$rds-form-field__control-container-border-width--focused: f.rds-spacing(
  1
) !default;

$rds-icon-floating-textarea-padding: f.rds-spacing(3) -
  $rds-form-field__control-container-border-width;

$rds-datepicker-icon-padding-s: -#{f.rds-spacing(2)};
$rds-datepicker-icon-padding-m: 0;
$rds-datepicker-icon-spacing: f.rds-spacing(3) !default;

$rds-form-field__control-textarea-height-l: f.rds-layout(7) !default;
$rds-form-field__control-textarea-height-m: f.rds-layout(6) !default;
$rds-form-field__control-textarea-height-s: f.rds-layout(5) !default;

$rds-form-field-padding-bottom: f.rds-spacing(7) !default;
$rds-form-field-margin-bottom: f.rds-spacing(4) !default;
$rds-form-field__control-container-padding-block--with-border-width: f.rds-spacing(
  2
) !default;
$rds-form-field__control-container-padding-inline--with-border-width: f.rds-spacing(
  5
) !default;
$rds-form-field__control-container-padding-textarea-resizer--with-border-width: f.rds-spacing(
  3
) !default;

$rds-form-field__control-container-border-radius: f.rds-spacing(1) !default;
$rds-form-field__control-container-border-color: f.rds-color(
  stroke-enabled
) !default;
$rds-form-field__control-container-border-color--invalid: f.rds-color(
  error
) !default;
$rds-form-field__control-container-border-color--hover: f.rds-color(
  stroke-hover
) !default;
$rds-form-field__control-container-border-color--focused: f.rds-color(
  stroke-active-focus
) !default;
$rds-form-field__control-container-border-color--disabled: f.rds-color(
  stroke-enabled
) !default;
$rds-form-field__control-container-border: $rds-form-field__control-container-border-width
  solid $rds-form-field__control-container-border-color !default;
$rds-form-field__control-container-border--disabled: $rds-form-field__control-container-border-width
  solid $rds-form-field__control-container-border-color--disabled !default;
$rds-form-field__control-container-border--hover: $rds-form-field__control-container-border-width
  solid $rds-form-field__control-container-border-color--hover !default;
$rds-form-field__control-container-border--invalid: $rds-form-field__control-container-border-width--invalid
  solid $rds-form-field__control-container-border-color--invalid !default;
$rds-form-field__control-container-border--focused: $rds-form-field__control-container-border-width--focused
  solid $rds-form-field__control-container-border-color--focused !default;

$rds-form-field__control-container-padding-top: $rds-form-field__control-container-padding-block--with-border-width !default;
$rds-form-field__control-container-padding-bottom: $rds-form-field__control-container-padding-block--with-border-width !default;
$rds-form-field__control-container-padding-top--invalid: $rds-form-field__control-container-padding-block--with-border-width !default;
$rds-form-field__control-container-padding-top--focused: $rds-form-field__control-container-padding-block--with-border-width !default;
$rds-form-field__control-container-padding-right-l: f.rds-spacing(5) !default;
$rds-form-field__control-container-padding-right-focus-l: f.rds-spacing(
  5
) !default;
$rds-form-field__control-container-padding-right-m: f.rds-spacing(4) !default;
$rds-form-field__control-container-padding-right-focus-m: f.rds-spacing(
  4
) !default;
$rds-form-field__control-container-padding-right-s: f.rds-spacing(3) !default;
$rds-form-field__control-container-padding-right-focus-s: f.rds-spacing(
  3
) !default;
$rds-form-field__control-container-padding-right--invalid: $rds-form-field__control-container-padding-inline--with-border-width -
  $rds-form-field__control-container-border-width--invalid !default;
$rds-form-field__control-container-padding-bottom--focused: $rds-form-field__control-container-padding-block--with-border-width !default;
$rds-form-field__control-container-padding-bottom--invalid: $rds-form-field__control-container-padding-block--with-border-width !default;
$rds-form-field__control-container-padding-left-l: f.rds-spacing(5) !default;
$rds-form-field__control-container-padding-left-focus-l: f.rds-spacing(
  5
) !default;
$rds-form-field__control-container-padding-left-m: f.rds-spacing(4) !default;
$rds-form-field__control-container-padding-left-focus-m: f.rds-spacing(
  4
) !default;
$rds-form-field__control-container-padding-left-s: f.rds-spacing(3) !default;
$rds-form-field__control-container-padding-left-focus-s: f.rds-spacing(
  3
) !default;
$rds-form-field__control-container-padding-left--invalid: $rds-form-field__control-container-padding-inline--with-border-width -
  $rds-form-field__control-container-border-width--invalid !default;

$rds-form-field__control-container-padding-textarea-resizer: $rds-form-field__control-container-padding-textarea-resizer--with-border-width -
  $rds-form-field__control-container-border-width !default;
$rds-form-field__control-container-padding-textarea-resizer--focused: $rds-form-field__control-container-padding-textarea-resizer--with-border-width !default;
$rds-form-field__control-container-padding-textarea-resizer--invalid: $rds-form-field__control-container-padding-textarea-resizer--with-border-width !default;
$rds-form-field__control-container-background: f.rds-color(bg-base-1) !default;
$rds-form-field__control-container-background--disabled: f.rds-color(
  bg-disabled
) !default;
$rds-form-field__control-fix-no-separator-spacing: f.rds-spacing(3) !default;
$rds-form-field__control-fix-separator-spacing: f.rds-spacing(5) !default;
$rds-form-field__control-fix-color: f.rds-color(low-contrast) !default;

$rds-form-field__label-line-height: f.rds-spacing(7) !default;

$rds-form-field__label-wrapper-padding-top--side: f.rds-spacing(4) !default;
$rds-form-field__label-wrapper-margin-bottom--side: 0 !default;
$rds-form-field__label-wrapper-margin-bottom--hint--side: f.rds-spacing(
  7
) !default;
$rds-form-field__label-margin-right--side: f.rds-spacing(4) !default;
$rds-form-field__label-margin-top-l: f.rds-spacing(3) !default;
$rds-form-field__label-margin-top-m: f.rds-spacing(1) !default;
$rds-form-field__label-margin-top-s: f.rds-spacing(3) !default;
$rds-form-field__label-icon-margin-top: f.rds-spacing(1) !default;
$rds-form-field__label-top__margin-bottom: f.rds-spacing(2) !default;
$rds-form-field__wrapper-textarea-field-padding-top--side: f.rds-spacing(
  4
) !default;

$rds-form-field__label-color: f.rds-color(low-contrast) !default;
$rds-form-field__label-color--focused: f.rds-color(
  stroke-active-focus
) !default;
$rds-form-field__label-color--invalid: f.rds-color(error) !default;
$rds-form-field__label-color--disabled: f.rds-color(disabled) !default;

$rds-form-field__label-typography-l: ui-label-m-bold !default;
$rds-form-field__label-typography-m: ui-label-s-bold !default;
$rds-form-field__label-typography-s: ui-component-text-s !default;
$rds-form-field__label-typography-floating-base: ui-label-m-bold !default;
$rds-form-field__label-typography-floating-float: ui-component-text-s !default;

$rds-form-field__label-icon-margin-left: f.rds-spacing(1) !default;
$rds-form-field__label-icon-size-l: iv.$rds-icon-size-xs !default;
$rds-form-field__label-icon-size-l-margin-top: f.rds-spacing(1) !default;
$rds-form-field__label-icon-size-m: iv.$rds-icon-size-xs !default;
$rds-form-field__label-icon-size-m-margin-top: f.rds-spacing(1) * 0.5 !default;
$rds-form-field__label-icon-size-s: iv.$rds-icon-size-xs !default;
$rds-form-field__label-icon-size-s-margin-top: 0 !default;
$rds-form-field__label-icon-floating-base-size: $rds-form-field__label-icon-size-l !default;
$rds-form-field__label-icon-floating-base-margin-top: $rds-form-field__label-icon-size-l-margin-top !default;
$rds-form-field__label-icon-floating-float: iv.$rds-icon-size-xs !default;
$rds-form-field__label-icon-floating-float-margin-top: 0 !default;

$rds-form-field__control-color: f.rds-color(high-contrast) !default;
$rds-form-field__control-color--disabled: f.rds-color(disabled) !default;
$rds-form-field__control-caret-color: $rds-form-field__control-color !default;
$rds-form-field__control-color--placeholder: f.rds-color(grey-3) !default;
$rds-form-field__control-margin-top: f.rds-spacing(5) !default;

$rds-form-field__subscript-wrapper-margin-top-l: f.rds-spacing(3) !default;
$rds-form-field__subscript-wrapper-margin-top-m: f.rds-spacing(3) !default;
$rds-form-field__subscript-wrapper-margin-top-s: f.rds-spacing(2) !default;

$rds-form-field__subscript-wrapper-width: 100% !default;
$rds-form-field__subscript-wrapper-height: f.rds-spacing(5) !default;
$rds-form-field__subscript-wrapper-padding-left: 0 !default;

$rds-fix-padding-top-l: (
    $rds-form-field__control-container-height--l -
      $rds-form-field__control-container-border-width -
      $rds-form-field__control-container-border-width -
      $rds-form-field__control-container-padding-top -
      $rds-form-field__control-container-padding-bottom -
      $rds-form-field__label-line-height
  ) * 0.5 !default;
$rds-fix-padding-top-m: (
    $rds-form-field__control-container-height--m -
      $rds-form-field__control-container-border-width -
      $rds-form-field__control-container-border-width -
      $rds-form-field__control-container-padding-top -
      $rds-form-field__control-container-padding-bottom -
      $rds-form-field__label-line-height
  ) * 0.5 !default;
$rds-fix-padding-top-s: (
    $rds-form-field__control-container-height--s -
      $rds-form-field__control-container-border-width -
      $rds-form-field__control-container-border-width -
      $rds-form-field__control-container-padding-top -
      $rds-form-field__control-container-padding-bottom -
      $rds-form-field__label-line-height
  ) * 0.5 !default;

$rds-form-field__subscript-color--hint: f.rds-color(low-contrast) !default;
$rds-form-field__subscript-color--hint-disabled: f.rds-color(disabled) !default;
$rds-form-field__subscript-color--error: f.rds-color(error) !default;

$rds-form-field__select-arrow-color: f.rds-color(low-contrast) !default;
$rds-form-field__select-arrow-color--disabled: f.rds-color(disabled) !default;
$rds-form-field__select-arrow-color--invalid: f.rds-color(error) !default;

$rds-form-field__asterisk-color: f.rds-color(error) !default;

$rds-form-field-separator-height: f.rds-spacing(2) + f.rds-spacing(1);

$rds-textarea-padding-l: f.rds-spacing(4) -
  $rds-form-field__control-container-border-width f.rds-spacing(3) -
  $rds-form-field__control-container-border-width f.rds-spacing(3) -
  $rds-form-field__control-container-border-width f.rds-spacing(5) -
  $rds-form-field__control-container-border-width;
$rds-textarea-padding-focused-l: f.rds-spacing(4);
$rds-textarea-padding-left-focused-l: f.rds-spacing(5);
$rds-textarea-padding-invalid-l: f.rds-spacing(4);
$rds-textarea-padding-m: f.rds-spacing(3) -
  $rds-form-field__control-container-border-width f.rds-spacing(3) -
  $rds-form-field__control-container-border-width f.rds-spacing(3) -
  $rds-form-field__control-container-border-width f.rds-spacing(4) -
  $rds-form-field__control-container-border-width;
$rds-textarea-padding-focused-m: f.rds-spacing(3);
$rds-textarea-padding-left-focused-m: f.rds-spacing(4);
$rds-textarea-padding-invalid-m: f.rds-spacing(3);
$rds-textarea-padding-s: f.rds-spacing(4) -
  $rds-form-field__control-container-border-width--focused f.rds-spacing(3) -
  $rds-form-field__control-container-border-width f.rds-spacing(3) -
  $rds-form-field__control-container-border-width f.rds-spacing(3) -
  $rds-form-field__control-container-border-width;
$rds-textarea-padding-focused-s: f.rds-spacing(4) -
  $rds-form-field__control-container-border-width;
$rds-textarea-padding-left-focused-s: f.rds-spacing(3);
$rds-textarea-padding-invalid-s: f.rds-spacing(2);

$textarea-floating-padding-l: f.rds-spacing(3) -
  $rds-form-field__control-container-border-width;
$textarea-floating-padding-m: f.rds-spacing(3) -
  $rds-form-field__control-container-border-width;
$textarea-floating-padding-s: f.rds-spacing(3) -
  $rds-form-field__control-container-border-width;

$textarea-padding-suffix-l: f.rds-spacing(3);
$textarea-padding-suffix-m: f.rds-spacing(2);
$textarea-padding-suffix-s: 0;

$rds-form-field-sizes: (
  l: (
    container-height: $rds-form-field__control-container-height--l,
    textarea-height: $rds-form-field__control-textarea-height-l,
    fix-padding-top: $rds-fix-padding-top-l,
    margin-top: $rds-form-field__label-margin-top-l,
    icon-size: $rds-icon-size-l,
    icon-padding: $rds-icon-padding-l,
    datepicker-icon-padding: $rds-icon-padding-l,
    helper-text-margin: $rds-form-field__subscript-wrapper-margin-top-l,
    separator-top: -#{f.rds-spacing(2)},
    separator-height: calc(100% + $rds-fix-padding-top-l),
    label-name: $rds-form-field__label-typography-l,
    label-icon-size: $rds-form-field__label-icon-size-l,
    label-icon-margin-left: $rds-form-field__label-icon-margin-left,
    label-icon-margin-top: $rds-form-field__label-icon-size-l-margin-top,
    textarea-padding: $rds-textarea-padding-l,
    textarea-padding-left-focused: $rds-textarea-padding-left-focused-l,
    textarea-padding-focused: $rds-textarea-padding-focused-l,
    textarea-padding-invalid: $rds-textarea-padding-invalid-l,
    textarea-padding-suffix: $textarea-padding-suffix-l,
    textarea-suffix-separator: f.rds-spacing(5) -
      $rds-form-field__control-container-border-width,
    textarea-suffix-floating: f.rds-spacing(5),
    textarea-suffix-top: -#{f.rds-spacing(4) - $rds-form-field__control-container-border-width},
    textarea-floating-padding: $textarea-floating-padding-l,
    input-padding-top: $rds-form-field__control-container-padding-top,
    input-padding-bottom: $rds-form-field__control-container-padding-bottom,
    input-padding-right: $rds-form-field__control-container-padding-right-l,
    input-padding-left: $rds-form-field__control-container-padding-left-l,
    input-padding-right-focus:
      $rds-form-field__control-container-padding-right-focus-l,
    input-padding-left-focus:
      $rds-form-field__control-container-padding-left-focus-l,
    datepicker-icon-after-height: f.rds-spacing(11),
    datepicker-icon-after-top: 0,
    datepicker-icon-after-left: 0,
    datepicker-right-padding: $rds-form-field__control-container-padding-right-l -
      $rds-form-field__control-container-border-width,
    datepicker-right-padding--focused:
      $rds-form-field__control-container-padding-right-l,
  ),
  m: (
    container-height: $rds-form-field__control-container-height--m,
    textarea-height: $rds-form-field__control-textarea-height-m,
    fix-padding-top: $rds-fix-padding-top-m,
    margin-top: $rds-form-field__label-margin-top-m,
    icon-size: $rds-icon-size-m,
    icon-padding: $rds-icon-padding-m,
    datepicker-icon-padding: $rds-datepicker-icon-padding-m,
    helper-text-margin: $rds-form-field__subscript-wrapper-margin-top-m,
    separator-top: -#{f.rds-spacing(2) - $rds-form-field__control-container-border-width},
    separator-height: calc(100% + $rds-form-field-separator-height),
    label-name: $rds-form-field__label-typography-m,
    label-icon-size: $rds-form-field__label-icon-size-m,
    label-icon-margin-left: $rds-form-field__label-icon-margin-left,
    label-icon-margin-top: $rds-form-field__label-icon-size-m-margin-top,
    textarea-padding: $rds-textarea-padding-m,
    textarea-padding-focused: $rds-textarea-padding-focused-m,
    textarea-padding-left-focused: $rds-textarea-padding-left-focused-m,
    textarea-padding-invalid: $rds-textarea-padding-invalid-m,
    textarea-padding-suffix: $textarea-padding-suffix-m,
    textarea-suffix-separator: f.rds-spacing(4) -
      $rds-form-field__control-container-border-width,
    textarea-suffix-floating: f.rds-spacing(4),
    textarea-suffix-top: -#{f.rds-spacing(3) - $rds-form-field__control-container-border-width},
    input-padding-top: $rds-form-field__control-container-padding-top,
    input-padding-bottom: $rds-form-field__control-container-padding-bottom,
    input-padding-right: $rds-form-field__control-container-padding-right-m,
    input-padding-left: $rds-form-field__control-container-padding-left-m,
    input-padding-right-focus:
      $rds-form-field__control-container-padding-right-focus-m,
    input-padding-left-focus:
      $rds-form-field__control-container-padding-left-focus-m,
    datepicker-icon-after-height: f.rds-spacing(9),
    datepicker-icon-after-top: -#{f.rds-spacing(2)},
    datepicker-icon-after-left: -#{f.rds-spacing(2)},
    datepicker-right-padding: $rds-form-field__control-container-padding-right-m -
      $rds-form-field__control-container-border-width,
    datepicker-right-padding--focused:
      $rds-form-field__control-container-padding-right-m,
  ),
  s: (
    container-height: $rds-form-field__control-container-height--s,
    textarea-height: $rds-form-field__control-textarea-height-s,
    fix-padding-top: $rds-fix-padding-top-s,
    margin-top: $rds-form-field__label-margin-top-s,
    icon-size: $rds-icon-size-s,
    icon-padding: $rds-icon-padding-s,
    datepicker-icon-padding: $rds-datepicker-icon-padding-s,
    helper-text-margin: $rds-form-field__subscript-wrapper-margin-top-s,
    separator-top: -#{f.rds-spacing(2)},
    separator-height:
      calc(
        100% + $rds-form-field__control-container-padding-top +
          $rds-form-field__control-container-padding-top
      ),
    label-name: $rds-form-field__label-typography-s,
    label-icon-size: $rds-form-field__label-icon-size-s,
    label-icon-margin-left: $rds-form-field__label-icon-margin-left,
    label-icon-margin-top: $rds-form-field__label-icon-size-s-margin-top,
    textarea-padding: $rds-textarea-padding-s,
    textarea-padding-focused: $rds-textarea-padding-focused-s,
    textarea-padding-left-focused: $rds-textarea-padding-left-focused-s,
    textarea-padding-invalid: $rds-textarea-padding-invalid-s,
    textarea-padding-suffix: $textarea-padding-suffix-s,
    textarea-suffix-separator: f.rds-spacing(5) -
      $rds-form-field__control-container-border-width,
    textarea-suffix-floating: f.rds-spacing(4),
    textarea-suffix-top: -#{f.rds-spacing(4) - $rds-form-field__control-container-border-width},
    input-padding-top: $rds-form-field__control-container-padding-top,
    input-padding-bottom: $rds-form-field__control-container-padding-bottom,
    input-padding-right: $rds-form-field__control-container-padding-right-s,
    input-padding-left: $rds-form-field__control-container-padding-left-s,
    input-padding-right-focus:
      $rds-form-field__control-container-padding-right-focus-s,
    input-padding-left-focus:
      $rds-form-field__control-container-padding-left-focus-s,
    datepicker-icon-after-height: f.rds-spacing(8),
    datepicker-icon-after-top: -#{f.rds-spacing(2) + $rds-form-field__control-container-border-width},
    datepicker-icon-after-left: -#{f.rds-spacing(2) + $rds-form-field__control-container-border-width},
    datepicker-right-padding: $rds-form-field__control-container-padding-right-s -
      $rds-form-field__control-container-border-width,
    datepicker-right-padding--focused:
      $rds-form-field__control-container-padding-right-s,
  ),
) !default;

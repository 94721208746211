@use 'sass:map';
@use './list-item-variables' as v;
@use '../abstracts/mixins/typography' as t;

@mixin rds-list-item() {
  ul,
  ol {
    &.rds-list-item-container {
      list-style: none;

      &:focus-visible {
        outline: none;
      }

      li {
        margin-top: 0;

        &:before {
          display: none;
        }
      }
    }
  }

  .rds-list-item-container {
    &:focus-visible {
      outline: none;
    }
  }

  .rds-list-item {
    display: flex;
    background-color: transparent;
    border: none;
    align-items: center;
    outline: none;
    width: 100%;
    color: v.$rds-list-item-color;

    &.rds-list-item--checked {
      background-color: v.$rds-list-item-background--checked;

      .rds-list-item-checkmark {
        visibility: visible;
        margin-left: auto;
        color: v.$rds-list-item-checkmark-color--active;
      }
    }

    &:hover:not([disabled]),
    &.rds-nestable-list-item.rds-nestable-list-item-activated {
      background-color: v.$rds-list-item-background--hover;
      cursor: pointer;
    }

    &:active:not([disabled]),
    &.rds-list-item-active:not([disabled]) {
      background-color: v.$rds-list-item-background--active;
    }

    &[disabled] {
      color: v.$rds-list-item-color--disabled;
      cursor: default;
      pointer-events: none;

      &.rds-list-item--checked {
        .rds-list-item-checkmark {
          color: v.$rds-list-item-checkmark-color--disabled;
        }
      }

      .rds-avatar {
        opacity: 0.3;
      }
    }

    @each $size, $item in v.$rds-list-item-sizes {
      &.rds-list-item--#{$size} {
        column-gap: map.get($item, gap);
        border: 0;
        border-radius: v.$rds-list-item-border-radius;
        padding: map.get($item, padding-vertical)
          map.get($item, padding-horizontal);
        @include t.rds-typography(map.get($item, typography));

        &.cdk-focused.cdk-keyboard-focused:not([disabled]) {
          outline: v.$rds-list-item-border--focus;
          outline-offset: -#{v.$rds-list-item-border-size};
          border-radius: v.$rds-list-item-border-radius--focus;
        }

        rds-icon,
        .rds-list-item-checkmark,
        .rds-list-item__has-child-items {
          width: map.get($item, icon-size);
          min-width: map.get($item, icon-size);
          height: map.get($item, icon-size);
          min-height: map.get($item, icon-size);
        }
      }
    }

    .rds-list-item-checkmark {
      visibility: hidden;
    }

    .rds-list-item__checkbox-right {
      margin-left: auto;
    }

    .rds-list-item__has-child-items {
      margin-left: auto;
    }

    .rds-list-item-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }

    .rds-checkbox-with-avatar-wrapper {
      margin-left: auto;
    }

    .rds-pseudo-checkbox__container {
      width: v.$rds-list-item__checkbox-size;
      height: v.$rds-list-item__checkbox-size;
      align-items: center;
      justify-content: center;

      .rds-pseudo-checkbox__checkbox-container {
        margin: 0 !important;
      }
    }
  }

  .rds-header-list-item {
    color: v.$rds-header-list-item-color;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    cursor: default;

    @each $size, $item in v.$rds-list-item-sizes {
      &.rds-header-list-item--#{$size} {
        padding-left: map.get($item, padding-horizontal);
        padding-right: map.get($item, padding-horizontal);
        height: map.get($item, header-height);
        @include t.rds-typography(map.get($item, header-typography));
      }
    }
  }

  .rds-list-item-separator {
    height: 1px;
    background: v.$rds-list-item-separator-color;
    margin: {
      top: v.$rds-list-item-separator-padding;
      bottom: v.$rds-list-item-separator-padding;
    }
  }
}

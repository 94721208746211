@use '../variables/border';
@use '../functions/border' as f-border;

@mixin border-radius($radius: border.$rds-border-radius) {
  border-radius: f-border.valid-radius($radius);
}

@mixin border-top-radius($radius: border.$rds-border-radius) {
  border-top-left-radius: f-border.valid-radius($radius);
  border-top-right-radius: f-border.valid-radius($radius);
}

@mixin border-end-radius($radius: border.$rds-border-radius) {
  border-top-right-radius: f-border.valid-radius($radius);
  border-bottom-right-radius: f-border.valid-radius($radius);
}

@mixin border-bottom-radius($radius: border.$rds-border-radius) {
  border-bottom-right-radius: f-border.valid-radius($radius);
  border-bottom-left-radius: f-border.valid-radius($radius);
}

@mixin border-start-radius($radius: border.$rds-border-radius) {
  border-top-left-radius: f-border.valid-radius($radius);
  border-bottom-left-radius: f-border.valid-radius($radius);
}

@mixin border-top-start-radius($radius: border.$rds-border-radius) {
  border-top-left-radius: f-border.valid-radius($radius);
}

@mixin border-top-end-radius($radius: border.$rds-border-radius) {
  border-top-right-radius: f-border.valid-radius($radius);
}

@mixin border-bottom-end-radius($radius: border.$rds-border-radius) {
  border-bottom-right-radius: f-border.valid-radius($radius);
}

@mixin border-bottom-start-radius($radius: border.$rds-border-radius) {
  border-bottom-left-radius: f-border.valid-radius($radius);
}
